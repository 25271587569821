import React, { useEffect } from "react";
import style from './TestImage.module.css'
import CancelIcon from '@mui/icons-material/Cancel';
import img1 from '../../..//assets/Images/slider1.jpg'
import img2 from '../../..//assets/Images/slider2.jpg'
import img3 from '../../..//assets/Images/slider3.jpg'
import img4 from '../../..//assets/Images/slider4.jpg'
import { getAssesmentAfterCheck } from '../../../services/Teacher/AssignmentService'
import { userCurriculumCode,userCurriculum,teacherSubject } from "../../../services/Authentication/AuthService";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { useState } from "react";
import { Backdrop, Modal } from "@mui/material";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
export default function TestImage() {
  let history = useHistory();
  const [open, setOpen] = useState(false)
  const [image, setImage] = useState('')
const [data,setData]=useState([])
  // let data = [
  //   { id: '001', name: 'maths', subject: 'maths', sDate: '01-06-2023', eDate: '02-06-2023'},
  //   { id: '002', name: 'maths', subject: 'maths', sDate: '01-06-2023', eDate: '02-06-2023'},
  //   { id: '003', name: 'maths', subject: 'maths', sDate: '01-06-2023', eDate: '02-06-2023'},
  //   { id: '004', name: 'maths', subject: 'maths', sDate: '01-06-2023', eDate: '02-06-2023'},
  //   { id: '005', name: 'maths', subject: 'maths', sDate: '01-06-2023', eDate: '02-06-2023'},
  //   { id: '006', name: 'maths', subject: 'maths', sDate: '01-06-2023', eDate: '02-06-2023'},
  //   { id: '007', name: 'maths', subject: 'maths', sDate: '01-06-2023', eDate: '02-06-2023'},
  //   { id: '008', name: 'maths', subject: 'maths', sDate: '01-06-2023', eDate: '02-06-2023'},
  //   { id: '001', name: 'maths', subject: 'maths', sDate: '01-06-2023', eDate: '02-06-2023'},
  // ]
const getData=async()=>{
  console.log(userCurriculum())
  let body={
    curriculumCode: userCurriculumCode(),
    subject: teacherSubject(),
    curriculum: userCurriculum()
  }
let assessment = await getAssesmentAfterCheck(body).then(res=>setData(res.data.data)).catch(err=>console.log(err))
}
  const imgOpen = (index, imgLink) => {
    console.log(imgLink)
    if (index <= 3) {
      setImage(imgLink)
      setOpen(true)
    }
    console.log(image)
  }
useEffect(()=>{
  getData()
},[])
  const handleClose = () => setOpen(false)
  return (
    <React.Fragment>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
       <div className={`w-50 ${style.main}`}>
       <CancelIcon className={style.close_icon} onClick={handleClose} />
       <img src={image} className="w-100" />
       </div>
      </Backdrop>

      <div className={`container-fluid  p-0`}>
        <div className="row p-0 m-0">
          <div className='session_heading mt-2 w-100'>
            <h1 className="form-heading">Assessment Recordings</h1>
          </div>
          <div className="col-md-12 table_container">
            {/* <div className="card"> */}
            <div className={`card-body ${style.overflow_list} `}>
              <table className="table table">
                <thead>
                  <tr>
                    <th style={{ width: "15%" }}>Assessment ID</th>
                    <th style={{ width: "20%" }}>Assessment name</th>
                    <th style={{ width: "20%" }}>Subject</th>
                    <th style={{ width: "15%" }}>Start Date</th>
                    <th style={{ width: "15%" }}>End Date</th>
                    <th style={{ width: "15%" }}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => <tr key={index}>
                    <td>{item.testID}</td>
                    <td className={style.text_cap} >{item.assessmentName}</td>
                    <td className={style.text_cap}>{item.subject}</td>
                    <td>{moment(item.startdate).format("DD-MM-YYYY")}</td>
                    <td>{moment(item.enddate).format("DD-MM-YYYY")}</td>
                    <td><button className="view_btn" onClick={()=>history.push(`/teacher/teacher-test-view/${item.testID}`)}><VisibilityOutlinedIcon sx={{fontSize:'16px'}}/>&nbsp; View</button></td>
                    {/* <td><span className={`${style.pointer} ${index <= 3 ? 'text-primary' : ''}`} onClick={() => imgOpen(index, item.alerts)}>{index <= 3 ? 'Image' : 'none'}</span></td> */}
                  </tr>)}
                </tbody>

              </table>
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>

    </React.Fragment>
  );
}
