import React from "react";
import parse from "html-react-parser";
import DisplayErrorMessage from "../../Share/DisplayErrorMessage";
import styles from "./MyQueries.module.css";
import { userID } from "../../../services/Authentication/AuthService";
import ReactStars from "react-rating-stars-component";

const CompletedQueries = (props) => {
  const {
    isFeedback,
    completedQueries,
    feedbackMessage,
    feedbackQuery,
    cancelQuery,
    handleFeedbackQuery,
    handleInputFeedback,
    selectedFeedback,
    ratingUpdate,
    statusChanged
  } = props;

  if (completedQueries && completedQueries.length === 0) {
    return (
      <DisplayErrorMessage
        message="No completed queries found"
        errorSize="big"
      />
    );
  }
  const ratingChanged = (newRating, chatid) => {
    const payload = {
      rating: newRating,
      chatId: chatid
    }
    ratingUpdate(payload)
    // console.log(newRating);
  };
  return (
    <React.Fragment>
      {completedQueries &&
        completedQueries.length &&
        completedQueries.map((q, index) => {

          if (q.status === 2) {
            return (
              <div className={`cardDiv ${styles.queryDiv}`} key={q._id}>
                <h5 title="Curriculum" className={styles.curriculum}>
                  {q.curriculum} {"  -"}
                </h5>
                <div title="Subjet" className={styles.subject}>
                  {q.subject} {"-"}
                </div>
                <div title="Topic" className={styles.topic}>
                  {q.topic} {"-"}
                </div>
                {/* <div title="Chapter" className={styles.chapter}>
                  {q.chapter}
                </div> */}

                {/* <hr className={styles.hr} /> */}
                {/* <div className={styles.messagesDiv}> */}
                {q.messages.map((m) => {
                  return (
                    <React.Fragment>
                      {m.author_id === userID() ? (
                        <div className="col-md-12">
                          <p
                            key={m.createdAt}
                            className={styles.studentReview}
                          >
                            {parse(m.message)}
                          </p>
                        </div>
                      ) : (
                        <div className="col-md-12 text-right">
                          <p
                            key={m.createdAt}
                            className={styles.teacherReview}
                          >
                            {parse(m.message)}
                          </p>
                        </div>
                      )}
                    </React.Fragment>
                  );
                })}
                {/* </div> */}

                {q.feedback ? (
                  <div className={styles.queryFeatures}>
                    <b>Feedback:</b> {q.feedback}
                  </div>
                ) : (
                  <React.Fragment>
                    <div className={styles.queryFeatures}>
                      <span
                        className={styles.feedback}
                        onClick={() => feedbackQuery(index)}
                      >
                        <i className="las la-comment-dots"></i> Feedback
                      </span>
                    </div>

                    {isFeedback && (
                      <div
                        key={`item-${index}`}
                        className={`mt-2 ${selectedFeedback === index
                          ? styles.open
                          : styles.close
                          }`}
                      >
                        <textarea
                          rows="5"
                          className="form-control"
                          placeholder="Feedback"
                          name="feedbackMessage"
                          value={feedbackMessage}
                          onChange={handleInputFeedback}
                        ></textarea>
                        <div className="col-md-12 pad-0 text-right">
                          <button
                            className="btn btn-danger mt-3 mr-2 mr-0"
                            onClick={cancelQuery}
                          >
                            Cancel
                          </button>
                          <button
                            className="btn btn-primary mt-3 mr-0"
                            onClick={() => handleFeedbackQuery(q)}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                )}
                <div className="row">
                <div className="col">
                  <ReactStars
                    count={5}
                    value={q.rating ? q.rating : 0}
                    onChange={rate => ratingChanged(rate, q.chatID)}
                    size={24}
                    activeColor="#ffd700"
                  />
                  </div>
                  <div className="d-flex flex-row  justify-content-end col">
                    <button
                      className="btn btn-success  mt-3 mr-2 "
                      onClick={() => {statusChanged(1, q.chatID);  }}
                    >
                      <i class="fa-solid fa-rotate-right"></i> open again
                    </button>

                  </div>
                </div>
              </div>
            );
          }
        })}
    </React.Fragment>
  );
};

export default CompletedQueries;
