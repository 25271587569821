import React from "react";
import { Link } from "react-router-dom";
import style from "./video.css"

const Video = ({ videosList, deleteVideo, search, searchVideo }) => {
  return (
    <div className="row">
      <h1 className="form-heading" style={{ color: "#4A1D75" }}>
        Videos
      </h1>

      {/* <div className="add-btn-div d-flex   flex-row-reverse  add-btn">
        <Link
          to={`/admin/add-video`}
          className="btn btn-sm btn-primary  col-1 "
        >
          Add Video
        </Link>
        <Link
          to={`/admin/add-read`}
          className="btn btn-sm btn-primary mr-2 col-1"
        >
          Add reading Material
        </Link>
      </div> */}
      {/* <div className="add-btn-div">
        
     
      </div> */}
      {/* <div class="input-group row col-12   mb-2 ">
        <div class="input-group-prepend">
          <div class="input-group-text">
            <img
              src="https://img.icons8.com/search"
              style={{ maxWidth: "40%" }}
            />
          </div>
        </div>
        <input
          type="text"
          value={search}
          onChange={(text) => searchVideo(text)}
          class="form-control"
          placeholder="Search Video/Curriculum/Subject/Topic"
        />
      </div> */}
      <div className="videoHeader">
        <div class="search">
          <span class="fa fa-search"></span>
          <input
            placeholder="Search"
            style={{ borderRadius: "30px" }}
            type="text"
            value={search}
            onChange={(text) => searchVideo(text)}
          />
        </div>
        <div style={{ display: "flex"}}>
        <div>
            
            <Link
              to={`/admin/add-exercise`}
              className="btn btn-sm mr-2"
              style={{
                borderRadius: "40px",
                backgroundColor: "#4A1D75",
                border: "2px solid #4A1D75",
                width: "200px",
                textAlign: "center",
                padding: "6px",
                color: "white",
                height: "auto",
                marginRight: "5px",
              }}

            >
              Add Exercise Material
            </Link>
          </div>
          <div>
            {" "}
            <Link
              to={`/admin/add-video`}
              className="btn btn-sm   "
              style={{
                borderRadius: "40px",
                backgroundColor: "#4A1D75",
                border: "2px solid #4A1D75",
                width: "140px",
                textAlign: "center",
                padding: "6px",
                color: "white",
                height: "auto",
                marginRight: "5px",
              }}
            >
              Add Video
            </Link>
          </div>
          <div>
            <Link
              to={`/admin/add-read`}
              className="btn btn-sm  mr-2"
              style={{
                borderRadius: "40px",
                backgroundColor: "#4A1D75",
                border: "2px solid #4A1D75",
                width: "200px",
                textAlign: "center",
                padding: "6px",
                color: "white",
                height: "auto",
                marginRight: "5px",
              }}
            >
              Add Reading Material
            </Link>
          </div>
        </div>
      </div>
      <div className="col-md-12 table_container">
        {/* <div className="card"> */}
          <div className="card-body border scroll-div overflowList">
            <table className="table table">
              <thead>
                <tr>
                  <th style={{ width: "5%" }}>Video ID</th>
                  <th style={{ width: "10%" }}>Video Name</th>
                  <th style={{ width: "10%" }}>Curriculum</th>
                  <th style={{ width: "10%" }}>Subject</th>
                  <th style={{ width: "10%" }}>Topic</th>
                  <th style={{ width: "10%" }}>Chapter</th>
                  <th style={{ width: "10%" }}>Read file</th>
                  <th style={{ width: "10%" }}>Exercise file</th>
                  <th style={{ width: "5%" }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {videosList &&
                  videosList.map((v) => {
                    return (
                      <tr key={v._id}>
                        <td style={{ width: "5%" }}>{v.videoID}</td>
                        <td style={{ width: "10%" }}>{v.fileName}</td>
                        <td style={{ width: "10%" }}>{v.curriculum}</td>
                        <td style={{ width: "10%" }}>{v.subject}</td>
                        <td style={{ width: "10%" }}>{v.topic}</td>
                        <td style={{ width: "10%" }}>{v.chapter}</td>
                        {v.readArr && (
                          <td style={{ width: "10%" }}>
                            {v.readArr.map((data) => {
                              return (
                                <a href={data.location} target="_blank">
                                  <p>{data.name}</p>
                                </a>
                              );
                            })}
                          </td>
                        )}
                        {!v.readArr && <td style={{ width: "10%" }}>No file</td>}
                        {v.exercise && (
                          <td style={{ width: "10%" }}>
                            {v.exercise.map((data) => {
                              return (
                                <a href={data.location} target="_blank">
                                  <p>{data.name}</p>
                                </a>
                              );
                            })}
                          </td>
                        )}
                        <td style={{ width: "5%" }}>
                          <button
                            className="btn btn-danger btn-sm"
                            onClick={() => deleteVideo(v.videoID, v.fileName)}
                            style={{
                              backgroundColor: "#DEF2E9",
                              // width: "72px",
                            }}
                          >
                            <span style={{ color: "#49AF48" }}> Delete</span>
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default Video;
