import http from "../httpService";
import { apiUrl } from "../../config.json";
import { getAuthToken } from "../Authentication/AuthService";

const headers = {
  "x-access-token": getAuthToken(),
};

export const queryInsert = (payload) => {
  return http.post(apiUrl + "/query/createQuery", payload, {
    headers: headers,
  });
};


export const queryUpdate = (payload) => {
  return http.post(apiUrl + "/query/updateQuery", payload, {
    headers: headers,
  });
};

export const getAllQueries = (payload) => {
  return http.post(apiUrl + "/query/getAllQuery", payload, {
    headers: headers,
  });
};
export const updateRating = (payload) => {
  return http.post(apiUrl + "/query/giveRating", payload, {
    headers: headers,
  });
};
