import React, { useEffect, useState } from 'react'
import moment from "moment";
import { Link } from "react-router-dom";
import { getAssignments } from '../../../services/Teacher/AssignmentService';
import { assignCurrculum } from '../../../services/Authentication/AuthService';

export default function StudentReoprtFileOne(props) {
    // const { assignmentList } = props;
    // console.log(props.assignmentList);

  const [assignmentList,setAssignmentList] = useState([])

  const  getAllAssignment = ()=>{
      const payload = {
        assignCurrculum: assignCurrculum(),
      };
      getAssignments(payload)
        .then((res) => {
          console.log(res.data.data);
          setAssignmentList(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
  }
  useEffect(()=>{

    getAllAssignment();
  },[])
  return (
    <div className="row">
      <div className="col-md-12 p-3 table_container">
        <div className="card-body p-0">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Assessment Name</th>
                  <th>Type</th>
                  <th>Curriculum</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {assignmentList &&
                  assignmentList.length > 0 &&
                  assignmentList.map((a) => {
                    return (
                      <tr key={a._id}>
                        <td>{a.testID}</td>
                        <td>{a.assessmentName}</td>
                        <td>{a.type}</td>
                        <td>{a.curriculum}</td>
                        <td>{moment(a.startdate).format("MM/DD/YYYY")}</td>
                        <td>{moment(a.enddate).format("MM/DD/YYYY")} </td>
                        <td>
                          <Link
                            to={`/teacher/student-report/${a.testID}/${a.curriculumCode}`}
                          >
                            <button className="btn btn-sm btn-primary view_icon">
                              <i class="fa-regular fa-eye"></i>
                              View
                            </button>
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
