function getUserMedia(options, successCallback, failureCallback) {
    var api = navigator.getUserMedia || navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia || navigator.msGetUserMedia;
    if (api) {
      return api.bind(navigator)(options, successCallback, failureCallback);
    }
    alert('User Media API not supported.');
  }
  
  var theStream;
  var theRecorder;
  var recordedChunks = [];

  function recorderOnDataAvailable (event) {
    if (event.data.size == 0) return;
    console.log('ondataavailable, type: ' + event.data.type);
    recordedChunks.push(event.data);
  }
    
  export function getStream() {
    var constraints = {video: true, audio: true};
    console.log(1)
    getUserMedia(constraints, function (stream) {
    console.log(2)

      var mediaControl = document.querySelector('video');
    //   if (navigator.mozGetUserMedia) {
    //     mediaControl.mozSrcObject = stream;
    //   } else {


    //     console.log(mediaControl)
    //     mediaControl.srcObject = stream;
    //   }
      let recorder
      theStream = stream;
      try {
        recorder = new MediaRecorder(stream);
      } catch (e) {
        console.error('Exception while creating MediaRecorder: ' + e);
        return;
      }
      theRecorder = recorder;
      console.log('MediaRecorder created');
      recorder.ondataavailable = recorderOnDataAvailable;
      recorder.start(100);
    }, function (err) {
      alert('Error: ' + err);
    });
  }
  export function exitVideo(){
    theRecorder.stop();
  }

  export function download() {
    console.log('Saving data');
    theRecorder.stop();
    theStream.getTracks()[0].stop();
  
    var blob = new Blob(recordedChunks, {type: "video/webm"});
    console.log(blob,"blob")
    const file = new File([blob],"webvideo",{ type: 'video/webm',    lastModified: Date.now() })
    return file
    console.log(file,"file")

    var url = (window.URL || window.webkitURL).createObjectURL(blob);
    console.log(url,"url")

    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = url;
    a.download = 'test.webm';
    a.click();
    console.log(a,"a")
    
    // setTimeout() here is needed for Firefox.
    setTimeout(function () {
        (window.URL || window.webkitURL).revokeObjectURL(url);
    }, 100); 
  }
  