import React, { useEffect, useState } from "react";
import moment from "moment";
import parse from "html-react-parser";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import styles from "../../Students/Assignments/Assignments.module.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {Link} from "react-router-dom";
  
const AssignmentCorrection = (props) => {
  // const navigate = useNavigate()
  const [show, setShow] = useState(false);
  const [studQues, setStudQues] = useState([]);
  const [question, setQuestion] = useState([]);
  let history = useHistory();
  const {
    assignmentIndividual: data,
    studentQues,
    reDirectStudentData,
    manualCorrectionData,
  } = props;

  const getQuestion = (id) => {
    let student = studQues.filter((e) => e.userID === id);
    setQuestion(student[0].questions);
  };
  useEffect(() => {
    setStudQues(studentQues);
  }, [studentQues]);
  useEffect(() => {
    if (manualCorrectionData.length !== 0) {
      setQuestion(manualCorrectionData);
    }
    setStudQues(studentQues);
  }, [manualCorrectionData]);
  return (
    <React.Fragment>
      <div className="col-md-12">
        <div className="card">
          <div className="card-body pad-0">
            <h1 className="form-heading">Assessment Correction</h1>
            <div className="row mt-3">
              <div className="col-md-12 d-lg-flex align-items-start gap-3">
                <table className="table table-bordered">
                  <tbody>
                    <tr>
                      <th className="fs-6">Assessment Name</th>
                      <td>{data.assessmentName}</td>
                    </tr>
                    <tr>
                      <th className="fs-6">Assessment Type</th>
                      <td>{data.type === "Public" ? "Informal" : "Formal"}</td>
                    </tr>
                    <tr>
                      <th className="fs-6">Curriculum</th>
                      <td>{data.curriculum}</td>
                    </tr>
                    <tr>
                      <th className="fs-6">Subject</th>
                      <td>{data.subject}</td>
                    </tr>
                    <tr>
                      <th className="fs-6">
                        Start Date <small>(DD/MM/YYYY)</small>
                      </th>
                      <td>{moment(data.startdate).format("DD/MM/YYYY")}</td>
                    </tr>
                    <tr>
                      <th className="fs-6">
                        End Date <small>(DD/MM/YYYY)</small>
                      </th>
                      <td>{moment(data.enddate).format("DD/MM/YYYY")} </td>
                    </tr>
                  </tbody>
                </table>
                <table
                  className="table table-bordered mt-5 mt-lg-0"
                  style={{ maxHeight: "60vh", overflowY: "scroll",width:"600px",marginRight:"100px"}}
                >
                  <tbody >
                    <tr>
                      <th className="fs-6" style={{width:"50px"}}>Student ID</th>
                      <th className="fs-6"style={{width:"50px"}}>Student Name</th>
                      {/* <th className="fs-6">Marks</th> */}
                    </tr>
                    {studQues.map((item) => (
                      <tr>
                        <td>{item.userID}</td>
                        <td
                          className="text-primary fw-bold pointer"
                          onClick={() => {
                            let url=`/teacher/StudentData/${item.userID}/${data.testID}`
                            reDirectStudentData(item.userID,url)
                             /* history.push(`StudentData/${item.userID}`); */ 
                            //  history.push(); 
                            //  <Link to="/teacher/StudentData/83"></Link>
                            /*  setShow(true); */
                            //  getQuestion(item.userID)
                            //  history("StudentData/:id");
                          }}
                        >
                          {item.firstName + " " + item.lastName}
                        </td>
                        {/* <td>{"-"}</td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AssignmentCorrection;
