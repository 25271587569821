import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import FlatList from "flatlist-react";
import TextField from "@material-ui/core/TextField";
import { Button, Modal } from "react-bootstrap";
import Autocomplete1 from "@material-ui/lab/Autocomplete";
import styles from "./Topbar.module.css";
import { getCurriculumList } from "../../../services/Admin/commonService";
import {
  teacherSubject,
  userCurriculum,
  userCurriculumCode,
  userID,
} from "../../../services/Authentication/AuthService";
import { getUpdateCurriculum } from "../../../services/Admin/curriculumService";
import { getAllSubjectList } from "../../Share/GetCurriculumDetails";
// import { Link } from "react-router-dom";
// import { Link } from "react-router-dom/cjs/react-router-dom.min";

const Topbar = () => {
  let history = useHistory();
  const [show, setShow] = useState(false);

  const [cur, setCurList] = useState({});
  const [subjectList, setSubjectList] = useState([]);
  const [subject, setSubject] = useState("");

  const [selected, setSelected] = useState({});
  const [error, setError] = useState();
  function Logout() {
    sessionStorage.clear();
    history.push("/login");
  }
  let notification = [];
  const listCurricluim = () => {
    getCurriculumList()
      .then((res) => {
        if (res.status === 200) {
          setCurList(res.data.data);
        }
      })
      .catch((err) => {
        setCurList([]);
      });
  };
  useEffect(() => {
    setSelected({
      curriculum: userCurriculum(),
      curriculumCode: userCurriculumCode(),
    });
    setSubject(teacherSubject());
    listCurricluim();
    getAllSubject(userCurriculum(), userCurriculumCode());
  }, []);
  console.log("sd", userCurriculum());
  const getAllSubject = (curriculum, curriculumCode) => {
    let payload = {
      curriculum: curriculum,
      curriculumCode: curriculumCode,
    };
    console.log("sub000");
    getAllSubjectList(curriculumCode)
      .then((res) => {
        if (res.length > 0) {
          setSubjectList(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const updateCur = () => {
    let payload = {
      userID: userID(),
      curriculum: selected.curriculum,
      curriculumCode: selected.curriculumCode,
      role: "teacher",
      subject: subject,
    };
    console.log("dsds", payload);

    getUpdateCurriculum(payload)
      .then((res) => {
        if (res.status === 200) {
          sessionStorage.token = res.data.token;
          sessionStorage.loginType = "teacher";
          handleClose();
          window.location.reload();
        }
      })
      .catch((err) => {
        setError("Something went wrong");
      });
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const data = [
    {
      icontext: <i class="las la-check-circle"></i>,
      textData: "Assigned Curriculum",
    },
    {
      icontext: <i class="las la-check-circle"></i>,
      textData: " You are assigned with math session for curriculum name",
    },
    {
      icontext: <i class="las la-check-circle"></i>,
      textData:
        "You are assigned with a new assessment of math for curriculum name",
    },
  ];
  return (
    <header>
      <Modal show={show} onHide={handleClose}>
        {/* <Modal.Header closeButton>
          <Modal.Title>Change Curriculum</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <Autocomplete1
            id="combo-box-demo"
            // className="form-control"
            options={cur}
            getOptionLabel={(option) => option.curriculum}
            onChange={(event, value) => {
              if (value === null) {
                setSelected("");
                setSubject("");
                return;
              }
              setSubject("");
              getAllSubject(value.curriculum, value.curriculumCode);
              setSelected({
                curriculum: value.curriculum,
                curriculumCode: value.curriculumCode,
              });
            }}
            // disableClearable={true}
            defaultValue={{
              curriculum: selected.curriculum ? selected.curriculum : "",
            }}
            style={{ width: "100%", height: "38px" }}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
          />
          <Autocomplete1
            id="combo-box-demo"
            // className="form-control"
            options={subjectList}
            className="mt-3"
            getOptionLabel={(option) => {
              return option.subject || "";
            }}
            key={(option) => option.subject}
            onChange={(event, value) => {
              if (value === null) {
                setSubject("");
                return;
              }
              console.log("vcalyyyy", value);
              setSubject(value.subject);
            }}
            disableClearable={true}
            defaultValue={{ subject: subject ? subject : "" }}
            style={{ width: "100%", height: "38px" }}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
          />
          {error && <p>{error}</p>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={updateCur}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      <nav
        className={`navbar navbar-expand-lg navbar-light fixed-top ${styles.navbar}`}
      >
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className={`navbar-nav ms-auto ${styles.navbarNav}`}>
            <li className="nav-item">Teacher Dashboard</li>
          </div>
          <div className={`navbar-nav ml-auto ${styles.navbarNav}`}>
            <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle"
                to="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="las la-bell"></i>
              </Link>
              
              <div
                className={`dropdown-menu ${styles.dropdownMenu}`}
                // aria-labelledby="navbarDropdown"
                style={{}}
              >
                {/* <div className="flat">
                  {notification.map(data => {
                    return (
                      <div className="dropdown-item">
                        <p>{data}</p>
                      </div>
                    )
                  })}
                  <FlatList
                    list={notification}
                    className="flat"
                    renderWhenEmpty={() => (
                      <div className="dropdown-item">
                        <p>No Notification</p>
                      </div>
                    )}
                    display={{
                      grid: false,
                    }}
                    renderItem={(data, idx) => {
                      return (
                        <Fragment>
                          <div key={idx} className="dropdown-item">
                            <p>
                              {data} {idx}
                            </p>
                          </div>
                          {idx < notification.length - 1 && (
                            <div className="dropdown-divider"></div>
                          )}
                        </Fragment>
                      );
                    }}
                  />
                </div> */}
                <div className={`${styles.teacheraccept}`}>
                  {data.map((res) => {
                    return (
                      <>
                        <div className={`${styles.iconText}`}>
                          <span>{res.icontext}</span>
                          <p style={{ paddingLeft: "2%" }}>{res.textData}</p>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </li>
            <li className={`nav-item dropdown`}>
              <span
                className="nav-link dropdown-toggle pointer"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="las la-user-circle"></i>
              </span>
              <div
                className={`dropdown-menu ${styles.dropdownMenu1}`}
                aria-labelledby="navbarDropdown"
              >
                {/* <span className="dropdown-item pointer" onClick={handleShow}>
                  Change Curriculum
                </span> */}
                {/* <div className="dropdown-divider"></div> */}

                <Link className="dropdown-item" to="/teacher/profile">
                  Profile
                </Link>
                <div className="dropdown-divider"></div>
                <span className="dropdown-item pointer" onClick={Logout}>
                  Logout
                </span>
              </div>
            </li>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Topbar;
