import React, { Component } from "react";
import Assignment from "../../components/Teachers/Assignment/Assignment";
import AssignmentCorrection from "../../components/Teachers/Assignment/AssignmentCorrection";
import StudentData from "../../components/Teachers/Assignment/StudentData";
import { setVideolink } from "../../services/Student/SessionService";
import LoadingModal from "../../components/Share/LoadingModal";
// import StudentReoprtFileOne from "../../components/Teachers/StudentReport/StudentReoprtFileOne";
import {
  fetchAssignments,
  fetchAssignmentsById,
  getPendingQuestions,
  testSubmitByTeacher,
  getAssignments,
  getStudentQues
} from "../../services/Teacher/AssignmentService";

import {
  userCurriculumCode,
  teacherSubject,
  userID,
  userInfo,
  assignCurrculum
} from "../../services/Authentication/AuthService";

class AssignmentContainer extends Component {
  _isMounted = false;
  state = {
    assignmentList: [],
    assignmentIndividual: [],
    pendingQuestions: [],
    manualCorrectionData: [],
    studentQues: [],
    isLoading: false
  };
  getAllAssignments = async () => {
    const payload = {
      assignCurrculum: assignCurrculum()
    };

    getAssignments(payload)
      .then((res) => {

        if (res.status === 200) {
          this.setState({ assignmentList: res.data.data });
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  getAllAssignmentsById = async () => {
    // console.log("this.props.match", this.props.match.params);
    const payload = {
      curriculumCode: this.props.match.params.cc,
      testID: this.props.match.params.testId,
    };
    fetchAssignmentsById(payload)
      .then((res) => {
        if (res.status === 200) {


          this.setState({ assignmentIndividual: res.data.data });

        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  getStudentQuesData = async () => {
    // console.log("this.props.match", this.props.match.params);
    const payload = {
      testID: this.props.match.params.testId,
    };
    if(payload.testID){

      this.setState({ isLoading: true })
      getStudentQues(payload)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.data.length > 0) {
  
              this.setState({ studentQues: res.data.data });
              this.setState({ isLoading: false })
  
            }
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };
  getPendingQuestions = async () => {
    // console.log("this.props.match", this.props.match.params);
    const payload = {
      userID: userID(),
      testID: this.props.match.params.testId,
    };

    // getPendingQuestions(payload)
    //   .then((res) => {
    //     if (res.status === 200) {
    //       this.setState({
    //         pendingQuestions: res.data.data,
    //         manualCorrectionData: res.data.data,
    //       });
    //     }
    //   })
    //   .catch((err) => {
    //     console.log("err", err);
    //   });
  };

  manualMarks = (e, q, type, question) => {

    let newArray = [...question];
    let value = e.target.value;

    const elementsIndex = newArray.findIndex(
      (element) => element.questionID == q.questionID
    );
    if (type === "marks") {

      if (Number(value) <= Number(q.questionmarks)) {
        let valid;
        if (e.target.value == 0) {
          valid = false;
        } else {
          valid = true;
        }
        newArray[elementsIndex] = {
          ...newArray[elementsIndex],
          acheivedMarks: value,
          valid: valid,
        };
      } else {
        alert("Entered marks should be lessthan or equal to Question Marks");
        e.target.value = "";
      }
    } else if (type === "comments") {
      newArray[elementsIndex] = {
        ...newArray[elementsIndex],
        comments: value,
      };
    }

    this.setState({ manualCorrectionData: newArray });
  };
  reDirectStudentData = async (studentId, url) => {
    try {
      this.setState({ isLoading: true })
      let payload = {
        testID: this.props.match.params.testId,
        studentId: studentId
      }
      await setVideolink(payload).then(res => {
        this.setState({ isLoading: false })
        this.props.history.replace(url);
      })
    } catch (error) {
      console.log(error)
    }
  }
  submitCorrection = (status) => {
    const assignmentDetails = this.state.assignmentIndividual;
    const queObj = this.state.manualCorrectionData;
    const userData = userInfo();
    userData["testID"] = this.props.match.params.testId;
    // console.log("queObj", queObj);
    // console.log("queObj--length", queObj.length);

    const quesLength = this.state.manualCorrectionData ? this.state.manualCorrectionData.length : 0;
    let quess = this.state.manualCorrectionData
    let totalMarks = 0;
    let score = 0;
    let correctQues = 0;
    let wrongQues = 0;
    let temp = {};
    let marks = 0

    let optionQues = [];
    if (this.state.manualCorrectionData) {
      quess.forEach(e => { marks = marks + Number(e.questionmarks) })
      this.state.manualCorrectionData.map((r, i) => {
        totalMarks += parseInt(r.acheivedMarks);
        if (r.valid === true) {
          if (assignmentDetails.assignmentMode === "Optional") {
            optionQues.push(parseInt(r.acheivedMarks));
            correctQues += 1;
            optionQues.sort(function (a, b) {
              return b - a;
            });

            if (assignmentDetails.optionalQuestions < optionQues.length) {
              optionQues.splice(assignmentDetails.optionalQuestions);
            }

            score = optionQues.reduce(function (a, b) {
              return a + b;
            }, 0);
          } else {
            score += parseInt(r.acheivedMarks);
            correctQues += 1;
          }
        } else {
          wrongQues += 1;
        }
        temp.score = score;
        temp.correctQues = correctQues;
        temp.wrongQues = wrongQues;
        temp.totalMarks = totalMarks;
        temp.student = r.userID
        return temp;
      });
    }


    if (assignmentDetails.length !== 0) {

      let resultObj = {
        assessmentName: assignmentDetails.assessmentName.trim(),
        createDate: assignmentDetails.createDate,
        curriculum: assignmentDetails.curriculum.trim(),
        curriculumCode: assignmentDetails.curriculumCode.trim(),
        enddate: assignmentDetails.enddate,
        startdate: assignmentDetails.startdate,
        subject: assignmentDetails.subject.trim(),
        testID: assignmentDetails.testID,
        type: assignmentDetails.type,
        correctionMode: assignmentDetails.correctionMode,
        totalQuestions: quesLength,
        correctAnsweredQues: temp.correctQues,
        wrongAnsweredQues: temp.wrongQues,
        totalMarks: marks,
        achievedMarks: temp.score,
        status: status ? status : "Accept",
        userID: userID(),
        studentId: this.props.match.params.id
      };
      const payload = {
        globalInfo: userData,
        transaction: this.state.manualCorrectionData,
        result: resultObj,
      };

      console.log(payload)
      if (resultObj.achievedMarks) {

        testSubmitByTeacher(payload)
          .then((res) => {
            this.props.history.replace(`/teacher/assignments-list`);
          })
          .catch((err) => {
            console.log("err", err);
          });
      }

    }
  };

  componentDidMount() {

    this._isMounted = true;
    if (this._isMounted) {

      this.getAllAssignments();
      this.getAllAssignmentsById();
      // this.getPendingQuestions();
      this.getStudentQuesData();
    }
  }
  componentWillUnmount() {
  }
  render() {
    const path = this.props.match.path;
        
        

    const { assignmentIndividual, assignmentList, pendingQuestions, studentQues, manualCorrectionData } =
      this.state;
    return (
      <React.Fragment>
        {path === "/teacher/assignments-list" && (
          <Assignment assignmentList={assignmentList} />
        )}

        {path === "/teacher/assignment-correction/:testId/:cc" && (
          <AssignmentCorrection
            assignmentIndividual={assignmentIndividual}
            pendingQuestions={pendingQuestions}
            manualMarks={this.manualMarks}
            submitCorrection={this.submitCorrection}
            studentQues={studentQues}
            manualCorrectionData={manualCorrectionData}
            reDirectStudentData={this.reDirectStudentData}
          />
        )}
        {path === "/teacher/StudentData/:id/:testId" && (
          <StudentData
            assignmentIndividual={assignmentIndividual}
            pendingQuestions={pendingQuestions}
            manualMarks={this.manualMarks}
            submitCorrection={this.submitCorrection}
            studentQues={studentQues}
            manualCorrectionData={manualCorrectionData}
          />
        )}
        {/* {path === "/teacher/student-report-one" && (
          <StudentReoprtFileOne assignmentList={"sdfsdfsf"} />
        )} */}
        {/* <LoadingModal visible={this.state.isLoading} /> */}
      </React.Fragment>
    );
  }
}

export default AssignmentContainer;
