import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  CopyCurriculumm,
  CopyCurrSubCourseQuestion,
} from "../../../services/Admin/curriculumService";
// import style from "./curriculum.css";
// import style from "./curriculum.css";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import BorderColorIcon from "@mui/icons-material/BorderColor";

const Curriculum = ({ curriculumList, searchCurr, search }) => {
  let [st, setst] = useState(true);

  return (
    <div>
      <div className="row" style={{ height: "" }}>
        <h1 className="form-heading" style={{ color: "#4A1D75" }}>
          Curriculum List
        </h1>
        {/* <div
       
        className="CurriculamHeader"
      > */}
        {/* <div class="input-group row col-12   mb-2 "> */}
        {/* <div class="input-group-prepend"> */}
        {/* <div className="input-group-text" style={{ borderRadius: "40px" }}>
            <img
              src="https://img.icons8.com/search"
              style={{ maxWidth: "30px" }}
            />
            <input
              type="text"
              value={search}
              onChange={(text) => searchCurr(text)}
              class="form-control"
              placeholder="Search Curriculum"
              style={{ borderRadius: "40px" }}
            />
          </div> */}
        {/* </div> */}
        {/* </div> */}
        {/* <div
         
          style={{ borderRadius: "40px" }}
        > */}
        {/* <Link
            to={`/admin/add-curriculum`}
            className="btn btn-sm  add-btn"
            style={{
              borderRadius: "40px",
              backgroundColor: "#4A1D75",
              border: "2px solid #4A1D75",
              width: "150px",
              textAlign: "center",
              padding: "15px",
            }}
          >
            <span> Add Curriculum</span>
          </Link>
        </div>
      </div> */}
        <div className="CurriculamHeader">
          <div class="search">
            <span class="fa fa-search"></span>
            <input
              placeholder="Search Curriculum"
              style={{ borderRadius: "30px" }}
              type="text"
              value={search}
              onChange={(text) => searchCurr(text)}
            />
          </div>
          <div>
            <Link
              to={`/admin/add-curriculum`}
              className="btn btn-sm add-btn"
              style={{
                borderRadius: "40px",
                backgroundColor: "#4A1D75",
                border: "2px solid #4A1D75",
                width: "140px",
                textAlign: "center",
                padding: "6px",
                color: "white",
                height: "auto",
              }}
            >
              Add Curriculum
            </Link>
          </div>
        </div>
        <div className="col-md-12 table_container">
          {/* <div className="card"> */}
          <div className="card-body">
            <table className="table table-">
              <thead>
                <tr>
                  <th scope="col">
                    <span style={{ color: "#20639B" }}>Curriculum Name</span>
                  </th>
                  {/* <th scope="col">Curriculum Award</th> */}
                  {/* <th scope="col">Short Name</th>  */}
                  <th scope="col" className="w-25">
                    <span style={{ color: "#20639B" }}> Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {curriculumList.length > 0 &&
                  curriculumList.map((c) => {
                    return (
                      <tr key={c._id}>
                        <td>{c.curriculum}</td>
                        <td
                          style={{
                            display: "flex",
                            width: "100%",
                            // justifyContent: "space-between",
                          }}
                        >
                          <Link to={`/admin/edit-curriculum/${c._id}`}>
                            <button
                              className="btn btn-sm "
                              style={{
                                backgroundColor: "#FFE7A8",
                                marginRight: "10px",
                              }}
                            >
                              <span style={{ color: "#EAB731" }}>
                                <BorderColorIcon
                                  style={{
                                    color: "#EAB731",
                                    width: "18px",
                                  }}
                                />
                                <b> Edit</b>
                              </span>
                            </button>
                          </Link>
                          <Link to={`/admin/view-curriculum/${c._id}`}>
                            <button
                              className="btn btn-sm btn-info"
                              style={{ backgroundColor: "#C5F9D5" }}
                            >
                              <span style={{ color: "#49AF48" }}>
                                <RemoveRedEyeIcon
                                  style={{ color: "#49AF48", width: "18px" }}
                                />
                                <b>View</b>
                              </span>
                            </button>
                          </Link>

                          {c.curriculum[c.curriculum.length - 1] ===
                          ")" ? null : (
                            <span className="copy_btn">
                              <span className="threedotbtn border px-2">⋮</span>

                              <span className="copy_menu">
                                <ul>
                                  <li
                                    onClick={() => {
                                      document
                                        .querySelectorAll(".copy_menu")
                                        .forEach((e) => {
                                          e.style.display = "none";
                                        });
                                      async function test() {
                                        await CopyCurriculumm(c._id);
                                        window.location.reload();
                                      }
                                      test();
                                    }}
                                  >
                                    Copy curriculum
                                  </li>
                                  <li
                                    onClick={() => {
                                      document
                                        .querySelectorAll(".copy_menu")
                                        .forEach((e) => {
                                          e.style.display = "none";
                                        });
                                      async function test1() {
                                        await CopyCurrSubCourseQuestion(c._id);
                                        window.location.reload();
                                      }
                                      test1();
                                    }}
                                  >
                                    Copy curriculum+Subject+Course+Question
                                  </li>
                                </ul>
                              </span>
                            </span>
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};

export default Curriculum;
