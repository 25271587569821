import React from "react";
import styles from "./Sessions.module.css";
import quer from '../../../assets/Banners/Banner6.png'

const SessionType = ({ isType, sessionType }) => {
  return (
    <React.Fragment>
      <div className="row">
        <div className={`col-md-12 ${styles.dashimg} mb-2 `}>
          {/* <img src={quer} style={{ width: '100%', height: '100%' }} /> */}
        </div>
      </div>
      <div className="col-md-12 row mb-35">
        <div
          className={`tabItem ${isType === "upcoming" ? "tabItemActive" : ""}`}
          onClick={() => sessionType("upcoming")}
          style={{ borderRadius: "40px" }}
        >
          Upcoming Sessions
        </div>
        <div
          className={`tabItem ${isType === "completed" ? "tabItemActive" : ""}`}
          onClick={() => sessionType("completed")}
          style={{ borderRadius: "40px" }}
        >
          Completed Sessions
        </div>
      </div>
    </React.Fragment>
  );
};

export default SessionType;
