import React from "react";
import { NavLink } from "react-router-dom";
import "./side.css";
import styles from "./sidebar.module.css";
import { Link } from "react-router-dom";

const Sidebar = () => {
  return (
    <aside className={`${styles.mainSidebar}`}>
      <div className={`navbar-header  ${styles.navbarHeader}`}></div>
      <ul className={`navbar-nav ml-auto`} style={{ textDecoration: "none" }}>
        <li className="nav-item">
          <Link
            className={`navbar-brand  ${styles.navbarBrand}`}
            to="/admin/admin-dashboard"
            style={{ textDecoration: "none", color: "#fff" }}
          >
            Admin
          </Link>
        </li>
        <li className="nav-item">
          <NavLink
            to="/admin/admin-dashboard"
            className="nav-link"
            style={{ color: "#fff" }}
          >
            <i className={`las la-home ${styles.sidebaricons}`}></i>
            Home
          </NavLink>
        </li>
        <li className="nav-item ">
          <NavLink
            to="/admin/curriculum"
            className="nav-link"
            style={{ color: "#fff" }}
          >
            <i className={`las la-graduation-cap ${styles.sidebaricons}`}></i>
            Curriculum
          </NavLink>
        </li>
        <li className="nav-item ">
          <NavLink
            to="/admin/subject"
            className="nav-link"
            style={{ color: "#fff" }}
          >
            <i className={`las la-book-reader ${styles.sidebaricons}`}></i>
            Subject
          </NavLink>
        </li>
        <li className="nav-item ">
          <NavLink
            to="/admin/course-content"
            className="nav-link"
            style={{ color: "#fff" }}
          >
            <i className={`las la-book-open ${styles.sidebaricons}`}></i>
            Chapter
          </NavLink>
        </li>

        <li className="nav-item dropdown">
          <NavLink
            to="/admin/question"
            className="nav-link"
            style={{ color: "#fff" }}
          >
            <i className={`las la-question-circle ${styles.sidebaricons}`}></i>
            Question Library
          </NavLink>
        </li>

        <li className="nav-item dropdown">
          <NavLink
            to="/admin/template"
            className="nav-link"
            style={{ color: "#fff" }}
          >
            <i className={`las la-chalkboard  ${styles.sidebaricons}`}></i>
            Templates
          </NavLink>
        </li>

        <li className="nav-item dropdown">
          <NavLink
            to="/admin/assessment"
            className="nav-link"
            style={{ color: "#fff" }}
          >
            <i className={`las la-clipboard ${styles.sidebaricons}`}></i>
            Assessment
          </NavLink>
        </li>

        <li className="nav-item dropdown">
          <NavLink
            to="/admin/staff"
            className="nav-link"
            style={{ color: "#fff" }}
          >
            <i className={`las la-user ${styles.sidebaricons}`}></i>
            Staff
          </NavLink>
        </li>

        <li className="nav-item dropdown">
          <NavLink
            to="/admin/video"
            className="nav-link"
            style={{ color: "#fff" }}
          >
            <i className={`las la-video ${styles.sidebaricons}`}></i>
            Video
          </NavLink>
        </li>
        <li className="nav-item dropdown">
          <NavLink
            to="/admin/student"
            className="nav-link"
            style={{ color: "#fff" }}
          >
            <i className={`las la-user ${styles.sidebaricons}`}></i>
            Student
          </NavLink>
        </li>
        <li className="nav-item dropdown">
          <NavLink
            to="/admin/HrModule"
            className="nav-link"
            style={{ color: "#fff" }}
          >
            <i className={`las la-user ${styles.sidebaricons}`}></i>
            HR Module
          </NavLink>
        </li>
        {/* <li className="nav-item ">
          <NavLink to="/admin/org-admin" className="nav-link">
            <i className={`las la-graduation-cap ${styles.sidebaricons}`}></i>
            Org-Admin
          </NavLink>
        </li> */}
        <li className="nav-item dropdown">
          <NavLink
            to="/admin/schedule"
            className="nav-link"
            style={{ color: "#fff" }}
          >
            <i className={`las la-calendar ${styles.sidebaricons}`}></i>
            Calendar
          </NavLink>
        </li>
        <li className="nav-item dropdown">
          <NavLink
            to="/admin/images"
            className="nav-link"
            style={{ color: "#fff" }}
          >
            <i className={`las la-image ${styles.sidebaricons}`}></i>
            Images
          </NavLink>
        </li>
        <li className="nav-item dropdown">
          <NavLink
            to="/admin/session-admin"
            className="nav-link"
            style={{ color: "#fff" }}
          >
            <i className={`las la-sync ${styles.sidebaricons}`}></i>
            Session
          </NavLink>
        </li>
        <li className="nav-item dropdown">
          {/* <NavLink
            to="/admin/test-link"
            className="nav-link"
            style={{ color: "#fff" }}
          >
            <i className={`las la-book-open ${styles.sidebaricons}`}></i>
            Assessment Link
          </NavLink> */}
        </li>
      </ul>
    </aside>
  );
};

export default Sidebar;
