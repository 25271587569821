import React, { useState, useEffect, Fragment } from "react";
import DeleteIcon from "@mui/icons-material/Delete";


const ViewStudent = props => {
    const { studentValues, staffDelete, changeStatus,curriculumData,active } = props
   
    let cur=["This student not buy any curriculum"];;
    let curCode=["This student not buy any curriculum"];
    if(curriculumData){

       cur=curriculumData.map(e=>e.curriculum);
      curCode=curriculumData.map(e=>e.curriculumCode);
    }

    return (
      <div className="container">
        <div style={{ marginTop: "20px", display: "flex" }}>
          <div style={{marginLeft:"30px"}}>
            <button
              className="btn btn-sm btn-danger float-right ml-3"
              onClick={() => staffDelete(studentValues._id)}
              style={{
                backgroundColor: "#DEF2E9",
                //   marginR: "20px",
                // width: "72px",
              }}
            >
              <span style={{ color: "#49AF48" }}>
                <DeleteIcon style={{ color: "#49AF48", fontSize: "9px" }} />
                <b style={{ fontSize: "9px" }}>Delete</b>
              </span>
            </button>
          </div>
          <div style={{ marginLeft: "10px" }}>
            {/* {studentValues.isActivate ? (
              <button
                className="btn btn-sm btn-info float-right ml-3"
                onClick={() => changeStatus(studentValues._id, false)}
                style={{ backgroundColor: "#AB94E2" }}
              >
                <span style={{ color: "rgb(74, 29, 117)" }}>
                  <b> Active</b>
                </span>
              </button>
            ) : (
              <button
                className="btn btn-sm btn-info float-right ml-3"
                onClick={() => changeStatus(studentValues._id, true)}
                style={{ backgroundColor: "#AB94E2" }}
              >
                <span style={{ color: "rgb(74, 29, 117)" }}>
                  <b> InActive</b>
                </span>
              </button>
            )} */}
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h1 className="form-heading" style={{ color: "#4A1D75" }}>
              Student Detail
            </h1>

            <div className="card">
              <div className="card-body">
                <div className="text-view">
                  <label>Name </label>
                  <span>
                    {studentValues.firstName} {studentValues.middleName}{" "}
                    {studentValues.lastName}
                  </span>
                </div>
                <div className="text-view">
                  <label>mobile</label>
                  <span>{studentValues.mobile}</span>
                </div>
                <div className="text-view">
                  <label>email</label>
                  <span>{studentValues.email}</span>
                </div>
                <div className="text-view">
                  <label>address</label>
                  <span>{studentValues.address}</span>
                </div>
                {props.role !== "admin" && (
                  <Fragment>
                    <div className="text-view">
                      <label>Curriculum</label>
                      <span>{cur.join()}</span>
                    </div>
                    <div className="text-view">
                      <label>curriculum Code</label>
                      <span>{curCode.join()}</span>
                    </div>
                    {props.role !== "teacher" && (
                      <Fragment>
                        {" "}
                        <div className="text-view">
                          <label>gender</label>
                          <span>{studentValues.gender}</span>
                        </div>
                        <div className="text-view">
                          <label>Date of birth</label>
                          <span>
                            {studentValues.dob &&
                              studentValues.dob.split("T")[0]}
                          </span>
                        </div>
                        <div className="text-view">
                          <label>country</label>
                          <span>{studentValues.country}</span>
                        </div>
                        <div className="text-view">
                          <label>state</label>
                          <span>{studentValues.state}</span>
                        </div>
                        <div className="text-view">
                          <label>city</label>
                          <span>{studentValues.city}</span>
                        </div>
                        <hr />
                        <div className="text-view">
                          <label>Parent/Gaurdian Name</label>
                          <span>
                            {studentValues.parentOrGardien &&
                              studentValues.parentOrGardien.parentFirstName}
                            {studentValues.parentOrGardien &&
                              studentValues.parentOrGardien.parentMiddleName}
                            {studentValues.parentOrGardien &&
                              studentValues.parentOrGardien.parentLastName}
                          </span>
                        </div>
                        <div className="text-view">
                          <label>Parent/Gaurdian Email</label>
                          <span>
                            {studentValues.parentOrGardien &&
                              studentValues.parentOrGardien.parentEmail}
                          </span>
                        </div>
                        <div className="text-view">
                          <label>Parent/Gaurdian Mobile</label>
                          <span>
                            {studentValues.parentOrGardien &&
                              studentValues.parentOrGardien.parentMobile}
                          </span>
                        </div>
                        <div className="text-view">
                          <label>Parent/Gaurdian Address</label>
                          <span>
                            {studentValues.parentOrGardien &&
                              studentValues.parentOrGardien.parentAddress}
                          </span>
                        </div>
                      </Fragment>
                    )}
                  </Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default ViewStudent