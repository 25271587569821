import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import quer from "../../../assets/Banners/group3.png";
import styles from "./MyQueries.module.css";

const QueryType = (props) => {
  const { queryType, raiseQuery } = props;
  const path = useHistory().location.pathname;
  return (
    <Fragment>
      <div className="row">
        <div className={`col-md-12 ${styles.dashimg} mb-2 `}>
          <img src={""} />
        </div>
      </div>
      <br />
      <div className="col-md-12 row">
        <div className={`${styles.queryTypeButton}`}>
          <div className={`${styles.butonstyle}`}>
            <div
              className={`tabItem ${
                path === "/student/my-queries/open" ? "tabItemActive" : ""
              } ${styles.buttonTab}`}
              onClick={() => queryType("open")}
              style={{ borderRadius: "30px", textAlign: "centrer" }}
            >
              <span> Open</span>
            </div>
            <div
              className={`tabItem ${
                path === "/student/my-queries/inprogress" ? "tabItemActive" : ""
              } ${styles.buttonTab}`}
              onClick={() => queryType("inprogress")}
              style={{ borderRadius: "30px" }}
            >
              <span> Inprogress</span>
            </div>
            <div
              className={`tabItem ${
                path === "/student/my-queries/completed" ? "tabItemActive" : ""
              } ${styles.buttonTab}`}
              onClick={() => queryType("completed")}
              style={{ borderRadius: "30px" }}
            >
              <span>Completed</span>
            </div>
          </div>
          <div>
            <button
              className="btn btn-right "
              style={{
                width: "200px",
                textAlign: "center",
                borderRadius: "30px",
                color: "white",
                backgroundColor: "#4A1E75",
              }}
              onClick={raiseQuery}
            >
              Raise Query
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default QueryType;
