
import React, { useState, useEffect, Fragment } from "react";
import Profile from "../../components/Share/Profile"
import { getUserInfo, } from "../../services/Admin/commonService";
import { userID } from "../../services/Authentication/AuthService";

const TecherProfileConatainer = props => {
    const [userInfo, setUserInfo] = useState('')
    useEffect(() => {
        getUser()
    }, [])

    const getUser = () => {
        getUserInfo(+userID()).then(res => {
            if (res.status === 200) {
                // if (res.data.status) {
                    let data = res.data.data
                    if(data.assignCurrculum){

                        let cur = data.assignCurrculum.map(e=>e.curriculum)
                        let curCode = data.assignCurrculum.map(e=>e.curriculumCode)
                        data.curriculum=cur.join()
                        data.curriculumCode=curCode.join()
                    }
                    setUserInfo(data)
                // }
            } else {
                setUserInfo('')
            }
        }).catch(err => { console.log('err') })
    }

    return <Fragment>
        {userInfo && <Profile role={userInfo.role}
            firstName={userInfo.firstName}
            middleName={userInfo.middleName}
            lastName={userInfo.lastName}
            mobile={userInfo.mobile}
            dob={userInfo.dob}
            email={userInfo.email}
            gender={userInfo.gender}
            curriculum={userInfo.curriculum}
            curriculumCode={userInfo.curriculumCode}
            address={userInfo.address}
            country={userInfo.country}
            state={userInfo.state}
            city={userInfo.city}
        />
        }
    </Fragment>
}

export default TecherProfileConatainer