import React, {
  useState,
  useEffect,
  Fragment,
  useCallback,
  useMemo,
} from "react";
import StudentList from "../../components/admin/adminStudent/studentList";
import {
  getStaffList,
  getStatusChange,
} from "../../services/Admin/staffService";
import EditStudent from "../../components/admin/adminStudent/editStudent";
import { adminStudent } from "../../constants/AdminInitialValues";
import ViewStudent from "../../components/admin/adminStudent/ViewStudent";
import {
  getAllCurriculumList,
  getAllSubjectList,
} from "../../components/Share/GetCurriculumDetails";
import {
  createRole,
  saveStaff,
  deleteStaff,
  getRolesList,
} from "../../services/Admin/staffService";
import { getCurriculum } from "../../services/Admin/curriculumService";
import { getCurriculumCodeFun } from "../../utils/utils";
const StudentAdminContainer = (props) => {
  // console.log('pro' + props.location.pathname)
  // const path = props.location.pathname
  const path = props.match.path;
  const [studentList, setStudentList] = useState("");
  const [studentListAll, setStudentListAll] = useState([]);
  const [search, setSearch] = useState("");

  const [studentValues, setStudentValue] = useState({});
  const [cur, setCur] = useState([])

  const [curriculumList, setCurriculumList] = useState([]);
  const [curriculumCode, setCurriculumCode] = useState("");
  
  const [editError, setEditError] = useState("");
  useEffect(() => {
    if (path === "/admin/student") {
      getStudentList();
      getAllCurriculum();
    } else if (path === "/admin/student-edit/:id") {
      studentDetail();
      getAllCurriculum();
      //console.log('print' + JSON.stringify(props))
    } else if (path === "/admin/student-view/:id") {
      studentDetail();
    }
  }, [path]);
  const getStudentList = () => {
    getStaffList()
      .then((res) => {
        if (res.status === 200) {
          console.log("pp-", res.data.data);
          res.data.data.map((x, idx) => {
            console.log(idx + x.curriculum);
          });
          setStudentList(res.data.data);
          setStudentListAll(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };
  const handlestudentAdd = (forms) => {
    setEditError("");
    if (!forms.isValid) {
      let obj = Object.keys(forms.errors)[0];
      let key = Object.values(forms.errors)[0];
      console.log(obj, key);
      setEditError(obj + " " + key);
      return;
    }
 

    // console.log(forms.isValid)
    // console.log(JSON.stringify(forms))
    saveStaff(forms.values,forms.values._id).then((res) => {
      if (res.status === 200) {
        props.history.push("/admin/student");
      } else {
        setEditError("Unexpected error");
      }
    });
  };
  const getAllCurriculum = () => {
    getAllCurriculumList()
      .then((res) => {
        // console.log('res' + JSON.stringify(res))
        if (res) {
          setCurriculumList(res);
        }
      })
      .catch((err) => console.log(err));
  };
  const staffDelete = async (ID) => {
    try {
      await deleteStaff(ID);
      props.history.push("/admin/student");
    } catch (ex) {
      // if (ex.response && ex.response.status === 404) {
      //   this.props.history.replace("/not-found");
      // }
    }
  };
  const changeStatus = (id, value) => {
    let payload = {
      id: id,
      inactive: value,
    };
    getStatusChange(payload).then((res) => {
      if (res.status === 200) {
        studentDetail();
        window.location.reload()
        // this.getStaffList();
      }
    });
  };
  const studentDetail = () => {
    const id = props.match.params.id;
    const payload = {
      userID: parseInt(id),
    };
    getStaffList(payload)
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data.data["curriculumData"])
          //console.log('pp-' + JSON.stringify(res.data))
          setStudentValue(res.data.data[0]);
          setCur(res.data.data["curriculumData"])
          
        }
      })
      .catch((err) => console.log(err));
  };
  const handleCurriculum = (data, e) => {
    curriculumList.length > 0 &&
      curriculumList.map((cc) => {
        //  console.log('cccccccccc-' + JSON.stringify(cc))
        if (cc.curriculum === e.target.value) {
          setCurriculumCode(cc.curriculumCode);
        }
      });
  };
  const handleInput = ({ currentTarget: input }, name, formik) => {
    const data = { ...studentValues };
    const { options, selectedIndex } = input;
    if (selectedIndex) {
      let optValue = options[selectedIndex].innerHTML;

      data[input.name] = optValue;
    } else {
      data[input.name] = input.value;
    }
    //  console.log('input' + input.innerHTML)
    if (name === "curriculum") {
      let curriculumCodeValue = getCurriculumCodeFun(
        curriculumList,
        input.value
      );
      data["curriculumCode"] = curriculumCodeValue;
    }
   console.loh("jfdhsafe")
    setStudentValue(data);
  };
  const handleInput1 = (input, name, formik) => {
    const data = { ...studentValues };
    data["curriculum"] = input;
    // const { options, selectedIndex } = input;
    // if (selectedIndex) {
    //     let optValue = options[selectedIndex].innerHTML;

    //     data[input.name] = optValue;
    // } else {
    //     data[input.name] = input.value;
    // }
    //  console.log('input' + input.innerHTML)
    if (name === "curriculum") {
      let curriculumCodeValue = getCurriculumCodeFun(curriculumList, input);
      data["curriculumCode"] = curriculumCodeValue;
    }
    //   console.log('formik' + JSON.stringify(data)
    setStudentValue(data);
  };
  const searchStudent = (event) => {
    // this.setState({ search: event.target.value })
    setSearch(event.target.value);
    let val = String(event.target.value).toLowerCase();
    if (!val) {
      getStudentList();
      return;
    }
    let filter = studentListAll.filter((q) => {
      // console.log('ass--',q)
      try {
        if (q.firstName.toLowerCase().indexOf(val) !== -1) {
          // console.log('ass--', q.fileName)

          return q;
        }
      } catch (err) {}
    });
    // console.log('filter-' + JSON.stringify(filter))
    // this.setState({ videosList: [...filter] })
    setStudentList([...filter]);
  };
  return (
    <Fragment>
      {path === "/admin/student" && (
        <StudentList
          studentList={studentList}
          searchStudent={searchStudent}
          search={search}
          changeStatus={changeStatus}

        />
      )}

      {path === "/admin/student-view/:id" && (
        <ViewStudent
          mode="View"
          changeStatus={changeStatus}
          studentValues={studentValues}
          staffDelete={staffDelete}
          curriculumData={cur}
        />
      )}

      {path === "/admin/student-edit/:id" && (
        <EditStudent
          mode="edit"
          handlestudentAdd={handlestudentAdd}
          studentValues={studentValues}
          curriculumList={curriculumList}
          handleCurriculum={handleCurriculum}
          handleInput={handleInput}
          editError={editError}
          handleInput1={handleInput1}
        />
      )}
    </Fragment>
  );
};

export default StudentAdminContainer;
