import React, { useState, useEffect, Fragment } from "react";
import styles from "./adminSession.module.css";
import FlatList from "flatlist-react";
import Toggle from "react-toggle";
import { Modal, Button } from "react-bootstrap";

import "./toogle.css";
import { indigo } from "@material-ui/core/colors";
import Select from "../common/select";
import { getAllTeachers, inviteTeacher } from "../../Schedule/api";
import { teacherListFun } from "../../../utils/utils";
import moment from "moment";
// import {deleteSession} from "../../../services/Admin/staffService"
const AdminSessionComp = ({
  changeTime,
  deleteAdminSession,
  removeTeacher,
  accTeacherList,
  addTeacher,
  sendMessage,
  handleClose,
  session,
  saveSession,
  teacherList,
  show,
  sendInvite,
  sessionList,
  statusChange,
  toogle,
  setToogle,
  getSession,
  texts
}) => {
  // const deleteAdminSession = (id) =>{
  //   deleteSession(id)
  //   .then((res)=>{
  //     console.log(res)
  //   })
  //   .catch((err)=>{
  //     console.log(err)
  //   })
  // }


  const setTeacherArr = (value) => {
    // console.log(value);
  };
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-12">
          <h1 className="form-heading">Session</h1>
          <div style={{ display: "flex" }}>
            <Toggle
              id="cheese-status"
              defaultChecked={toogle}
              onChange={() => setToogle((state) => !state)}
            />
            <p
              className="ml-4"
              htmlFor="cheese-status "
              style={{ marginLeft: "15px" }}
            >
             {texts}
            </p>
          </div>
          <FlatList
            list={sessionList}
            renderItem={(q) => {
              // if (q.status === "ACCEPTED") {
              //   return;
              // }
              // if (!toogle && q.student.length < 10) {
              //     return;
              // }
              let start, end, len;
          
              start = q.start.split(" ")[0];
              end = q.end.split(" ")[0];
              len = q.students.length;
              return (
                <div class="card p-3 mt-2 mb-2">
                  <h5 class={`card-title mb-0 ${styles.adminSession}`}>
                    {" "}
                    <img
                      className="mr-3"
                      src="https://img.icons8.com/ios-glyphs/30/000000/arrow.png"
                    />
                    {q.curriculum} - {q.subject} - {q.topics} {q.sessionID}{" "}
                    <br /> <hr />
                  </h5>
                  <div class="card-body row pt-0">
                    <div className="col-md-6">
                      <p className={`mb-2 ${styles.head}`}>{q.title}</p>
                      <div className="row">
                        <div className="col-4 d-flex flex-column align-items-center">
                          <p className="">Start</p>
                          <p
                            className={`${styles.date}`}
                            // style={{ backgroundColor: "#4A1D75" }}
                          >
                            {start}
                          </p>
                          <p
                            className={`${styles.date} mt-2`}
                            // style={{ backgroundColor: "#4A1D75" }}
                          >
                            {moment(q.start).format("hh:mm A")}
                          </p>
                        </div>
                        <div className="col-4 d-flex flex-column align-items-center">
                          <p>End</p>
                          <p
                            className={`${styles.date} `}
                            // style={{ backgroundColor: "#4A1D75" }}
                          >
                            {end}
                          </p>
                          <p
                            className={`${styles.date} mt-2`}
                            // style={{ backgroundColor: "#4A1D75" }}
                          >
                            {moment(q.end).format("hh:mm A")}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <p className={`mb-2 ${styles.head} text-center `}>
                        Total Students
                      </p>
                      <p className={`${styles.num} `}>{len}</p>
                    </div>
                    <div className="col-md-3 text-center">
                      {/* <button
                                        className={`btn btn-danger ${styles.click}`}
                                        style={{ borderRadius: '20px' }}
                                    >
                                        Cancel
                                    </button> */}
                      <br />
                      <button
                        className={` ${styles.click2}`}
                        style={{ borderRadius: "20px" }}
                        onClick={() => saveSession(q)}
                      >
                        Accept
                      </button>
                      <button
                        className={` ${styles.click1}`}
                        style={{ borderRadius: "20px" }}
                        onClick={(e) => {
                          deleteAdminSession(q._id);
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              );
            }}
          />
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        {/* <Modal.Header closeButton>
                <Modal.Title>Modal heading</Modal.Title>
            </Modal.Header> */}
        <Modal.Body>
          <div className="text-view mb-3 ">
            <label>Curriculum</label>
            <span>{session.curriculumCode}</span>
          </div>
          <div className="text-view mb-3">
            <label>subject</label>
            <span>{session.subject}</span>
          </div>
          {/* <div className="text-view">
                    <label>teacher</label>
                    <select
                        name='teacher'
                        // optname={optname}
                        id='teacher'
                        value={teacherList}
                        onChange={(event) => setTeacherArr(event.target.value)}
                        className="form-control"
                    >
                        <option value="">Select</option>
                        <option value="all">Invite all</option>

                        {teacherList.map((option) => {

                            

                                return (
                                    <option key={option._id} value={option._id}>
                                        {option.firstName} {option.lastName}
                                    </option>
                                )
                            
                           

                        })}
                    </select>
                   
                </div> */}
          <div className="text-view mb-3 ">
            <label>Start Date</label>
            <input
              type="date"
              onChange={(value) => {
                changeTime("startDate", value.target.value);
              }}
              value={session.startDate}
              className="form-control"
              placeholder="date"
            />
          </div>
          <div className="text-view mb-3 ">
            <label>Start Time</label>
            <input
              type="time"
              onChange={(value) => {
                changeTime("startTime", value.target.value);
              }}
              value={session.startTime}
              className="form-control"
              placeholder="time"
            />
          </div>

          <div className="text-view mb-3 ">
            <label>End Date</label>
            <input
              type="date"
              value={session.endDate}
              onChange={(value) => {
                changeTime("endDate", value.target.value);
              }}
              className="form-control"
              placeholder="date"
            />
          </div>
          <div className="text-view mb-3 ">
            <label>End Time</label>
            <input
              type="time"
              value={session.endTime}
              onChange={(value) => {
                changeTime("endTime", value.target.value);
              }}
              className="form-control"
              placeholder="time"
            />
          </div>
          <div>
            <label>Available Teacher</label>
            <div className={styles.acceptedTecher}>
              <ul className="list-group row col-12">
                {teacherList.map((option) => {
                  return (
                    <li
                      key={option._id}
                      className="list-group-item d-flex justify-content-between"
                    >
                      <span>
                        {" "}
                        {option.firstName} {option.lastName}
                      </span>{" "}
                      <button
                        type="button"
                        onClick={() => addTeacher(option)}
                        class="btn btn-success btn-sm"
                      >
                        add
                      </button>{" "}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <label>Accepted Teacher</label>
          <div className={styles.acceptedTecher}>
            <ul className="list-group row col-12">
              {accTeacherList.map((option) => {
                return (
                  <li
                    key={option._id}
                    className="list-group-item d-flex justify-content-between"
                  >
                    <span>
                      {" "}
                      {option.firstName} {option.lastName}
                    </span>{" "}
                    <button
                      type="button"
                      onClick={() => removeTeacher(option)}
                      class="btn btn-danger btn-sm"
                    >
                      Delete
                    </button>{" "}
                  </li>
                );
              })}
            </ul>
          </div>
          {sendMessage && <p>Invite send to teacher</p>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={sendInvite}>
            Invite
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AdminSessionComp;
