import React, { Component } from "react";
import AssignmentType from "../../components/Students/Assignments/AssignmentType";
import FormalAssignments from "../../components/Students/Assignments/FormalAssignments";
import InformalAssignments from "../../components/Students/Assignments/InformalAssignments";
import AssignmentPreview from "../../components/Students/Assignments/AssignmentPreview";
import TestView from "../../components/Students/Assignments/TestView";
import TestResult from "../../components/Students/Assignments/TestResult";
import { getUserCurriculum } from "../../services/Student/CurriculumStudentService"
import { setVideolink } from "../../services/Student/SessionService";
import LoadingModal from "../../components/Share/LoadingModal";
import { videoUpload } from "../../services/Admin/videoService";
import fscreen from 'fscreen';
import { FullScreen, useFullScreenHandle } from "react-full-screen";

import {
  getAssignments,
  getTestsList,
  getAssignmentByID,
  getTestQuesList,
  testSubmit,
  getResult,
  getAssignmentResult,
  getMeeting,
  checkStudentTest
} from "../../services/Student/AssignmentService";

import {
  userCurriculumCode,
  userInfo,
  userID,
  userEmail,
} from "../../services/Authentication/AuthService";
import AssignmentResult from "../../components/Students/Assignments/AssignmentResult";

class AssignmentsContainer extends Component {
  state = {
    isType: "formal",
    assignmentList: [],
    assignmentIndividual: {},
    questions: [],
    queAnsObj: [],
    testResult: [],
    showResult: {},
    result: {},
    meetingLink: "",
    show: true,
    isLoading: false,
  };

  assignmentType = (type) => {
    if (type === "formal") {
      this.setState({ isType: "formal" });
    } else if (type === "informal") {
      this.setState({ isType: "informal" });
    }
  };

  getAllAssignments = async () => {
    getUserCurriculum(userID()).then((res) => {
      var data = res.data.data;
      let code = [];
      data.map((e) => {
        code.push(e.curriculumCode);
      });
      const payload = {
        curriculumCode: code,
      };
      getAssignments(payload)
        .then((res) => {
          if (res.status === 200) {
            this.setState({ assignmentList: res.data.data });
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    });
  };
  getMeetingLink = async () => {
    this.setState({isLoading:true})
    const id = this.props.match.params.id;
    const payload = {
      curriculumCode: userCurriculumCode(),
      testID: id,
      studentId: userID(),
      email: userEmail(),
    };

    await getMeeting(payload)
      .then((res) => {
        if (res.status === 200) {
          this.setState({ meetingLink: res.data.data.joinUrl });
          this.setState({ show: false });
          this.setState({isLoading:false})

          window.open(res.data.data.joinUrl);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
    return true;
  };
  assignmentPreview = async () => {
    const id = this.props.match.params.id;
    const payload = {
      curriculumCode: userCurriculumCode(),
      testID: id,
    };
    getAssignmentByID(payload)
      .then((res) => {
        if (res.status === 200) {
          sessionStorage.setItem("assigDet", JSON.stringify(res.data.data));

          this.setState({ assignmentIndividual: res.data.data });
          if (res.data.data.correctionMode === "automatic") {
            let payload = {
              testID: res.data.data.testID,
              userID: userID(),
            };
            getResult(payload)
              .then((res) => {
                let data;

                data = res.data.data;
                if (data.length !== 0) {
                  console.log(data);
                  data["statuss"] = true;
                  this.setState({ showResult: data[0] });
                }
              })
              .catch((err) => {});
          } else {
            console.log(this.state.result);
            this.setState({ showResult: this.state.result });
            // this.getResultAssignment()
          }

          // this.setState({ assignmentIndividual: res.data.data });
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  getResultAssignment = async () => {
    const id = this.props.match.params.id;
    const payload = {
      testID: id,
      userID: userID(),
    };
    console.log(payload, "okokok");
    getAssignmentResult(payload)
      .then((res) => {
        if (res.data.error === false) {
          let data = res.data.data;
          console.log(res.data.data);
          data["statuss"] = true;
          this.setState({ result: data });
        } else {
          let data = {};
          data["statuss"] = false;
          this.setState({ result: data });
        }
      })
      .catch((err) => {
        let data = {};
        data["statuss"] = false;
        this.setState({ result: data });
        console.log("err");
      });
  };

  getQuestions = async () => {
    const id = this.props.match.params.id;
    const payload = {
      testID: id,
    };
    getTestQuesList(payload)
      .then((res) => {
        this.setState({ questions: res.data.data });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  handleOption = (e, question) => {
    const queAnsObj = this.state.queAnsObj;
    const queObj = { ...question };
    const valid = question.correctanswer === e.target.value;
    queObj.valid = valid;
    queObj.response = e.target.value;
    queObj.responsedate = new Date();
    queAnsObj.push(queObj);
    this.setState({ queAnsObj });
  };

  handleOptionText = (data, question) => {
    const queAnsObj = this.state.queAnsObj;
    const queObj = { ...question };
    queObj.response = data;
    queObj.correctanswer = data;
    queObj.responsedate = new Date();

    queAnsObj.push(queObj);
    this.setState({ queAnsObj });
  };

  submitTest = async (questions,file) => {
    const data = new FormData();
    data.append(
      "profileImage",
      file,
      "webCamTestVideo"
    );
    let headers = {
      accept: "application/json",
      "Accept-Language": "en-US,en;q=0.8",
      "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
    };
    videoUpload(data, headers).then(res=>{
      this.setState({isLoading:true})
      console.log(res.data)
      if(res.data){

        const assignmentDetails = JSON.parse(sessionStorage.getItem("assigDet"));
        const id = this.props.match.params.id;
        const queAnsObj = this.state.queAnsObj;
        const userData = userInfo();
        userData["testID"] = id;
    
        function getUniqueListBy(queAnsObj, key) {
          return [...new Map(queAnsObj.map((item) => [item[key], item])).values()];
        }
    
        const uniqQueObj = getUniqueListBy(queAnsObj, "_id");
    
        const quesLength = questions.length;
    
        let totalMarks = 0;
        let score = 0;
        let correctQues = 0;
        let wrongQues = 0;
        let temp = {};
        let optionQues = [];
        uniqQueObj.map((r, i) => {
          totalMarks += parseInt(r.questionmarks);
          if (r.valid === true) {
            if (assignmentDetails.assignmentMode === "Optional") {
              optionQues.push(parseInt(r.questionmarks));
              correctQues += 1;
              optionQues.sort(function (a, b) {
                return b - a;
              });
    
              if (assignmentDetails.optionalQuestions < optionQues.length) {
                optionQues.splice(assignmentDetails.optionalQuestions);
              }
    
              score = optionQues.reduce(function (a, b) {
                return a + b;
              }, 0);
            } else {
              score += parseInt(r.questionmarks);
              correctQues += 1;
            }
          } else {
            wrongQues += 1;
          }
          temp.score = score;
          temp.correctQues = correctQues;
          temp.wrongQues = wrongQues;
          temp.totalMarks = totalMarks;
          let video ={
            filename:res.data.fileName,
            videoLink:res.data.fileLocation
          }
          temp.testVideo=video
          return temp;
        });
    
        const resultObj = {
          assessmentName: assignmentDetails.assessmentName,
          createDate: assignmentDetails.createDate,
          curriculum: assignmentDetails.curriculum,
          curriculumCode: assignmentDetails.curriculumCode,
          enddate: assignmentDetails.enddate,
          startdate: assignmentDetails.startdate,
          subject: assignmentDetails.subject,
          testID: assignmentDetails.testID,
          type: assignmentDetails.type,
          correctionMode: assignmentDetails.correctionMode,
          totalQuestions: quesLength,
          correctAnsweredQues: temp.correctQues,
          wrongAnsweredQues: temp.wrongQues,
          totalMarks: assignmentDetails.maxWeightage,
          achievedMarks: temp.score,
          userID: userID(),
          testVideo:{
            filename:res.data.fileName,
            videoLink:res.data.fileLocation
          }
        };
    
        const payload = {
          globalInfo: userData,
          transaction: uniqQueObj,
          result: resultObj,
        };
        console.log(payload)
    
        testSubmit(payload)
          .then((res) => {
            this.props.history.replace(`/student/test-result/${id}`);
            window.location.reload();
            this.setState({isLoading:false})
          })
          .catch((err) => {
            console.log("err", err);
          });
      }
    })
  
  };

  setVideo() {
    const id = this.props.match.params.id;
    setVideolink({
      testID: id,
      studentId: userID(),
    }).then((res) => {
      return res.data;
    });
  }

  getResult = async () => {
    const id = this.props.match.params.id;
    const payload = {
      userID: userID(),
      testID: id,
    };
    const payload1 = {
      testID: id,
    };
    getAssignmentByID(payload1).then((res) => {
      this.setState({ assignmentIndividual: res.data.data });
    });
    getResult(payload)
      .then((res) => {
        this.setState({ testResult: res.data.data });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  closeModal = () => {
    this.setState({ show: false });
    this.props.history.push("/student/assignments");
  };
  async componentDidMount() {
    // console.log("fxreen" + fscreen.fullscreenEnabled)
    this.getResultAssignment();
    this.getAllAssignments();
    if (this.props.match.path === "/student/assignment-preview/:id") {
      // this.getMeetingLink()
      this.assignmentPreview();
    } else if (this.props.match.path === "/student/test-view/:id") {
      this.getQuestions();
    } else if (this.props.match.path === "/student/test-result/:id") {
      this.getResult();
    } else if (this.props.match.path === "/student/assignment-result/:id") {
      this.assignmentPreview();
    }
  }

  getTime = () => {
    let date = new Date();
    let hours = date.getHours();
    let min = date.getMinutes();
    return { hours, min };
  };
  studentTest = async (testID, userID) => {
    let payload = {
      testID: testID,
      userID: userID,
    };
    console.log(payload);
    let result = await checkStudentTest(payload)
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => console.log(err));
    console.log(result);
    if (result) {
      if (result.test) {
        this.setState({ show: false });
      }
    }
    return result;
  };
  render() {
    const path = this.props.match.path;
    const {
      isType,
      assignmentList,
      assignmentIndividual,
      questions,
      testResult,
      isLoading,
    } = this.state;
    return (
      <React.Fragment>
        {path === "/student/assignments" && (
          <React.Fragment>
            <AssignmentType
              isType={isType}
              assignmentType={this.assignmentType}
            />
            {isType === "formal" && (
              <FormalAssignments
                assignmentList={assignmentList}
                assignmentPreview={this.assignmentPreview}
              />
            )}
            {isType === "informal" && (
              <InformalAssignments assignmentList={assignmentList} />
            )}
          </React.Fragment>
        )}
        {path === "/student/assignment-preview/:id" && (
          <AssignmentPreview
            assignmentIndividual={assignmentIndividual}
            closeModal={this.closeModal}
            show={this.state.show}
            getMeetingLink={this.getMeetingLink}
            meetingLink={this.state.meetingLink}
            getTime={this.getTime}
            studentTest={this.studentTest}
          />
        )}
        {path === "/student/assignment-result/:id" && (
          <AssignmentResult
            assignmentIndividual={assignmentIndividual}
            showResult={this.state.showResult}
            getTime={this.getTime}
          />
        )}
        {path === "/student/test-view/:id" && (
          <TestView
            questions={questions}
            submitTest={this.submitTest}
            handleOption={this.handleOption}
            handleOptionText={this.handleOptionText}
            assignmentIndividual={assignmentIndividual}
            assignmentPreview={this.assignmentPreview}
          />
        )}

        {path === "/student/test-result/:id" && (
          <TestResult
            testResult={testResult}
            assignmentIndividual={assignmentIndividual}
            setVideo={this.setVideo}
          />
        )}
        <LoadingModal visible={isLoading} />
      </React.Fragment>
    );
  }
}

export default AssignmentsContainer;
