import React, { useEffect, useState } from "react"; 
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getAllStudentReports } from "../../../services/Teacher/AssignmentService";

export default function StudentReports() {

const [studentList,setStudentList] = useState([]);

let params = useParams()
const getAllStudentList = () =>{
console.log(params.testId)
  getAllStudentReports({
    testID: params.testId,
  })
    .then((res) => {
      console.log(res);
      setStudentList(res.data.data);
    })
    .catch((err) => {
      console.log(err);
    });
}

useEffect (()=>{

  getAllStudentList()
},[])

    const data = [
      {
        id: 111,
        name: "varun",
        curricluman_name: "IIT curriculam",
        subjectnName: "Math",
        marks: 45,
        percente: "45%",
        accept: "status",
        remark: "",
      },
      {
        id: 222,
        name: "Paras",
        curricluman_name: "IIM curriculam",
        subjectnName: "Reasoning",
        marks: 0,
        reject: "",
        percente:0,
        remark: "student caught cheat in exam",
      },
      {
        id: 333,
        name: "Deepshikha",
        curricluman_name: "Neet curriculam",
        subjectnName: "Biology",
        marks: 70,
        percente: "70%",
        accept: "status",
        remark: "",
      },
      {
        id: 444,
        name: "Ankush",
        curricluman_name: "IIT curriculam",
        subjectnName: "Math",
        marks: 85,
        percente: "85%",
        accept: "status",
        remark: "",
      },
    ];
  return (
    <div>
      <div className="col-md-12 p-3 table_container">
         <div className="card-body p-0">
             <div className="table-responsive">
                  <table className="table">
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Student Name</th>
                            <th>Curriculam Name</th>
                            <th>Subject Name</th>
                            <th>Marks</th>
                            <th>Percentage</th>
                            <th>Status</th>
                            <th>Remark</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((res)=>{
                            return (
                              <tr>
                                <td>{res.id}</td>
                                <td>{res.name}</td>
                                <td>{res.curricluman_name}</td>
                                <td>{res.subjectnName}</td>
                                <td>{res.marks}</td>
                                <td>{res.percente}</td>
                                <td>
                                  {res.accept ? (
                                    <button
                                      className="btn btn-sm mt-1"
                                      style={{ backgroundColor: "#E0F6F6" }}
                                    >
                                      <span style={{ color: "#1DB9AA" }}>
                                        {" "}
                                        <b>Accept</b>
                                      </span>
                                    </button>
                                  ) : (
                                    <button
                                      className="btn btn-sm btn-info mt-1"
                                      style={{ backgroundColor: "#fcd2dd" }}
                                    >
                                      <span style={{ color: "#f77e9e" }}>
                                        {" "}
                                        Reject
                                      </span>
                                    </button>
                                  )}
                                </td>
                                <td>{res.remark}</td>
                              </tr>
                            );
                        })}
                    </tbody>
                  </table>
             </div>
         </div>
      </div>
    </div>
  );
}
