import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import "./Auth.css";
import { Formik } from "formik";
import { registerValidation } from "../../validations/StudentValidations";
import { GoogleregisterValidation } from "../../validations/StudentValidations";
import FormikControl from "../../common/Formik/FormikControl";

import { Country, State, City } from "country-state-city";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

import { useHistory } from "react-router-dom";
const Registration = (props) => {
  const { registerData, handleSubmitRegister, query, error } =
    props;

  const [logoImg, setLogoImg] = useState();
  let [country, setcountry] = useState([]);
  let [state, setstate] = useState([]);
  let [city, setcity] = useState([]);
  let [countrycode, setcountrycode] = useState("");
  let [Statedis, setStatedis] = useState(false)
  let [Citydis, setCitydis] = useState(false)
  let [dataaa, setDataa] = useState();
  let [stateData, setStateData] = useState()
  let [cityData, setCityData] = useState()
   let history = useHistory();



  function countrychange(e) {
    setStatedis(true)

    // console.log(e);
    setcountrycode(e.split(",")[0]);
    // console.log(e.split(",")[0]);
    console.log(e.split(",")[1]);

    setDataa(e.split(",")[1])
    setstate([...State.getStatesOfCountry(e.split(",")[0])]);
    // console.log(state)
  }
  function statechange(e) {
    setCitydis(true)
    console.log(e.split(",")[1]);
    setStateData(e.split(",")[1]);
    setcity([...City.getCitiesOfState(countrycode, e.split(",")[0])]);
  }
  let [mobile, setmobile] = useState(0)
  function setValue(e) {
    setmobile(e)
  }

  function citychange(e) {
    console.log(e.split(",")[0]);
    console.log(e);
    setCityData(e.split(",")[0]);

  }

  console.log("log99" + error);

  const [curriculum, setCurriculum] = useState([]);

  useEffect(() => {
    setLogoImg(localStorage.logo);
    setcountry([...Country.getAllCountries()]);
  }, []);

  console.log(country, "data");
  const handleSelect = (event) => {
    const {
      target: { value },
    } = event;
    setCurriculum(typeof value === "string" ? value.split(",") : value);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const [passwordtype, setPasswordtype] = useState('password')
  const [eye, eyeClose] = useState(true)

  const toggle = () => {
    if (passwordtype == 'password') {
      setPasswordtype("text")
      eyeClose(false)
    }
    else {
      setPasswordtype("password")
      eyeClose(true)
    }
  }

  const [valid, setValid] = useState(false)

  function validation() {
    if (cityData != undefined && stateData != undefined && dataaa != undefined) {
      setValid(true);
    }
    else {
      setValid(false);
    }
  }


  // const [countryvalid_err, setCountryvalid_err] = useState(false)

  // function countrycityHandler() {
  //   if (dataaa == undefined) {
  //     console.log("Invalid")
  //     setCountryvalid_err(false)
  //     alert("Invalid")
  //   }
  //   else {
  //     setCountryvalid_err(true)
  //     alert("Valid")
  //   }
  // }

  // const [statevalid_err, setStatevalid_err] = useState(false)

  // function stateHandler() {
  //   console.log(stateData,"<-statedata");
  //   if (stateData == undefined) {
  //     console.log("Invalid")
  //     setStatevalid_err(false)
  //   }
  //   else {
  //     setStatevalid_err(true)
  //   }
  // }


  // const [cityevalid_err, setCityvalid_err] = useState(false)

  // function cityHandler() {
  //   if (cityData == undefined) {
  //     console.log("Invalid")
  //     setCityvalid_err(false)

  //   }
  //   else {
  //     setCityvalid_err(true)
  //   }
  // }

  // function check() {
  //   console.log("city: ", cityevalid_err, "state :", statevalid_err, "Country: ", countryvalid_err)
  //   if (cityevalid_err == true && statevalid_err == true && countryvalid_err == true) {
  //     alert("city")
  //   }
  //   else {
  //     alert("All are false")
  //   }
  // }



  // const [address, setAddress] = useState("")
  // const [address_valid, setAddress_valid] = useState(false)

  // function addressHandler(e) {
  //   setAddress(e.target.value)
  //   let item = e.target.value;

  //   if (item.length < 1) {
  //     console.log("Invalid")
  //     setAddress_valid(true)
  //   }
  //   else {
  //     setAddress_valid(false)
  //   }
  //   validation();
  // }

  // const [pincode, setPincode] = useState("")
  // const [pincode_valid, setPincode_valid] = useState(false)

  // function pincodeHandler(e) {
  //   setPincode(e.target.value)
  //   let item = e.target.value;

  //   if (item.length < 1) {
  //     console.log("Invalid")
  //     setPincode_valid(true)
  //   }
  //   else {
  //     setPincode_valid(false)
  //   }
  //   validation();
  // }

  // const [valid, setValid] = useState(false)
  // function validation(e) {
  //   if (pincode.length > 0 && address.length > 0) {
  //     setValid(true)
  //   }
  //   else {
  //     setValid(false)
  //   }
  //   if (pincode.length > 0 && address.length > 0) {
  //     setValid(true)
  //   }
  //   else {
  //     setValid(false)
  //   }
  // }


  return (
    <div className="register">
      <div className="row">
        <div className="col-md-2 register-left">
          <br />
          <br />
          <br />
          {logoImg && (
            <img
              src={logoImg}
              alt="logo"
              width="100%"
              style={{ margin: "23px auto", display: "block" }}
            />
          )}
          <div className=" exists-acc">
            <p style={{ color: "#222648" }}>
              Already have an account? <br />
              <strong>
                <Link to="/" style={{ color: "#222648" }}>
                  Login Here
                </Link>
              </strong>
            </p>
          </div>
        </div>
        <div className="col-md-10 pad-0">
          <div className="register-right">
            <h3 className="register-heading" style={{ color: "#4A1D75" }}>
              Registration
            </h3>
            <div className="row register-form">
              <div className="col-md-12 pad-0">
                <Formik
                  initialValues={registerData}
                  validationSchema={
                    query ? GoogleregisterValidation : registerValidation
                  }
                  validateOnMount
                  enableReinitialize
                >
                  {(formik) => {
                    const { handleSubmit } = formik;
                    const data = formik.values;
                    data.country = dataaa;
                    data.state = stateData;
                    data.city = cityData;

                    console.log(data);

                    return (
                      <form onSubmit={handleSubmit}>
                        <div className="row section-row">
                          <div className="col-md-4">
                            <FormikControl
                              control="input"
                              type="text"
                              name="firstName"
                              placeholder="First Name"
                            />
                          </div>
                          <div className="col-md-4">
                            <FormikControl
                              control="input"
                              type="text"
                              name="middleName"
                              placeholder="Middle Name"
                            />
                          </div>
                          <div className="col-md-4">
                            <FormikControl
                              control="input"
                              type="text"
                              name="lastName"
                              placeholder="Last Name"
                            />
                          </div>
                          <div className="col-md-4">
                            {/* <FormikControl
                              control="input"
                              type="text"
                              name="mobile"
                              placeholder="Mobile Number"
                            /> */}
                            <PhoneInput
                              placeholder="Enter phone number"
                              // value={value}
                              onChange={(e) => setValue(e)}
                            />
                          </div>
                          <div className="col-md-4">
                            <FormikControl
                              control="input"
                              type="text"
                              name="email"
                              disabled={Boolean(query)}
                              placeholder="Email ID"
                            />
                          </div>
                          {!query && (
                            <div className="col-md-4">
                             <div className="password">
                             <FormikControl
                                control="input"
                                type={passwordtype}
                                name="password"
                                placeholder="Password"
                              />
                              <span id="eye_icon">
                              
                                <i
                                  onClick={toggle}
                                  className={`fa ${
                                    eye ? "fa-eye-slash" : "fa-eye"
                                  }`}
                                  // style={{ zIndex: 1000 }}
                                ></i>{" "}
                              </span>
                             </div>
                            </div>
                          )}

                          <div className="col-md-4">
                            <FormikControl
                              control="select"
                              name="gender"
                              placeholder="Select Gender"
                              options={[
                                { _id: 1, name: "Male" },
                                { _id: 2, name: "Female" },
                                { _id: 3, name: "Other" },
                              ]}
                            />
                          </div>
                          <div className="col-md-4">
                            <FormikControl
                              control="date"
                              placeholderText="Date of Birth"
                              name="dob"
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              maxDate={new Date()}
                            />
                          </div>
                          {/* <div className="col-md-4">
                            <Typography>Select Curriculum</Typography>
                            <Select
                              multiple
                              value={curriculum}
                              onChange={handleSelect}
                              input={
                                <OutlinedInput label="Select Curriculum" />
                              }
                              MenuProps={MenuProps}
                            >
                              {curriculumList[0] &&
                                curriculumList.map((e) => {
                                  return (
                                    <MenuItem key={e._id} value={e.curriculum}>
                                      {e.curriculum}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                          </div> */}
                        </div>
                        <hr />
                        <div className="row section-row">
                          <div className="col-md-4">
                            <FormikControl
                              control="input"
                              type="text"
                              name="parentOrGardien.parentFirstName"
                              placeholder="Parent/Gaurdian First Name"
                            />
                          </div>

                          <div className="col-md-4">
                            <FormikControl
                              control="input"
                              type="text"
                              name="parentOrGardien.parentMiddleName"
                              placeholder="Parent/Gaurdian Middle Name"
                            />
                          </div>

                          <div className="col-md-4">
                            <FormikControl
                              control="input"
                              type="text"
                              name="parentOrGardien.parentLastName"
                              placeholder="Parent/Gaurdian Last Name"
                            />
                          </div>

                          <div className="col-md-4">
                            <FormikControl
                              control="input"
                              type="text"
                              name="parentOrGardien.parentMobile"
                              placeholder="Parent/Gaurdian Mobile"
                            />
                          </div>

                          <div className="col-md-4">
                            <FormikControl
                              control="input"
                              type="text"
                              name="parentOrGardien.parentEmail"
                              placeholder="Parent/Gaurdian Email"
                            />
                          </div>

                          <div className="col-md-12">
                            <FormikControl
                              control="textarea"
                              name="parentOrGardien.parentAddress"
                              className="form-control"
                              placeholder="Parent/Gaurdian Address"
                              rows="3"
                            />
                          </div>
                        </div>
                        <hr />

                        <div className="row section-row">
                          <div className="col-md-12">
                            <FormikControl
                              control="textarea"
                              type="text"
                              name="address"
                              rows="3"
                              placeholder="Address"
                            />
                          </div>

                          {/* <div className="col-md-4">
                            <FormikControl
                              control="input"
                              type="text"
                              name="country"
                              placeholder="Country"
                            />
                          </div> */}
                          <div className="col-md-4">
                            <select
                              className="Place-Select"
                              onChange={(e) => {
                                countrychange(e.target.value);
                                // countrycityHandler();
                              }}
                            >
                              <option disabled>---select---</option>
                              {country &&
                                country.map((e, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={`${e.isoCode},${e.name}`}
                                    >
                                      {e.name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                          {/* <div className="col-md-4">
                            <FormikControl
                              control="input"
                              type="text"
                              name="state"
                              placeholder="State"
                            />
                          </div> */}
                          <div className="col-md-4">
                            <select
                              className="Place-Select"
                              aria-label="Default select example"
                              ariaPlaceholder="dlfahdkf"
                              onChange={(e) => {
                                statechange(e.target.value);
                                // stateHandler();
                              }}
                            >
                              <option value="" disabled={Statedis}>
                                {" "}
                                Select State{" "}
                              </option>
                              {state &&
                                state.map((e, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={`${e.isoCode},${e.name}`}
                                    >
                                      {e.name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                          {/* <div className="col-md-4">
                            <FormikControl
                              control="input"
                              type="text"
                              name="city"
                              placeholder="City"
                            />
                          </div> */}
                          <div className="col-md-4 mb-3">
                            <select
                              className="Place-Select"
                              onChange={(e) => {
                                citychange(e.target.value);
                                // cityHandler();
                              }}
                            >
                              <option value="" disabled={Citydis}>
                                {" "}
                                Select City{" "}
                              </option>
                              {city &&
                                city.map((e, index) => {
                                  return (
                                    <option key={index} value={e.name}>
                                      {e.name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>

                          <div className="col-md-4">
                            <FormikControl
                              control="input"
                              type="text"
                              name="pincode"
                              placeholder="Pin Code"
                            />
                          </div>
                        </div>

                        <hr />
                        <div className="row section-row">
                          <div className="col-md-4">
                            <FormikControl
                              control="input"
                              type="text"
                              name="identityNumber"
                              placeholder="Identity Number"
                            />
                          </div>

                          <div className="col-md-4">
                            <FormikControl
                              control="input"
                              type="file"
                              name="identityUrl"
                            />
                          </div>
                        </div>
                        {error && (
                          <small className="form-text text-danger d-flex justify-content-center">
                            {error}
                          </small>
                        )}
                        <div className="text-center col-md-12">
                          <button
                            style={{
                              backgroundColor: "#4A1D75",
                              color: "white",
                            }}
                            type="submit"
                            className="btn btn-sm mt-4 theam-color"
                            onClick={() => {
                              validation();
                              console.log(mobile);
                              console.log({ ...formik });
                              data.mobile = mobile;
                              if (valid == true) {
                                console.log("hello paras")
                                handleSubmitRegister(formik);
                              } else {
                                alert(
                                  "Please enter (Country , State & City) , If entered click again on register."
                                );
                              }
                            }}
                          >
                            Register
                            {/* <Link to="/Subscription" className="register1">
                              Register
                            </Link> */}
                          </button>
                        </div>
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Registration;
