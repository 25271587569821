import http from "../httpService";
import { apiUrl } from "../../config.json";
import { getAuthToken } from "../Authentication/AuthService";

const headers = {
  "x-access-token": getAuthToken(),
};

export const createAssessment = (payload) => {
  return http.post(apiUrl + "/assessment/createAssessment", payload, {
    headers: headers,
  });
};

export const getAssessmentsList = (payload) => {
  return http.post(apiUrl + "/assessment/getAllAssessment", payload, {
    headers: headers,
  });
};

export const deleteAssessment = (payload) => {
  return http.post(apiUrl + "/assessment/delete/"+ payload.testID, {
    headers: headers,
  });
};

export const getAssignmentByID = (payload) => {
  return http.post(apiUrl + "/assessment/getAssessment", payload, {
    headers: headers,
  });
};