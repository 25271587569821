import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import admindash1 from "../../..//assets/Images/admindash1.png";
import admindash2 from "../../..//assets/Images/admindash2.png";
import admindash3 from "../../..//assets/Images/admindash3.png";
import admindash4 from "../../..//assets/Images/admindash4.png";
import admindash5 from "../../..//assets/Images/admindash5.png";
import { Icon } from "@material-ui/core";
import style from "./Admin.module.css";
import { adminDash } from "../../../services/Admin/commonService";
import { Link } from "react-router-dom";
export default function AdminHomeDash() {
  const [data, setData] = useState({});
  useEffect(()=>{
    adminDash().then(res=>{
      console.log(res.data.data)
      setData(res.data.data)
    })
  },[])
  return (
    <React.Fragment>
      <div>
        {/* <h1 className="form-heading" style={{ color: "#4A1D75" }}>
          Dashboard
        </h1> */}
      </div>
      <div className={style.admindashome}>
        <div className={style.main}>
          <Link to="/admin/curriculum" style={{ textDecoration: "none" }}>
            <div className={style.card}>
              <div className={style.curri}>
                <span>
                  <h6 style={{ color: "black" }}>Total Curriculum</h6>
                </span>
                <img src={admindash1} style={{ width: "40px" }} />
              </div>
              <span style={{ color: "black" }}>
                <b>{data.curriculum}</b>
              </span>
            </div>
          </Link>
          <Link to="/admin/assessment" style={{ textDecoration: "none" }}>
            <div className={style.card2}>
              <div className={style.assess}>
                <span>
                  <h6 style={{ color: "black" }}>Total Assessment</h6>
                </span>
                <img src={admindash2} />
              </div>
              <span style={{ color: "black" }}>
                <b>{data.assesment}</b>
              </span>
            </div>
          </Link>
          <Link to="/admin/session-admin" style={{ textDecoration: "none" }}>
            <div className={style.card3}>
              <div className={style.sessio}>
                <span>
                  <h6 style={{ color: "black" }}>Total Session Schedule</h6>
                </span>

                {/* <span></span> */}

                <img src={admindash3} />
              </div>
              {/* <br /> */}
              <span style={{ color: "black" }}>
                <b>{data.session}</b>
              </span>
            </div>
          </Link>
        </div>
      </div>
      <div className={style.submain}>
        <Link to="/admin/student" style={{ textDecoration: "none" }}>
          <div className={style.card4}>
            <div className={style.student}>
              <span style={{ color: "black" }}>
                <h6>Total Student</h6>
              </span>
              <img src={admindash4} />
            </div>
            <span style={{ color: "black" }}>
              <b>{data.student}</b>
            </span>
          </div>
        </Link>
        <Link to="/admin/staff" style={{ textDecoration: "none" }}>
          <div className={style.card5}>
            <div className={style.teacher}>
              <span style={{ color: "black" }}>
                <h6>Total Teachers</h6>
              </span>
              <img src={admindash5} />
            </div>
            <span style={{ color: "black" }}>
              <b>{data.teacher}</b>
            </span>
          </div>
        </Link>
      </div>
    </React.Fragment>
  );
}
