


export default function Contact() {
  return (
    <div className="container">
      <div className="row mt-3">
        <div className="col-md-6">
          <div className="text-center">
            <div className="font-size">
              <img src="./icons/Group1.png" />
            </div>
            <h4>Email Us</h4>
            <p>support@advstechnosolutions.com</p>
          </div>
        </div>
        <div className="col-md-6">
          <div className="text-center">
            <div className="font-size">
              <img src="./icons/Group2.png" />
            </div>

            <h4>Call Us</h4>
            <p>Tel-011-000-000</p>
          </div>
        </div>
      </div>

      <section>
        <form>
          <h2 className="mt-4 text-center">Get In Touch With Us</h2>
          <div className="row mt-3">
            <div className="col-6">
              <div className="form-group">
                <label htmlFor="name">Your Name</label>
                <input
                  type="text"
                  className="form-control"
                  autoFocus
                  required
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Your Email</label>
                <input
                  type="email"
                  className="form-control"
                  aria-describedby="emailHelp"
                  required
                />
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-6">
              <div className="form-group">
                <label htmlFor="name">Your Phone</label>
                <input type="text" className="form-control" required />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Your Subject</label>
                <input
                  type="email"
                  className="form-control"
                  aria-describedby="emailHelp"
                  required
                />
              </div>
            </div>
          </div>
          <div className="form-group row container">
            <label htmlFor="message">Your Message</label>
            <textarea className="form-control" rows="5" />
          </div>

          <div className="d-flex justify-content-center">
            <input
              type="submit"
              className="btn btn-primary "
              value="SEND MESSAGE"
            />
          </div>
        </form>
      </section>
    </div>
  );
}
