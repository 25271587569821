import React, { useState, useEffect, Fragment } from 'react'
import TeacherSessionComp from '../../components/Teachers/session/teacherSessionComp'
import {
    userEmail,
    userRole,
    userCurriculumCode,
} from "../../services/Authentication/AuthService";
import { getAllSessions, getAllTeachers } from '../../services/Student/SessionService';
import { sessionStatus } from '../../services/Student/SessionService';
import AdminSessionComp from '../../components/admin/session/adminSession';
import Toggle from 'react-toggle'
import '../../components/admin/session/toogle.css'
import { inviteTeacher } from '../../components/Schedule/api';
import {deleteSession} from "../../services/Admin/staffService"

const AdminSessionContainer = () => {
    const [toogle, setToogle] = useState(true)
    const [sessionFilterList, setSessionFilterList] = useState([])
    const [show, setShow] = useState(false);
    const [teacherList, setTeacherList] = useState([])
    const [allTeacherList, setAllTeacherList] = useState([])
    const [accTeacherList, setAccTeacherList] = useState([])
    const [session, setSession] = useState({ curriculumCode: "", subject: "" })
    const [sendMessage, setsendMessage] = useState(false)
    const [teacherSelect, setTeacherSelect] = useState('')
    const [texts,setText]= useState('Show All Session')
    const [sessionList, setSessionList] = useState([])
    useEffect(() => {
        getSession()
    }, [])
    useEffect(() => {
        filterList(sessionList)
    }, [toogle])


    const getSession = () => {
        setSessionList([])
        setSessionFilterList([])
        const payload = { }
    
        getAllSessions(payload).then(res => {
            if (res.status === 200) {
                // console.log('res' + JSON.stringify(res.data))
                let resp = res.data.data.reverse()
                setSessionList(resp)
                filterList(resp)
            } else {
                console.log('error')
            }
        })
    }

    
      const deleteAdminSession = (id) => {
        deleteSession(id)
          .then((res) => {
            console.log(res);
           getSession();
          })
          .catch((err) => {
            console.log(err);
          });
      };
    const statusChange = (payload) => {
        sessionStatus(payload).then(res => {
            if (res.status === 200 && res.data.status) {
                getSession()
            } else {
                console.log('error in session')
            }
        })
    }
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const sendInvite = () => {
        let teach
        teach = {
            teacherID: '',
            userID: '',
            email: 'all',
            curriculumCode: '',
            sessionID: session.sessionId,
        }

        if (teacherSelect !== 'all') {

            teach = teacherList.find(res => {

                if (res._id === teacherSelect) {
                    return res
                }
            })
        }
        // console.log('teacherSelect-' + teacherSelect)

        const payload = {
            sessionID: session.sessionId,
            acceptedTeacher: accTeacherList,
            start: session.startDate + ' ' + session.startTime,
            end: session.endDate + ' ' + session.endTime
        }

        inviteTeacher(payload).then(res => {
            if (res.status === 200) {
                setsendMessage(true)

                setTimeout(() => {
                    getSession()
                    handleClose()
                }, 3000)


                console.log('invire' + JSON.stringify(res.data))
            } else {
                throw new Error
            }
            handleClose()
        }).catch(err => { console.log(err); handleClose() })
        console.log('pyalod' + JSON.stringify(payload))
    }
    const filterList = (payload) => {
        let filter = []
        if (!toogle) {
            setText('Requested Session')
            filter = payload.filter(p => p.role==="student")
            setSessionFilterList(filter)

        } else {
            setText("Show All Session")
            filter = payload.filter(p => p.role==="admin")
            setSessionFilterList(filter)
        }
    }
    const addTeacher = (value) => {
        setAccTeacherList(prev => {
            return [...prev, value]
        })
        let filter = teacherList.filter(q => {
            if (q._id !== value._id) {
                return q
            }
        })
        setTeacherList(filter)
    }
    const removeTeacher = (value) => {
        let filter = accTeacherList.filter(q => {
            if (q._id !== value._id) {
                return q
            }
        })
        let filter1 = allTeacherList.filter(q => {
            let check = filter.some(el => el._id === q._id)
            if (!check) {
                return q
            }
        })
        setTeacherList(filter1)
        setAccTeacherList(filter)
    }
    const changeTime = (field, value) => {
        // console.log('field-',field,'-value-',value)
        setSession(prev => {
            return { ...prev, [field]: value }
        })
    }
    const saveSession = (info) => {
        // console.log('info--' + JSON.stringify(info))
        let startDate = info.start.split(' ')[0]
        let endDate = info.end.split(' ')[0]
        let startTime = info.start.split(' ')[1]
        let endTime = info.end.split(' ')[1]

        setAccTeacherList(info.acceptedTeacher ? info.acceptedTeacher : [])

        setSession({ curriculumCode: info.curriculumCode, subject: info.subject, sessionId: info.sessionID, requestedTeacher: info.requestedTeacher, startDate, endDate, startTime, endTime })
        const payload = {
            curriculumCode: info.curriculumCode,
            subject: info.subject
        }

        setTeacherList([])
        setAllTeacherList([])
        getAllTeachers(payload).then(res => {
            //console.log('sss-' + JSON.stringify(res.data))
            if (res.data.data.length > 0) {
                if (info.acceptedTeacher && info.acceptedTeacher.length > 0) {
                    let check = res.data.data.filter(q => {
                        let some = info.acceptedTeacher.some(el => el._id === q._id)
                        if (!some) {
                            return q
                        }
                    })
                    setTeacherList(check)
                } else {
                    setTeacherList(res.data.data)
                }


                setAllTeacherList(res.data.data)
            } else {
                setTeacherList([])
                setAllTeacherList([])
            }
        }).catch(err => console.log(err))
        handleShow()
    }
    if (sessionFilterList.length === 0) {
        return <Fragment>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <h1 className="form-heading">Session</h1>
                        <Toggle
                            id='cheese-status'
                            defaultChecked={toogle}
                            onChange={() => setToogle(state => !state)}
                        />
                        <label className='ml-2' htmlFor='cheese-status '>Show All Session</label>
                        <p className="form-heading">No session found requested by student</p>

                    </div>

                </div>

            </div>
        </Fragment>

    }
    return <Fragment>
        <AdminSessionComp
            session={session}
            saveSession={saveSession}
            show={show}
            sendInvite={sendInvite}
            sessionList={sessionFilterList}
            getSession={getSession}
            statusChange={statusChange}
            toogle={toogle}
            setToogle={setToogle}
            handleClose={handleClose}
            teacherList={teacherList}
            addTeacher={addTeacher}
            accTeacherList={accTeacherList}
            removeTeacher={removeTeacher}
            changeTime={changeTime}
            deleteAdminSession={deleteAdminSession}
            texts={texts}
        />
    </Fragment>

}


export default AdminSessionContainer