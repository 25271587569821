import React, { useState, useEffect, Fragment, } from "react";
import Logo from "../../assets/Images/logo.png";
import styles from './verify.module.css'
import { useParams, useHistory } from 'react-router-dom'
import { newPassword } from "../../services/Authentication/AuthService";
import { useDispatch, useSelector } from 'react-redux';

const ChangePassword = props => {
    let history = useHistory();
    const [password, setPassword] = useState('')
    const [confirmPass, setConfirmPass] = useState('')
    const [error,setError]=useState('')
    const params = useParams()
    //console.log('para=' + JSON.stringify(params))
    const [logoImg, setLogoImg] = useState('')
    let image = useSelector(state => {
      localStorage.setItem('logo', state.logo.logo)
      return state.logo.logo
    })
    useEffect(() => {
     // console.log('image logo',image)
      setLogoImg(image)
    }, [image])
    const changePass = () => {
        setError(false);
        newPassword({ id: params.id, token: params.token, password: password, confirmPassword: confirmPass }).then(res => {
            if (res.status === 200) {
                if (res.data.status) {
                     history.push("/");

                } else {
                    setError(res.data.message)

                }
            }
        })
            .catch(err => { console.log(err) })
    }

    return <Fragment>
        <div className={styles.cardContainer}>
            <div className={`card ${styles.card}`}>
             {logoImg &&   <img src={logoImg} height="170px"
                    className={styles.image} alt="logo" />}
                <div className="card-body">
                    <div className="mb-3">
                        <label className="form-label">Password</label>
                        <input type="password" className={`form-control ${styles.input}`} id="exampleInputEmail1" onChange={event => setPassword(event.target.value)} />
                        <label className="form-label">Confirm Password</label>

                        <input type="password" className={`form-control ${styles.input}`} id="exampleInputEmail1" onChange={event => setConfirmPass(event.target.value)} />
                      {!error &&  <small className="form-text">"Must Contain 15 Characters, One Uppercase, One Lowercase, One Number and one special case Character</small>}
                        {error && <small className="form-text text-danger">{error}</small>}
                        <button type="submit" className="btn btn-primary mt-3 w-100 mt-2" onClick={changePass} >Submit</button>
                    </div>
                </div>
            </div>
        </div>
    </Fragment>
}
// const style = {
//     card: {
//         width: "50%",
//     },
//     cardImg: {
//         height: 100
//     }
// }

export default ChangePassword