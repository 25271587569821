import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

const Assignment = (props) => {
  const { assignmentList } = props;
  return (
    <div className="row">
      <div className="assessment_heading w-100 mt-2 px-3">
        <h1 className="form-heading mb-0" style={{ fontSize: "23px" }}>
          Assessment Correction List
        </h1>
      </div>
      <div className="col-md-12 p-3 table_container">
        {/* <div className="card"> */}
        <div className="card-body p-0">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Assessment Name</th>
                  <th>Type</th>
                  <th>Curriculum</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {assignmentList &&
                  assignmentList.length > 0 &&
                  assignmentList.map((a) => {
                    return (
                      <tr key={a._id}>
                        <td>{a.testID}</td>
                        <td>{a.assessmentName}</td>
                        <td>{a.type}</td>
                        <td>{a.curriculum}</td>
                        <td>{moment(a.startdate).format("MM/DD/YYYY")}</td>
                        <td>{moment(a.enddate).format("MM/DD/YYYY")} </td>
                        <td>
                          <Link
                            to={`/teacher/assignment-correction/${a.testID}/${a.curriculumCode}`}
                          >
                            <button className="btn btn-sm btn-primary view_icon">
                              <i class="fa-regular fa-eye"></i>
                              View
                            </button>
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default Assignment;
