import React, { useState, useEffect, Fragment } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../../../assets/Images/log.png";
import styles from "./Sidebar.module.css";

const Sidebar = () => {
  const [logoImg, setLogoImg] = useState();
  useEffect(() => {
    setLogoImg(localStorage.logo);
  }, []);
  return (
    <aside className={styles.mainSidebar}>
      <div className={styles.logo}>
        <img src={Logo} alt="logo" />
        {/* <span>Teacher Dashboard</span> */}
        <hr />
      </div>

      <ul
        className={`navbar-nav ml-auto mt-3 ${styles.sidebar_links_container}`}
      >
        <li className="nav-item">
          <NavLink
            to="/teacher/dashboard"
            exact
            activeClassName="active"
            className={`nav-link ${styles.navLink}`}
            style={{ color: "#fff" }}
          >
            <i className={`las la-th-large ${styles.sideIcon}`}></i>
            Dashboard
          </NavLink>
        </li>

        <li className="nav-item">
          <NavLink
            to="/teacher/assignments-list"
            exact
            className={`nav-link ${styles.navLink}`}
            style={{ color: "#fff" }}
          >
            <i className={`las la-calendar ${styles.sideIcon}`}></i>
            Assessments
          </NavLink>
        </li>

        <li className="nav-item">
          <NavLink
            to="/teacher/calender"
            exact
            activeClassName="active"
            className={`nav-link ${styles.navLink}`}
            style={{ color: "#fff" }}
          >
            <i
              className={`las la-calendar ${styles.sideIcon}`}
              style={{ color: "#fff" }}
            ></i>
            Calendar
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to="/teacher/queries-list"
            exact
            activeClassName="active"
            className={`nav-link ${styles.navLink}`}
            style={{ color: "#fff" }}
          >
            <i className={`las la-calendar ${styles.sideIcon}`}></i>
            Queries
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to="/teacher/teacher-session"
            exact
            activeClassName="active"
            className={`nav-link ${styles.navLink}`}
            style={{ color: "#fff" }}
          >
            <i className={`las la-calendar ${styles.sideIcon}`}></i>
            Session
          </NavLink>
        </li>
        {/* <li className="nav-item">
          <NavLink
            to="/teacher/teacher-test-image"
            exact
            activeClassName="active"
            className={`nav-link ${styles.navLink}`}
            style={{ color: "#fff" }}
          >
            <i className={`las la-calendar ${styles.sideIcon}`}></i>
            Assessment Recordings
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to="/teacher/teacher-report-container"
            exact
            activeClassName="active"
            className={`nav-link ${styles.navLink}`}
            style={{ color: "#fff" }}
          >
            <i className={`las la-calendar ${styles.sideIcon}`}></i>
            Teacher Report
          </NavLink>
        </li> */}
        <li className="nav-item">
          <NavLink
            to="/teacher/student-report-one"
            exact
            activeClassName="active"
            className={`nav-link ${styles.navLink}`}
            style={{ color: "#fff" }}
          >
            <i className={`las la-calendar ${styles.sideIcon}`}></i>
            Report
          </NavLink>
        </li>
      </ul>
    </aside>
  );
};

export default Sidebar;
