import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import FormikControl from "../../../common/Formik/FormikControl";
import { scheduleValues } from "../../../constants/AdminInitialValues";
import { scheduleValidation } from "../../../validations/AdminValidations";
import moment from "moment";
import LoadingModal from "../../Share/LoadingModal";
import "../schedule.css";
import styles from './calender.module.css'
import TextError from "../../../common/Formik/TextError";
import { userRole, userID } from "../../../services/Authentication/AuthService";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { indexOf } from "lodash";
import {
  getAllCurriculumList,
  getAllSubjectList,
  getAllTopicsList,
} from "../../../components/Share/GetCurriculumDetails";

import {
  getCurriculumCodeFun,
  curriculumListFun,
  subjectListFun,
  teacherListFun,
} from "../../../utils/utils";

import { getAllTeachers, sessionInsert } from "../api";
import { ConstructionOutlined } from "@mui/icons-material";
import { apiUrl } from "../../../config.json"
import { io } from "socket.io-client";
var socket = io(apiUrl)
class CreateSession extends Component {

  state = {
    scheduleData: scheduleValues,
    modalVisible: false,
    curriculumList: [],
    subjectsList: [],
    topics: [],
    teachersList: [],
    requestedTeacher: [],
    isLoading: false,
    sessionMode: 'Abc',
    facilities: [],
    code: ""
  };
  showvalue1 = function showval1(e) {
    e.target.checked ? this.state.facilities.push(e.target.value) : this.state.facilities.splice(indexOf(this.state.facilities[e.target.value], 1))
    console.log(this.state.facilities)
  }

  constructor(props) {
    super(props);
    this.props.childRef(this);
    this.props.fetchEvent(this);
  }



  getData = (data) => {
    console.log(data);
  }
  handleClose = () => {
    console.log("false");
    this.setState({
      modalVisible: false,
      scheduleData: { curriculum: "", curriculumCode: "", subject: "" },
    });
  };

  handleShow = () => {
    this.setState({
      modalVisible: false,
      scheduleData: { curriculum: "", curriculumCode: "", subject: "" },
    });
    this.setState({ modalVisible: true });
  };

  handleInput = async ({ currentTarget: input }, name, values, subject) => {
    const data = this.state.scheduleData;
    const { options, selectedIndex } = input;
    let code;
    if (selectedIndex) {
      let optValue = options[selectedIndex].innerHTML;
      data[input.name] = optValue;
    } else {
      data[input.name] = input.value;
    }
    if (name === "curriculum") {
      data.subject = "";
      let curriculumCodeValue = getCurriculumCodeFun(
        this.state.curriculumList,
        input.value
      );
      console.log(curriculumCodeValue);
      values.curriculumCode = curriculumCodeValue;
      this.setState({ code: curriculumCodeValue })
      let subjectsList = await getAllSubjectList(curriculumCodeValue);
      this.setState({ subjectsList, curriculumCode: curriculumCodeValue });
    }

    if (name === "subject") {
      this.getAllTeachers(this.state.code, subject);
      values.teacher = "";
      let subjectList = await getAllTopicsList(
        values.curriculumCode,
        input.value
      );
      let subjectTopics = [];
      subjectList.map((s) => {


        return subjectTopics.push(s.topic);
      });

      this.setState({ topics: subjectTopics });
    }

    if (name === "teacher") {
      if (input.value === "all") {
        this.setState({ requestedTeacher: this.state.teachersList });
      } else {
        this.state.teachersList.map((t) => {
          if (t.email === input.value) {
            this.setState({ requestedTeacher: [t] });
          }
        });
      }
    }
  };

  getAllTeachers = (curriculumCode, subject) => {
    const payload = {
      curriculumCode: curriculumCode,
      subject: subject,
    };

    getAllTeachers(payload)
      .then((res) => {
        if (res.status === 200) {

          this.setState({ teachersList: res.data.data });
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  Offline(e) {
    console.log(e)

  }
  handleSubmit = (formik) => {
    console.log("State", "Value is--" + this.state.sessionMode)
    const values = formik.values;


    const data_str = moment.parseZone(values.date).local().format("YYYY-MM-DD");
    const startTime_str = moment.parseZone(values.startTime).local().format("HH:mm");
    const endTime_str = moment.parseZone(values.endTime).local().format("HH:mm");

    const start_str = data_str + " " + startTime_str;
    const end_str = data_str + " " + endTime_str;

    let curriculumCodeValue = getCurriculumCodeFun(
      this.state.curriculumList,
      values.curriculum
    );

    let payload = {
      title: values.title,
      requestedTeacher: this.state.requestedTeacher,
      curriculum: values.curriculum,
      curriculumCode: curriculumCodeValue,
      subject: values.subject,
      topics: this.state.topics,
      desc: values.description,
      start: start_str,
      end: end_str,
      createDate: new Date(),
      createdBy: userID(),
      role: userRole(),
      sessionType: values.sessionType,
      sessionMode: { mode: this.state.sessionMode, address: values.address, facilities: this.state.facilities },

    };


    if (formik.isValid) {
      this.setState({ isLoading: true });
      sessionInsert(payload)
        .then((res) => {
          socket.emit("Notification_teacher", {
            curriculum: payload.curriculum,
            subject: payload.subject,
            sender_id: userID(),
            teacher: payload.requestedTeacher,
            message: `you are assigned with ${payload.subject} session for ${payload.curriculum} `,
            for_msg: "teacher",
            path: "/teacher/teacher-session"
          })
          this.props.fetchEvent(userRole());
          this.setState({ modalVisible: false, isLoading: false });
          this.props.history.push("/admin/schedule");
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
    console.log(payload);
  };

  async componentDidMount() {
    let data = await getAllCurriculumList();
    this.setState({ curriculumList: data });
  }


  render(props) {
    const { modalVisible } = this.state;
    let data = this.state.scheduleData;
    let dd = this.state.dd;
    let curriculumOptions = curriculumListFun(this.state.curriculumList);
    let subjectOptions = subjectListFun(this.state.subjectsList);
    let teacherOptions = teacherListFun(this.state.teachersList);
    return (
      <React.Fragment>
        <Modal size="lg" show={modalVisible} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title style={{ fontSize: "18px" }}>
              Create session
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={scheduleValues}
              validationSchema={scheduleValidation}
              validateOnMount
              enableReinitialize
            >
              {(formik) => {
                const { handleChange } = formik;
                return (
                  <Form>
                    <div className="row sessionForm">
                      <div className="col-md-12">
                        <FormikControl
                          control="input"
                          type="text"
                          name="title"
                          label="Title"
                        />
                      </div>

                      <div className="col-md-12">
                        <div className={styles.relative}>
                          <FormikControl
                            control="select"
                            label="Curriculum"
                            optname="curriculum"
                            name="curriculum"
                            placeholder="Select Curriculum"
                            options={curriculumOptions}
                            value={data.curriculum}
                            onChange={(e) => {
                              handleChange(e);
                              this.handleInput(e, "curriculum", formik.values, "");
                            }}
                          />
                          <KeyboardArrowDownIcon className={styles.drop_icon} />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className={styles.relative}>
                          <FormikControl
                            control="select"
                            label="Subject"
                            optname="subject"
                            name="subject"
                            placeholder="Select Subject"
                            options={subjectOptions}
                            value={data.subject}
                            onChange={(e) => {
                              handleChange(e);
                              this.handleInput(e, "subject", formik.values, e.target.value);
                            }}
                          />
                          <KeyboardArrowDownIcon className={styles.drop_icon} />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="label-heading" htmlFor={"teacher"}>
                            Teachers
                          </label>
                          <div className={styles.relative}>
                            <Field
                              as="select"
                              name="teacher"
                              id="teacher"
                              className="form-control"
                              onChange={(e) => {
                                handleChange(e);
                                this.handleInput(e, "teacher", formik.values, "");
                              }}
                            >
                              <option value="">Select</option>
                              {teacherOptions && teacherOptions.length > 0 && (
                                <option value="all">Invite All Teachers</option>
                              )}
                              {this.state.teachersList.map((option) => (
                                <option key={option._id} value={option.email}>
                                  {option.firstName +
                                    " " +
                                    option.lastName +
                                    " - " +
                                    option.email}
                                </option>
                              ))}
                            </Field>
                            <KeyboardArrowDownIcon className={styles.drop_icon} />
                          </div>
                          <ErrorMessage
                            component={TextError}
                            name={"teacher"}
                          />
                        </div>
                      </div>

                      <div className="col-md-12">

                        {/* <label className="label-heading" htmlFor={"Session"}>
                            Session
                          </label> */}
                        {/* <select class="form-control" id="exampleSelect">
                            <option>Classroom</option>
                            <option>Practical</option>
                          </select> */}
                        <div className={styles.relative}>
                          <FormikControl
                            control="select"
                            name="sessionType"

                            label="Session"
                            placeholder="Select Session Type"

                            options={[
                              { _id: 1, name: "Classroom" },
                              { _id: 2, name: "Practical" },
                            ]}
                          // value={data.sessionType}
                          />
                          <KeyboardArrowDownIcon className={styles.drop_icon} />
                        </div>
                      </div>
                      <div className="col-md-12">


                        {/* <select class="form-control" id="exampleSelect">
                            <option>Online Session</option>
                            <option>Offine Session</option>
                          </select> */}
                        <div className={styles.relative}>
                          <FormikControl
                            control="select"
                            name="sessionMode"
                            placeholder="Select Session Mode"

                            options={[
                              { _id: 1, name: "Online Session" },
                              { _id: 2, name: "Offine Session" },
                            ]}
                            // optname={this.state.sessionMode}

                            onChange={(e) => {
                              this.setState({ sessionMode: e.target.value })
                            }

                            }
                          />
                          <KeyboardArrowDownIcon className={styles.drop_icon} />
                        </div>
                      </div>

                      {

                        this.state.sessionMode === "Offine Session" ?
                          <React.Fragment>
                            <div className="col-md-12">
                              <FormikControl
                                control="textarea"
                                type="text"
                                name="address"
                                label="Address"
                                rows="5"
                              />
                            </div>
                            <b style={{ margin: "15px", fontWeight: "100px", marginTop: "0px" }}>Facilities: </b>
                            <span style={{ marginLeft: "25px", marginRight: "70px", fontSize: "16px" }}>AC
                              <input type="Checkbox" value='AC' /*onChange={first('user is danish')}*/ onChange={(e) => { this.showvalue1(e) }} style={{ marginLeft: "5px" }}></input></span>


                            <span style={{ marginLeft: "25px", marginRight: "70px", fontSize: "16px" }}>Fan
                              <input type="Checkbox" value='Fan' onChange={(e) => { this.showvalue1(e) }} style={{ marginLeft: "5px" }}></input></span>


                            <span style={{ marginLeft: "25px", marginRight: "70px", fontSize: "16px" }}>Cooler
                              <input type="Checkbox" value='Cooler' onChange={(e) => { this.showvalue1(e) }} style={{ marginLeft: "5px" }}></input></span>



                            <span style={{ marginLeft: "25px", marginRight: "70px", fontSize: "16px" }}>Refrigerator
                              <input type="Checkbox" value='Refrigerator' onChange={(e) => { this.showvalue1(e) }} style={{ marginLeft: "5px" }}></input></span>

                          </React.Fragment> : null
                      }

                      <div className="col-md-12">
                        <FormikControl
                          control="textarea"
                          type="text"
                          name="description"
                          label="Description"
                          rows="5"
                        />
                      </div>

                      <div className="col-md-12">
                        <FormikControl
                          control="date"
                          label="Date"
                          name="date"
                          minDate={new Date()}
                        />
                      </div>

                      <div className="col-md-6">
                        <FormikControl
                          control="date"
                          label="Start Time"
                          name="startTime"
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time (GMT +5:30)"
                          dateFormat="hh:mm a"
                          timeFormat="hh:mm a"
                        />
                      </div>
                      <div className="col-md-6">
                        <FormikControl
                          control="date"
                          label="End Time"
                          name="endTime"
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time (GMT +5:30)"
                          dateFormat="hh:mm a"
                          timeFormat="hh:mm a"
                        />
                      </div>

                      <div className="text-center col-md-12">
                        <button
                          type="submit"
                          className="btn btn-sm btn-primary mt-4"
                          onClick={() => this.handleSubmit(formik)}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </Modal.Body>
        </Modal>
        <LoadingModal visible={this.state.isLoading} />
      </React.Fragment>
    );
  }

}

export default CreateSession;
