import React, { Component } from "react";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";

import CreateSession from "./createSession";
import EventInfoModal from "./EventInfoModal";
import StudentEventInfoModal from "./StudentEventInfoModal";
import TeacherEventInfoModal from "./TeacherEventInfoModal";

import { getAllSessions } from "../api";
import { getUserCurriculum } from "../../../services/Student/CurriculumStudentService";
import {
  userEmail,
  userRole,

  userID
} from "../../../services/Authentication/AuthService";

const propTypes = {};
const localizer = momentLocalizer(moment);

class AdminTeacherEvent extends React.Component {
  render() {
    const cl = "event__" + this.props.event.status;
    return (
      <div>
        <span className={cl}>{this.props.title}</span>
      </div>
    );
  }
}
class StudentEvent extends React.Component {
  render() {
    const cl = "event__" + this.props.event.status;
    return (
      <div>
        <span className={cl}>{this.props.title}</span>
      </div>
    );
  }
}

class Calenders extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      loading: false,
      events: ['2021-08-11', "2021-08-21"],
      eventInfo: [],
      subjects: [],
      dayLayoutAlgorithm: "no-overlap",
      modalVisible: false,
      role: "",
    };
    this.createFormRef = React.createRef();
    this.eventInfomRef = React.createRef();
    this.eventStudentInfoRef = React.createRef();
    this.eventTeacherInfoRef = React.createRef();
  }

  eventStyleGetter = (event) => {
    // var backgroundColor = "#" + event.hexColor;
    var backgroundColor = "";
    var style = {
      backgroundColor: backgroundColor,
      borderRadius: "0px",
      color: "black",
      border: "0px",
      display: "block",
    };

    if (event.status === "PENDING") {
      style.backgroundColor = "#03A9F4";
    } else if (event.status === "ACCEPTED") {
      style.backgroundColor = "#4CAF50";
    } else if (event.status === "REJECTED") {
      style.backgroundColor = "#F44336";
    }


    return {
      style: style,
    };
  };

  fetchEvent = (role) => {
   
    if (role !== 'student' && role !== 'teacher' && role !== 'admin' && role !== 'superadmin')
    {
      return
    }
    let payload;
    if (role === "student") {
      getUserCurriculum(userID()).then((res)=>{
        var data=res.data.data;
        console.log(res);
       let code=[]
       data.map((e)=>{
         code.push(e.curriculumCode)
       })
      payload = {
        role: "student",
        curriculumCode: code,
        email: userEmail(),
      };
    })
    } else if (role === "teacher") {
      payload = {
        role: "teacher",
        email: userEmail(),
      };
    } else if (role === "admin") {
      payload = {
        role: "admin",
      };
    }
    getAllSessions(payload)
      .then((res) => {
        if (res.status === 200) {
          let events;
          console.log("res.data.data", res.data.data);
          if (role === "student") {
            events = res.data.data.filter((e) => e.status === "ACCEPTED");
          } else {
            events = res.data.data;
          }
          events = events.map((item, index) => {
            return {
              id: item._id,
              title: item.title,
              requestedTeacher: item.requestedTeacher,
              curriculum: item.curriculum,
              curriculumCode: item.curriculumCode,
              subject: item.subject,
              topics: item.topics,
              desc: item.desc,
              start: new Date(item.start),
              end: new Date(item.end),
              sessionID: item.sessionID,
              allDay: false,
              studentEmails: item.studentEmails,
              student: item.student,
              status: item.status,
              rejectedTeacher: item.rejectedTeacher || "",
              acceptedTeacher: item.acceptedTeacher || "",
              link: item.link || "",
            };
          });
          this.setState({
            loading: false,
            events: events,
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  showInfoEvent = (e) => {
    const role = this.state.role;
    console.log("hanfde", role)
    if (role === "admin") {
      this.setState({ eventInfo: e });
      this.eventInfomRef.handleEventShow();

    } else if (role === "teacher") {
      this.setState({ eventInfo: e });
      this.eventTeacherInfoRef.handleEventShow();
    } else if (role === "student") {
      this.setState({ eventInfo: e });
      this.eventStudentInfoRef.handleEventShow();
    }
  };

  componentDidMount() {
    this.fetchEvent(userRole());
    this.setState({ role: userRole() });
  }

  render() {
    return (
      <React.Fragment>
        <div className="row">
          {/* <div className={`col-md-12 ${styles.dashimg} mb-2 `} >
            <img src={calender} style={{ width: '100%', height: '100%' }} />
          </div> */}
          <div className="calender_heading w-100 mt-2">
            <h1 className="form-heading">Calendar</h1>
          </div>
          {this.state.role === "admin" && (
            <div className="add-btn-div">
              <button
                className="btn btn-sm  add-btn"
                onClick={() => this.createFormRef.handleShow()}
                style={{
                  borderRadius: "40px",
                  backgroundColor: "#4A1D75",
                  border: "2px solid #4A1D75",
                  width: "140px",
                  textAlign: "center",
                  padding: "6px",
                  color: "white",
                  height: "auto",
                  marginBottom:"20px"
                }}
              >
                Add New
              </button>
            </div>
          )}
          <div className="col-md-12">
            <div className="card">
              <div className="card-body p-3 cal_con">
                {this.state.role !== "student" && (
                  <div className="color-status color_status_div">
                    <span className="color-pending">PENDING</span>
                    <span className="color-accepted">ACCEPTED</span>
                    <span className="color-rejected">REJECTED</span>
                  </div>
                )}
                <Calendar
                  selectable
                  localizer={localizer}
                  events={this.state.events}
                  defaultView={Views.month}
                  scrollToTime={new Date(1970, 1, 1, 6)}
                  defaultDate={new Date()}
                  onSelectEvent={this.showInfoEvent}
                  dayLayoutAlgorithm={this.state.dayLayoutAlgorithm}
                  style={{ minHeight: 500 }}
                  eventPropGetter={(this.eventStyleGetter)}
                  components={{
                    event:
                      this.state.role === "student"
                        ? StudentEvent
                        : AdminTeacherEvent,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <CreateSession
          childRef={(ref) => (this.createFormRef = ref)}
          fetchEvent={this.fetchEvent}
          {...this.props}
        />
        <EventInfoModal
          eventInfo={this.state.eventInfo}
          childRef={(ref) => (this.eventInfomRef = ref)}
          {...this.props}
        />

        {this.state.role === "student" && (
          <StudentEventInfoModal
            eventInfo={this.state.eventInfo}
            childRef={(ref) => (this.eventStudentInfoRef = ref)}
            {...this.props}
          />
        )}
        {this.state.role === "teacher" && (
          <TeacherEventInfoModal
            eventInfo={this.state.eventInfo}
            childRef={(ref) => (this.eventTeacherInfoRef = ref)}
            {...this.props}
          />
        )}
        {/* {this.state.role === "superadmin" && (
          <StudentEventInfoModal
            eventInfo={this.state.eventInfo}
            childRef={(ref) => (this.eventStudentInfoRef = ref)}
            {...this.props}
          />
        )} */}
      </React.Fragment>
    );
  }
}

Calenders.propTypes = propTypes;

export default Calenders;
