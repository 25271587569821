import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import styles from "./HelpFromTutor.module.css";

import { Formik, Form } from "formik";
import { sessionValidation } from "../../../validations/StudentValidations";
import FormikControl from "../../../common/Formik/FormikControl";

import {
  curriculumListFun,
  subjectListFun,
  topicListFun,
  chapterListFun,
} from "../../../utils/utils";

const RaiseRequest = (props) => {
  const {
    curriculumList,
    subjectsList,
    topicsList,
    chaptersList,
    sessionData,
    messageError,
    backRequest,
    handleSubmitQuery,
    handleInput,
    handleInputMessage,
  } = props;

  const data = sessionData;

  let curriculumOptions = curriculumListFun(curriculumList);
  let subjectOptions = subjectListFun(subjectsList);
  let topicOptions = topicListFun(topicsList);
  let chapterOptions = chapterListFun(chaptersList);
   console.log(subjectOptions);
  return (
    <div className="row">
      <div>
        <h4 className="text-heading text-center mb-3">Request A Session</h4>
      </div>
      <div>
        <button
          className={`btn btn-sm mb-3 btn-right ${styles.backrequests}`}
          onClick={backRequest}
        >
          Back
        </button>
      </div>
      <div className="col-md-12">
        <div className="cardDiv mb-0">
          <div className={styles.raiseQueryDiv}>
            <Formik
              initialValues={data}
              validationSchema={sessionValidation}
              validateOnMount
              enableReinitialize
            >
              {(formik) => {
                const { handleChange } = formik;
                return (
                  <Form>
                    <div className="row">
                      <div className="col-md-6">
                        <FormikControl
                          control="select"
                          label="Curriculum Name"
                          optname="curriculum"
                          name="curriculum"
                          placeholder="Select Curriculum"
                          options={curriculumOptions}
                          value={data.curriculum}
                          onChange={(e) => {
                            handleChange(e);
                            handleInput(e, "curriculum", formik.values);
                          }}
                        />
                      </div>

                      <div className="col-md-6">
                        <FormikControl
                          control="select"
                          label="Subject Name"
                          optname="subject"
                          name="subject"
                          placeholder="Select Subject"
                          options={subjectOptions}
                          value={data.subject}
                          onChange={(e) => {
                            handleChange(e);
                            handleInput(e, "subject", formik.values);
                          }}
                        />
                      </div>

                      <div className="col-md-6">
                        <FormikControl
                          control="select"
                          label="Topic Name"
                          optname="topic"
                          name="topic"
                          placeholder="Select Topic"
                          options={topicOptions}
                          value={data.topic}
                          onChange={(e) => {
                            handleChange(e);
                            handleInput(e, "topic", formik.values);
                          }}
                        />
                      </div>

                      <div className="col-md-6">
                        <FormikControl
                          control="select"
                          label="Chapter Name"
                          optname="chapter"
                          name="chapter"
                          placeholder="Select Chapter"
                          options={chapterOptions}
                          value={data.chapter}
                          onChange={(e) => {
                            handleChange(e);
                            handleInput(e, "chapter", formik.values);
                          }}
                        />
                      </div>

                      <div className="col-md-12">
                        <CKEditor
                          editor={ClassicEditor}
                          data={data.message ? data.message : ""}
                          onChange={(event, editor) => {
                            handleInputMessage(editor.getData(), formik.values);
                          }}
                        />
                        {messageError && <div className="error">Required</div>}
                      </div>

                      <div className="text-center col-md-12">
                        <button
                          type="submit"
                          className={`btn btn-sm  mt-4 ${styles.submitRequest}`}
                          onClick={() => handleSubmitQuery(formik)}
                          // style={{ backgroundColor: "#4A1E75",color:"#fff" }}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RaiseRequest;
