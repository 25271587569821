import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { toCapitalWord } from "../../../utils/utils";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import style from "./staff.css"

import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import { Loader } from "react-feather";

const Staff = ({
  staffList,
  search,
  searchStaff,
  staffDelete,
  changeStatus,
}) => {
  const [roleSelect, setRoleSelect] = useState("all");
  const handleStaffSelection = (e) => {
    setRoleSelect(e.target.value);
  };
  console.log(staffList)
  const [id, setId] = useState();
  const [modal, setModal] = useState(false);
  const [isReadMore, setIsReadMore] = useState(true);
  const [loader,setLoader] = useState(false);
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };
  return (
    <div className="row">
      <div
        style={{
          display: "flex",
          width: "97%",
          textAlign: "center",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <h1 className="form-heading" style={{ color: "#4A1D75" }}>
          Staff List
        </h1>
        <div className="staffSelection" style={{ width: "100%" }}>
          <select
            className="form-control"
            onChange={handleStaffSelection}
            // style={{ width: "100%" }}
          >
            <option value="all">All</option>
            <option value="teacher">Teacher</option>
            <option value="admin">Admin</option>
          
          </select>
        </div>
      </div>

      {/* <div className="staffHewader"> */}
      {/* <div class="input-group row col-12   mb-2 "> */}
      {/* <div class="input-group-prepend"> */}
      {/* <div class="input-group-text">
            <img
              src="https://img.icons8.com/search"
              style={{ maxWidth: "30px" }}
            />
            <input
              type="text"
              value={search}
              onChange={(text) => searchStaff(text)}
              class="form-control"
              placeholder="Search Staff"
              style={{ borderRadius: "40px" }}
            />
          </div> */}
      {/* </div> */}
      {/* </div>
        <div style={{ display: "flex" }}>
          <div>
            <Link
              to={`/admin/add-staff-role`}
              className="btn btn-sm  add-btn ml-3"
              style={{
                borderRadius: "40px",
                backgroundColor: "#4A1D75",
                border: "2px solid #4A1D75",
                width: "150px",
                textAlign: "center",
                padding: "15px",
                marginRight: "10px",
              }}
            >
              Add Role
            </Link>
          </div>
          <div>
            <Link
              to={`/admin/add-staff`}
              className="btn btn-sm add-btn"
              style={{
                borderRadius: "40px",
                backgroundColor: "#4A1D75",
                border: "2px solid #4A1D75",
                width: "150px",
                textAlign: "center",
                padding: "15px",
              }}
            >
              Add Staff
            </Link>
          </div>
        </div>
      </div> */}
      <div className="staffHewader">
        <div class="search">
          <span class="fa fa-search"></span>
          <input
            // placeholder="Search Curriculum"
            style={{ borderRadius: "30px" }}
            type="text"
            value={search}
            onChange={(text) => searchStaff(text)}
            // class="form-control"
            placeholder="Search"
          />
        </div>
        <div style={{ display: "flex" }}>
          <div>
            <Link
              to={`/admin/add-staff-role`}
              className="btn btn-sm  add-btn ml-3"
              style={{
                borderRadius: "40px",
                backgroundColor: "#4A1D75",
                border: "2px solid #4A1D75",
                width: "140px",
                textAlign: "center",
                padding: "6px",
                color: "white",
                height: "auto",
                marginRight: "5px",
              }}
            >
              Add Role
            </Link>
          </div>
          <div>
            <Link
              to={`/admin/add-staff`}
              className="btn btn-sm add-btn"
              style={{
                borderRadius: "40px",
                backgroundColor: "#4A1D75",
                border: "2px solid #4A1D75",
                width: "140px",
                textAlign: "center",
                padding: "6px",
                color: "white",
                height: "auto",
              }}
            >
              Add Staff
            </Link>
          </div>
        </div>
      </div>

      <div className="col-md-12 table_container">
        {/* <div className="card"> */}
          <div className="card-body">
            <table className="table table">
              <thead>
                <tr>
                  <th style={{ color: "#20639B",width:'10%' }}>Staff Name</th>
                  <th style={{ color: "#20639B",width:'8%'  }}>Role</th>
                  <th style={{ color: "#20639B",width:'20%' }}>Email</th>
                  <th style={{ color: "#20639B",width:'15%'  }}>Curriculum</th>
                  <th style={{ color: "#20639B",width:'10%'  }}>Subject</th>

                  <th style={{ color: "#20639B",width:'10%'  }}>Mobile No.</th>
                  <th style={{ color: "#20639B",width:'4%' }}>Status</th>
                  <th style={{ color: "#20639B",width:'18%'  }}>Action</th>
                 
                </tr>
              </thead>
              <tbody className="w-100">
                {staffList.length > 0 &&
                  staffList.map((s) => {
                    if (s.role === roleSelect && s.role !== "student1") {
                      return (
                        <tr key={s._id}>
                          <td>
                            {s.firstName ? toCapitalWord(s.firstName) : ""}{" "}
                            {s.lastName ? toCapitalWord(s.lastName) : ""}
                          </td>
                          <td>{s.role ? toCapitalWord(s.role) : ""}</td>
                          <td>{s.email}</td>
                          {s.role === "teacher" && (
                            <React.Fragment>
                              <td>
                                {/* {isReadMore ? text.slice(0, 150) : text} */}
                                {/* <span
                                  onClick={toggleReadMore}
                                  className="read-or-hide"
                                >
                                  {isReadMore ? "...read more" : " show less"}
                                </span> */}
                                {s.assignCurrculum.map((res) => {
                                  return (
                                    <>
                                      <span>
                                        {isReadMore
                                          ? res.curriculum.slice(0, 10)
                                          : res.curriculum}
                                      </span>
                                    </>
                                  );
                                })}
                              </td>
                              <td>
                                {s.assignCurrculum.map((res) => {
                                  return (
                                    <>
                                      <span>
                                        {res.subject.split("-").join()}
                                      </span>
                                    </>
                                  );
                                })}
                              </td>
                            </React.Fragment>
                          )}

                          <td>{s.mobile}</td>
                          <td>
                            {/* <button className="btn btn-sm btn-info mt-1" onClick={() => staffDelete(s._id)}>
                              Delete
                            </button> */}
                            hello
                          </td>
                          <td>
                            <Link to={`/admin/edit-staff/${s.userID}`}>
                              <button
                                className="btn btn-sm mr-2"
                                style={{ backgroundColor: "#FFFFD3" }}
                              >
                                <span style={{ color: "#EAB731" }}>
                                  <BorderColorIcon
                                    style={{
                                      color: "#EAB731",
                                      fontSize: "13px",
                                    }}
                                  />
                                  <b style={{ fontSize: "11px" }}> Edit</b>
                                </span>
                              </button>
                            </Link>
                            <Link to={`/admin/edit-staff/${s.userID}`}>
                              <button
                                className="btn btn-sm mt-1"
                                onClick={() => {
                                  alert("fgff");
                                  staffDelete(s._id);
                                }}
                                style={{
                                  backgroundColor: "#DEF2E9",
                                  // width: "72px",
                                }}
                              >
                                <span
                                  style={{ color: "#49AF48", fontSize: "8px" }}
                                >
                                  <DeleteIcon
                                    style={{
                                      color: "#49AF48",
                                      fontSize: "10px",
                                    }}
                                  />
                                  {/* <b style={{ fontSize: "9px" }}> */}
                                  Delete
                                  {/* </b> */}
                                </span>
                              </button>
                            </Link>
                          </td>
                        </tr>
                      );
                    } else if (roleSelect === "all" && s.role !== "student") {
                      return (
                        <tr key={s._id}>
                          <td>
                            {s.firstName ? toCapitalWord(s.firstName) : ""}{" "}
                            {s.lastName ? toCapitalWord(s.lastName) : ""}
                          </td>
                          <td>{s.role ? toCapitalWord(s.role) : ""}</td>
                          <td>{s.email}</td>
                          {s.role !== "admin" ? (
                            <Fragment>
                              <td>
                                {s.assignCurrculum.map((res) => {
                                  return (
                                    <>
                                      <span>
                                        {`${res.curriculum}, `}
                                      </span>
                                    </>
                                  );
                                })}
                              </td>
                              <td>{s.assignCurrculum.map((res)=>{
                                return (
                                  <>
                                    <span>{`${res.subject},`}</span>
                                  </>
                                );
                              })}</td>
                            </Fragment>
                          ) : (
                            <Fragment>
                              <td>Admin</td>
                              <td>Admin</td>
                            </Fragment>
                          )}
                          <td>{s.mobile}</td>

                          <td>
                            {s.isActivate}
                            {s.isActivate ? (
                              <button
                                className="btn btn-sm mt-1"
                                onClick={() => changeStatus(s._id, false)}
                                style={{ backgroundColor: "#E0F6F6" }}
                              >
                                <span style={{ color: "#1DB9AA" }}>
                                  {" "}
                                  <b style={{ fontSize: "10px" }}> Active</b>
                                </span>
                              </button>
                            ) : (
                              <button
                                className="btn btn-sm btn-info mt-1"
                                onClick={() => changeStatus(s._id, true)}
                                style={{ backgroundColor: "#E0F6F6" }}
                              >
                                <span style={{ color: "#1DB9AA" }}>
                                  {" "}
                                  InActive
                                </span>
                              </button>
                            )}
                          </td>
                          <td>
                            <Link to={`/admin/edit-staff/${s.userID}`}>
                              <button
                                className="btn btn-sm me-2"
                                style={{
                                  backgroundColor: "#FFFFD3",
                                  marginRight: "2px",
                                }}
                              >
                                <span style={{ color: "#EAB731" }}>
                                  <BorderColorIcon
                                    style={{
                                      color: "#EAB731",
                                      fontSize: "11px",
                                    }}
                                  />
                                  <b style={{ fontSize: "9px" }}> Edit</b>
                                </span>
                                {/* {!loader ? text : <Loader className="spinner"/>} */}
                              </button>
                            </Link>

                            <Modal
                              size="md"
                              isOpen={modal}
                              toogle={() => {
                                setModal(!modal);
                              }}
                              style={{ marginTop: "18%" }}
                            >
                              <ModalHeader
                                toogle={() => {
                                  setModal(!modal);
                                }}
                              ></ModalHeader>
                              <ModalBody>
                                <div style={{ textAlign: "center" }}>
                                  <h3>Are you sure you want to Delete?</h3>
                                </div>
                                <div
                                  class="confirm"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <button
                                    className="btn btn-primary"
                                    onClick={() => {
                                      staffDelete(id);
                                      setModal(false);
                                    }}
                                    style={{ margin: "12px" }}
                                  >
                                    Yes
                                  </button>
                                  <button
                                    className="btn btn-danger"
                                    onClick={() => {
                                      setModal(false);
                                    }}
                                    style={{ margin: "12px" }}
                                  >
                                    No
                                  </button>
                                </div>
                              </ModalBody>
                            </Modal>
                            <button
                              className="btn btn-sm mt-1"
                              onClick={() => {
                                setModal(true);
                                setId(s._id);
                              }}
                              style={{
                                backgroundColor: "#DEF2E9",
                                // width: "72px",
                              }}
                            >
                              <span
                                style={{ color: "#49AF48", fontSize: "9px" }}
                              >
                                <DeleteIcon
                                  style={{
                                    color: "#49AF48",
                                    fontSize: "11px",
                                    marginRight: "2px",
                                    paddingBottom: "2px",
                                  }}
                                />
                                {/* <b style={{ fontSize: "9px" }}> */}
                                Delete
                                {/* </b> */}
                              </span>
                            </button>
                          </td>
                        </tr>
                      );
                    }
                  })}
              </tbody>
            </table>
          </div>
        {/* </div> */}
      </div>
      
    </div>
  );
};

export default Staff;
