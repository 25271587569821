import http from "../../services/httpService";
import { apiUrl } from "../../config.json";
import { getAuthToken } from "../../services/Authentication/AuthService";
const headers = {
  "x-access-token": getAuthToken(),
};

export const getAllTeachers = (payload) => {
  return http.post(apiUrl + "/user/teachers", payload, {
    headers: headers,
  });
};
export const inviteTeacher = (payload) => {
  return http.post(apiUrl + "/session/data/inviteTeacher", payload, {
    headers: headers,
  });
};

export const sessionInsert = (payload) => {
  return http.post(apiUrl + "/session/sessionInsert", payload, {
    headers: headers,
  });
};

export const sessionUpdate = (sessionUpdate) => {
  return http.post(apiUrl+ "/teams/sessionUpdate", sessionUpdate, {
    headers: headers,
  });
};

export const getAllSessions = (payload) => {
  return http.post(apiUrl + "/session/getAllSessions", payload, {
    headers: headers,
  });
};
