import CurriculumContainer from "./curriculumContainer";
import CourseContainer from "./courseContainer";
import SubjectContainer from "./subjectContainer";
import QuestionsContainer from "./questionsContainer";
import TemplateContainer from "./templatesContainer";
import AssessmentContainer from "./assessmentContainer";
import StaffContainer from "./staffContainer";
import VideoContainer from "./videoContainer";
import Calenders from "../../components/Schedule/Calender";
import InactiveUserContainer from './inactiveUserContainer';
import ProfileAdminContainer from "./profileAdminContainer";
import studentAdminContainer from "./studentAdminContainer";
import ImagesContainer from "./imagesContiner";
import AdminSessionContainer from "./adminSessionContainer";
import TestLinkContainer from "./testLinkContainer";
import OrgAdmin from "../../components/admin/Org-Admin/Org-Admin";
import HrModuleContainer from "./HrModuleContainer";
import AddExerciseMaterial from "../../components/admin/video/addExerciseMaterial";
import AdminHomeDash from "../../components/admin/adminHome/AdminHomeDash";

export default [
  {
    path: "admin-dashboard",
    component: AdminHomeDash,
  },
  {
    path: "add-curriculum",
    component: CurriculumContainer,
  },
  {
    path: "edit-curriculum/:id",
    component: CurriculumContainer,
  },
  {
    path: "HrModule",
    component: HrModuleContainer,
  },
  {
    path: "view-curriculum/:id",
    component: CurriculumContainer,
  },
  {
    path: "curriculum",
    component: CurriculumContainer,
  },
  {
    path: "add-course-content",
    component: CourseContainer,
  },
  {
    path: "edit-course-content/:id",
    component: CourseContainer,
  },
  {
    path: "view-course-content/:id",
    component: CourseContainer,
  },
  {
    path: "course-content",
    component: CourseContainer,
  },
  {
    path: "add-subject",
    component: SubjectContainer,
  },
  {
    path: "edit-subject/:id",
    component: SubjectContainer,
  },
  {
    path: "view-subject/:id",
    component: SubjectContainer,
  },
  {
    path: "subject",
    component: SubjectContainer,
  },
  {
    path: "add-question",
    component: QuestionsContainer,
  },
  {
    path: "edit-question/:id",
    component: QuestionsContainer,
  },
  {
    path: "view-question/:id",
    component: QuestionsContainer,
  },
  {
    path: "question",
    component: QuestionsContainer,
  },
  {
    path: "add-template",
    component: TemplateContainer,
  },
  {
    path: "edit-template/:id",
    component: TemplateContainer,
  },
  {
    path: "view-template/:id",
    component: TemplateContainer,
  },
  {
    path: "template",
    component: TemplateContainer,
  },
  {
    path: "add-assessment",
    component: AssessmentContainer,
  },
  {
    path: "edit-assessment/:id",
    component: AssessmentContainer,
  },
  {
    path: "view-assessment/:id/:cc",
    component: AssessmentContainer,
  },
  {
    path: "assessment",
    component: AssessmentContainer,
  },
  {
    path: "add-staff",
    component: StaffContainer,
  },
  {
    path: "edit-staff/:id",
    component: StaffContainer,
  },
  {
    path: "add-staff-role",
    component: StaffContainer,
  },
  {
    path: "staff",
    component: StaffContainer,
  },
  {
    path: "video",
    component: VideoContainer,
  },
  {
    path: "add-video",
    component: VideoContainer,
  },
  {
    path: "schedule",
    component: Calenders,
  },
  {
    path: "inactiveUser/:id",
    component: InactiveUserContainer,
  },
  {
    path: "add-read",
    component: VideoContainer,
  },
  {
    path: "profile",
    component: ProfileAdminContainer,
  },
  {
    path: "student",
    component: studentAdminContainer,
  },
  {
    path: "student-view/:id",
    component: studentAdminContainer,
  },
  {
    path: "student-edit/:id",
    component: studentAdminContainer,
  },
  {
    path: "org-admin",
    component: OrgAdmin,
  },
  {
    path: "images",
    component: ImagesContainer,
  },
  {
    path: "session-admin",
    component: AdminSessionContainer,
  },
  {
    path: "test-link",
    component: TestLinkContainer,
  },
  {
    path: "add-exercise",
    component: VideoContainer,
  },
];
