import {
  getCurriculumList,
  getSubjectsByCur,
  getTopicByCurSub,
  getAllChapters,
  getChapters
} from "../../services/Admin/commonService";

// Get the all curriculum list for that organization
export const getAllCurriculumList = () => {
  return getCurriculumList()
    .then((res) => {
      console.log(res);
      return res.data.data;
    })
    .catch((err) => {
      console.log("err", err);
      return [];
    });
};

// Get the all subjects list based on curriculum
export const getAllSubjectList = async (cc) => {
  const payload = {
    curriculumCode: cc,
  };
  return getSubjectsByCur(payload)
    .then((res) => {
      console.log(payload);
      return res.data.data;
    })
    .catch((err) => {
      console.log("err", err);
      return [];
    });
};

// Get the all topics list based on curriculum, subject
export const getAllTopicsList = async (cc, sub) => {
  const payload = {
    curriculumCode: cc,
    subject: sub,
  };
  return getTopicByCurSub(payload)
    .then((res) => {
      console.log(res);
      return res.data.data;
    })
    .catch((err) => {
      console.log("err", err);
      return [];
    });
};

// Get the all chapters list based on curriculum, subject, topic
export const getAllChaptersList = async (cc, sub, topic) => {
  const payload = {
    curriculumCode: cc,
    subject: sub,
    topic: topic,
  };
  console.log(payload);

  return getAllChapters(payload)
    .then((res) => {
      console.log(res);
      return res.data.data;
    })
    .catch((err) => {
      console.log("err", err);
      return [];
    });
};
export const getChaptersList = async (cc, sub, topic) => {
  const payload = {
    curriculumCode: cc,
    subject: sub,
    topic: topic,
  };
  console.log(payload);

  return getChapters(payload)
    .then((res) => {
      console.log(res);
      return res.data.data;
    })
    .catch((err) => {
      console.log("err", err);
      return [];
    });
};




