import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import styles from "./Sessions.module.css";
import { Button } from "@mui/material";
const SessionPreview = ({ sessionIndividual }) => {
  const data = sessionIndividual;
  const height = window.innerHeight - 100;
  function formatTimeShow(time) {
    const time_part_array = time.split(":");
    let ampm = 'AM';
    if (time_part_array[0] >= 12) {
      ampm = 'PM';
    }
    if (time_part_array[0] > 12) {
      time_part_array[0] = time_part_array[0] - 12;
    }
    const formatted_time = time_part_array[0] + ':' + time_part_array[1]  + ' ' + ampm;
    return formatted_time;
  }
  function datess(data) {
    let startindex = moment(data).format().indexOf('+')
    const date1 = new Date(moment(data).format().slice(0, startindex));
    const date2 = new Date();
    if (date2.getTime() >= date1.getTime()) {
      return true
    } else {
      return false
    }
  }
  return (
    <div className="row">
      <div className="col-md-12 text-right">
        <Link to={`/student/sessions`}>
          <button
            className={`btn btn-sm btn-secondary mr-0 ${styles.testBack}`}
          >
            Back
          </button>
        </Link>
      </div>
      <div className="col-md-6">
        <div
          className={`${styles.sessionPreviewDiv}`}
          style={{ height: height }}
        >
          <h2>Session Details</h2>
          <table className="table">
            <tr>
              <th>Curriculum</th>
              <td>{data.curriculum}</td>
            </tr>
            <tr>
              <th>Subject</th>
              <td>{data.subject}</td>
            </tr>
            {/* <tr>
              <th>Topic</th>
              <td>{data.topic}</td>
            </tr> 
             <tr>
              <th>Chapter</th>
              <td>{data.chapter}</td>
            </tr>  */}
            <tr>
              <th style={{ backgroundColor: "none" }}>Start Date &amp; Time</th>
              <td>
                {/* {moment
                  .parseZone(data.start)
                  .local()
                  .format("DD/MM/YYYY HH:mm")} */}
                {/* {console.log(data.start.split(" ")[1].trim())} */}
                {data.start ? data.start.split(" ")[0] + " " + formatTimeShow(data.start.split(" ")[1]) : ""}

              </td>
            </tr>
            <tr>
              <th>End Date &amp; Time</th>
              <td>
                {/* {moment.parseZone(data.end).local().format("DD/MM/YYYY HH:mm")} */}
                {data.end ? data.end.split(" ")[0] + " " + formatTimeShow(data.end.split(" ")[1]) : ""}

              </td>
            </tr>
          </table>
          {datess(data.start) ? (<>
            <Button variant="contained" onClick={() => {
              window.open(data.meetingLink);
            }}>JOIN</Button>
          </>) : (<>
            <Button variant="contained" disabled>Session is not start yet</Button>
          </>)}

        </div>
      </div>
    </div>
  );
};

export default SessionPreview;
