import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
const Profile = (props) => {
  console.log(props.list);
  const [visible, setVisible] = useState(false);
  const history = useHistory();
  return (
    <>
      <div>
        <h1
          className="form-heading d-flex justify-content-center"
          //   style={{ display: "flex", justifyContent: "center" }}
        >
          Profile
        </h1>
      </div>

      <div className="container" style={{ marginBottom: "10px" }}>
        {/* <div
          style={{
            display: "flex",
            marginLeft: "100px",
          }}
        >
          {props.role === "student" && (
            <button
              onClick={props.proptoCertificate}
              className="btn btn-primary btn-sm  mb-3 "
              style={{ marginLeft: "10px" }}
            >
              Get Certificate
            </button>
          )}
        
        </div> */}

        <div className="row justify-content-center">
          <div className="col-md-10">
            <div className="card">
              <div className="card-body">
                <div className="text-view">
                  <label>Name</label>
                  <span>
                    {props.firstName} {props.middleName} {props.lastName}
                  </span>
                </div>
                <div className="text-view">
                  <label>Mobile</label>
                  <span>{props.mobile}</span>
                </div>
                <div className="text-view">
                  <label>Email</label>
                  <span>{props.email}</span>
                </div>
                <div className="text-view">
                  <label>Address</label>
                  <span>{props.address}</span>
                </div>
                {props.role !== "admin" && (
                  <Fragment>
                    <div className="text-view">
                      <label>Curriculum</label>
                      <span>{props.curriculum}</span>
                    </div>
                    {/* <div className="text-view">
                                <label>Curriculum Code</label>
                                <span>
                                    {props.curriculumCode}
                                </span>
                            </div> */}
                    {props.role !== "teacher" && (
                      <Fragment>
                        {" "}
                        <div className="text-view">
                          <label>Gender</label>
                          <span>{props.gender}</span>
                        </div>
                        <div className="text-view">
                          <label>Date of birth</label>
                          <span>{props.dob && props.dob.split("T")[0]}</span>
                        </div>
                        <div className="text-view">
                          <label>Country</label>
                          <span>{props.country}</span>
                        </div>
                        <div className="text-view">
                          <label>State</label>
                          <span>{props.state}</span>
                        </div>
                        <div className="text-view">
                          <label>City</label>
                          <span>{props.city}</span>
                        </div>
                      </Fragment>
                    )}
                  </Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginLeft: "220px",
          paddingBottom: "100px",
        }}
      >
        <div style={{ width: "400px" }}>
          <button
            className="btn btn-success btn-sm  mb-3 "
            onClick={() => {
              setVisible(true);
            }}
          >
            Get Certificate
          </button>
          {visible ? (
            <select
              className="w-100 form-select"
              onChange={(e) => {
          
                history.push(`/student/certificate/${e.target.value}`);}}
            >
              <option>select certificate</option>
              {props.list.map((res) => (
                <option value={res.id}>{res.curriculum}</option>
              ))}
            </select>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default Profile;
