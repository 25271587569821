import http from "../httpService";
import { apiUrl } from "../../config.json";
import { getAuthToken } from "../Authentication/AuthService";

const headers = {
  "x-access-token": getAuthToken(),
};

export function saveSubject(payload, subjectId) {
  console.log("payload", payload);
  if (!subjectId) {
    return http.post(apiUrl + "/subject/addSubject", payload, {
      headers: headers,
    });
  } else {
    delete payload._id;
    return http.post(apiUrl + "/subject/updateSubject/" + subjectId, payload, {
      headers: headers,
    });
  }
}

export const getAllSubject = () => {
  return http.post(
    apiUrl + "/subject/getAllSubject",
    {},
    {
      headers: headers,
    }
  );
};

export const getSubject = (courseId) => {
  return http.post(apiUrl + "/subject/getsubject/" + courseId.subjectId, {
    headers: headers,
  });
};
export const deleteSubject = (payload) => {
  return http.post(apiUrl + "/subject/deleteSubject" , payload, {
    headers: headers,
  });
};
export const copySubject = (id) => {
  return http.post(apiUrl + "/subject/copysubject/" + id, {
    headers: headers,
  });
};
