import React from "react";
import parse from "html-react-parser";
import { deleteQuestion } from "../../../services/Admin/questionService";
const ViewQuestion = (props) => {
  const { questionsIndividual, closeViewQuestion } = props;
  const data = questionsIndividual;

  return (
    <div className="row justify-content-center">
      <div className="closePage">
        <h3 style={{ color: "#4A1D75" }}>View Question</h3>
        <button
          className="btn btn-sm btn-danger"
          onClick={() => {
            closeViewQuestion(data._id);
          }}
          style={{
            borderRadius: "40px",
            backgroundColor: "#4A1D75",
            border: "2px solid #4A1D75",
            width: "140px",
            textAlign: "center",
            padding: "6px",
            color: "white",
            height: "auto",
          }}
        >
          Delete
        </button>
        <button
          className="btn btn-sm btn-secondary mr-2"
          onClick={closeViewQuestion}
          style={{
            borderRadius: "40px",
            backgroundColor: "#4A1D75",
            border: "2px solid #4A1D75",
            width: "140px",
            textAlign: "center",
            padding: "6px",
            color: "white",
            height: "auto",
            marginRight: "5px",
          }}
        >
          Close
        </button>
      </div>
      <div className="col-md-12">
        <div className="card-deck">
          <div className="card">
            <div className="card-body">
              <div className="text-view">
                <label>Question ID</label>
                <span>{data.questionID ? data.questionID : ""}</span>
              </div>
              <div className="text-view">
                <label>Curriculam Name</label>
                <span>{data.curriculum ? data.curriculum : ""}</span>
              </div>
              <div className="text-view">
                <label>Subject Name</label>
                <span>{data.subject ? data.subject : ""}</span>
              </div>
              <div className="text-view">
                <label>Topic Name</label>
                <span>{data.topic ? data.topic : ""}</span>
              </div>
              <div className="text-view">
                <label>Chapter Name</label>
                <span>{data.chapter ? data.chapter : ""}</span>
              </div>
              <div className="text-view">
                <label>Question Type</label>
                <span>{data.questionType ? data.questionType : ""}</span>
              </div>
              <div className="text-view">
                <label>Marks for Questions</label>
                <span>{data.questionmarks ? data.questionmarks : ""}</span>
              </div>
            </div>
          </div>
        </div>
        <div className=" mt-3 ">
          <div className="question-list card mb-2">
            <div>
              <div>
                <p style={{ float: "left", marginRight: "10px" }}>Question:</p>{" "}
                {data.question ? parse(data.question) : ""}
              </div>
              {data.questionType === "Multi" && (
                <React.Fragment>
                  <p>
                    <i className="las la-check-circle"></i>{" "}
                    {data.correctanswer ? data.correctanswer : ""}
                  </p>
                  {data.image_correctanswer.location !== "" ? (<>

                    <img
                      src={data.image_correctanswer ? data.image_correctanswer.location : ""}
                      alt=""
                      style={{
                        width: "100%",
                        height: "100%",
                        maxWidth: "500px",
                        border: "1px solid #000",
                      }}
                    />
                  </>) : (<></>)}

                  <p>
                    <i className="las la-times-circle"></i>{" "}
                    {data.answer1 ? data.answer1 : ""}
                  </p>
                  {data.image_answer1.location !== "" ? (<>

                    <img
                      src={data.image_answer1 ? data.image_answer1.location : ""}
                      alt=""
                      style={{
                        width: "100%",
                        height: "100%",
                        maxWidth: "500px",
                        border: "1px solid #000",
                      }}
                    />
                  </>) : (<></>)}
                  <p>
                    <i className="las la-times-circle"></i>{" "}
                    {data.answer2 ? data.answer2 : ""}
                  </p>
                  {data.image_answer2.location!== "" ? (<>

                    <img
                      src={data.image_answer2 ? data.image_answer2.location : ""}
                      alt=""
                      style={{
                        width: "100%",
                        height: "100%",
                        maxWidth: "500px",
                        border: "1px solid #000",
                      }}
                    />
                  </>) : (<></>)}

                  <p>
                    <i className="las la-times-circle"></i>{" "}
                    {data.answer3 ? data.answer3 : ""}
                  </p>
                  {data.image_answer3.location !== "" ? (<>

                    <img
                      src={data.image_answer3 ? data.image_answer3.location : ""}
                      alt=""
                      style={{
                        width: "100%",
                        height: "100%",
                        maxWidth: "500px",
                        border: "1px solid #000",
                      }}
                    />
                  </>) : (<></>)}
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewQuestion;
