import React from "react";
import { Formik, Form } from "formik";

import { staffRoleValues } from "../../../constants/AdminInitialValues";
import { staffRoleValidation } from "../../../validations/AdminValidations";
import FormikControl from "../../../common/Formik/FormikControl";

const AddStaffRole = (props) => {
  const { handleSubmitRole } = props;
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <h1 className="form-heading" style={{ color: "#4A1D75" }}>
            Add Staff Role
          </h1>
          <div className="card">
            <div className="card-body">
              <Formik
                initialValues={staffRoleValues}
                validationSchema={staffRoleValidation}
                validateOnMount
                enableReinitialize
              >
                {(formik) => {
                  return (
                    <Form>
                      <div className="row">
                        <div className="col-md-12">
                          <FormikControl
                            control="input"
                            type="text"
                            label="Role Name"
                            id="roleName"
                            name="roleName"
                          />
                        </div>

                        <div className="text-center col-md-12">
                          <button
                            type="submit"
                            className="btn btn-sm  mt-4"
                            onClick={() => handleSubmitRole(formik)}
                            style={{
                              borderRadius: "40px",
                              backgroundColor: "#4A1D75",
                              border: "2px solid #4A1D75",
                              width: "140px",
                              textAlign: "center",
                              padding: "6px",
                              color: "white",
                              height: "auto",
                            }}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddStaffRole;
