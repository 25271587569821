import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import styles from "./Assignments.module.css";
import Countdown from "react-countdown";
import { Button, Modal } from "react-bootstrap";
import { userID } from "../../../services/Authentication/AuthService";
import { CircularProgress } from "@material-ui/core";
const AssignmentPreview = ({
  assignmentIndividual,
  getTime,
  closeModal,
  meetingLink,
  getMeetingLink,
  studentTest,
}) => {
  const data = assignmentIndividual;

  const height = window.innerHeight - 155;
  const [showMss, setShowMss] = useState(true);
  const [show, setShow] = useState(false);
  const [showTest, setShowTest] = useState(false);
  const [testButton, setTestButton] = useState(true);
  const [loading, setloading] = useState(true);
  const [timer, setTimer] = useState(null);
  useEffect(async () => {
    timerr();
    console.log(show);
    if (data.testID) {
      let checkTest = await studentTest(data.testID, userID());
      if (checkTest) {
        setShow(checkTest.test ? false : true);
        setShowTest(checkTest ? checkTest.test : false);
        setTestButton(checkTest ? checkTest.test : false);
        setloading(false);
      }
    }
  }, [data]);
  if (!data) {
    return false;
  }

  const Completionist = () => {
    setShowMss(false);
    return <span>Start the Assessment now!</span>;
  };

  const timerr = () => {
    let event, now, remaning, h, m;
    try {
      if (data.startdate) {
        h = data.startTime.split(":")[0];
        m = data.startTime.split(":")[1];

        now = new Date().getTime();
        let ii = moment(data.startdate).format("MM/DD/YY");
        ii = ii + " " + h + ":" + m;

        let da = new Date(ii).getTime();
        remaning = da - now;
        if (remaning > 0) {
          let datee = Date.now() + remaning;
          setTimer(datee);
        } else {
          setShowMss(false);
        }
      }
    } catch (err) {
      console.log("error");
      // console.log(err)
    }
  };
  const getTestTime = () => {
    let now = getTime();
    let h, m, event;

    if (data.startdate) event = new Date();
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    let da = new Date(event.toISOString());
    // let enGB = (da.toLocaleDateString('en-GB', options))

    // let time = moment(data.startdate).format("DD/MM/YYYY hh:mm")
    h = data.startdate.split("T")[1].split(":")[0];
    m = data.startdate.split("T")[1].split(":")[1];

    if (h < now.hours && m < now.min) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      {!loading ? (
        <>
          <div className="row">
            <div className="col-md-12">
              <div
                className={`${styles.assignmentPreviewDiv}`}
                style={{ display: "flex", justifyContent: "center" }}
              >
                {/* <div className={` col-md-11 text-right`}>
            <Link to={`/student/assignments`}>
              <button
                className={`btn btn-sm btn-secondary theam-color mr-0 ${styles.testBack}`}
              >
                Back
              </button>
            </Link>
          </div> */}
                <div
                  className="col-md-6 pad-0"
                  style={{ border: "1px solid black" }}
                >
                  <div style={{ height: height, marginTop: "40px" }}>
                    <h2 className="mt-3">Assessment Details</h2>
                    <table
                      class="table"
                      style={{ marginLeft: "10px", marginTop: "60px" }}
                    >
                      <tr style={{ padding: "0 0 5px 0" }}>
                        <th style={{ padding: "0 0 5px 0" }}>
                          Assessment Name
                        </th>
                        <td>
                          {data.assessmentName ? data.assessmentName : ""}
                        </td>
                      </tr>
                      <tr>
                        <th style={{ padding: "0 0 5px 0" }}>
                          Curriculum Name
                        </th>
                        <td>{data.curriculum}</td>
                      </tr>
                      <tr>
                        <th style={{ padding: "0 0 5px 0" }}>Subject Name</th>
                        <td>{data.subject ? data.subject : ""}</td>
                      </tr>
                      <tr>
                        <th style={{ padding: "0 0 5px 0" }}>
                          Start Date &nbsp;<small>(DD/MM/YYYY)</small>
                        </th>
                        <td>{moment(data.startdate).format("DD/MM/YYYY")}</td>
                      </tr>
                      <tr>
                        <th style={{ padding: "0 0 5px 0" }}>
                          End Date &nbsp;<small>(DD/MM/YYYY)</small>
                        </th>
                        <td>{moment(data.enddate).format("DD/MM/YYYY")}</td>
                      </tr>
                    </table>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        // border: "2px solid red",
                        width: "94%",
                      }}
                    >
                      <div
                        className="col-md-10 "
                        style={{ marginLeft: "10px" }}
                      >
                        {testButton ? (
                          <></>
                        ) : (
                          <>
                            <Link
                              onClick={() => {
                                // window.open(`/#/student/test-view/${data.testID}`, "_blank")

                                window.open(
                                  `/#/student/test-view/${data.testID}`,
                                  "_blank"
                                );
                              }}
                            >
                              <button
                                disabled={showMss}
                                className={`btn btn-sm btn-success `}
                              >
                                Start Assessment
                              </button>
                            </Link>
                          </>
                        )}
                      </div>
                      <div
                        className={` col-md-5 `}
                        style={{ }}
                      >
                        <Link to={`/student/assignments`}>
                          <button
                            className={`btn btn-sm btn-secondary theam-color mr-0 `}
                            style={{ marginRight: "20px" }}
                          >
                            Back
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                {/* 
          <div className="col-md-10 text-center">
            <Link
              onClick={() => {
               

                window.open(`/#/student/test-view/${data.testID}`, "_blank");
              }}
            >
              <button
                disabled={showMss}
                className={`btn btn-sm btn-success ${styles.testStart}`}
              >
                Start Assessmentest
              </button>
            </Link>
          </div> */}
              </div>
            </div>
            <Modal show={show}>
              <Modal.Header>
                <Modal.Title>Instructions</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ol>
                  <li>Make sure you have good internet connection</li>
                  <li>Make sure to join Teams meet and share your screen</li>
                  <li>Do not minimize the browser during Assessment</li>
                  <li>
                    Time remaning-{" "}
                    {timer && (
                      <Countdown date={timer}>
                        <Completionist />
                      </Countdown>
                    )}
                  </li>
                </ol>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={closeModal}>
                  Go Back
                </Button>

                <Button
                  variant="primary"
                  onClick={() =>
                    getMeetingLink().then((res) => {
                      setShowMss(false);
                      setShow(false);
                      // setloading(true)
                    })
                  }
                >
                  Join teams Meeting
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={showTest}>
              <Modal.Header>
                <Modal.Title>ALERT</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ul>
                  <li>
                    You have already completed the Assessment. Please try again
                    later
                  </li>
                  <li>
                    Once you submit the Assessment, it will be considered as
                    completed.
                  </li>
                </ul>
                <div style={{ marginLeft: "20px" }}>
                  <label style={{ fontWeight: "bold", fontSize: "17px" }}>
                    Important:-
                  </label>
                  <p>
                    The Assessment can only be taken once. Attempting to retake
                    the Assessment <br />
                    is not allowed.
                  </p>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={() => setShowTest(false)}>
                  OK
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "200px",
            }}
          >
            <CircularProgress />
          </div>
        </>
      )}
    </>
  );
};

export default AssignmentPreview;
