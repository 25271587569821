import React, { Component } from "react";
import Dashboard from "../../components/Teachers/Dashboard/Dashboard";
import { userID, userCurriculumCode, userEmail, userCurriculum, teacherSubject,assignCurrculum } from "../../services/Authentication/AuthService";
import { teacherDash } from "../../services/Teacher/QueriesService";
class DashboardContainer extends Component {
  state = {
    dashData: {}
  };
  setDashData() {
    console.log(assignCurrculum())
    let payload = {
      assignCurrculum: assignCurrculum(),
      email: userEmail(),
      userID: userID()
    }
    teacherDash(payload).then(res=>{
      this.setState({dashData:res.data.data})
      console.log(res.data.data)
    }).catch(error=>console.log(error))
  }
  componentDidMount() {
    this.setDashData();

  }
  render() {

    return (
      <React.Fragment>
        <Dashboard dashData={this.state.dashData}/>
      </React.Fragment>
    );
  }
}

export default DashboardContainer;
