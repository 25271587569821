import React from "react";
import styles from "./Assignments.module.css";
import cur from "../../../assets/Banners/group3.png";
import IconFormalAssignments from "../../../assets/Icons/IconFormalAssignments.png";
import style from "./AssignmentType.module.css"
const AssignmentType = ({ isType, assignmentType }) => {
  return (
    <React.Fragment>
      {/* no use div */}
      <div className="row">
        <div className={`col-md-12 ${styles.dashimgstyle}  mb-2 `}></div>
      </div>

      <div className="col-md-12 row mb-35    " id="buttonItem">
        <div
          className={`tabItem ${isType === "formal" ? "tabItemActive" : ""}`}
          onClick={() => assignmentType("informal")}
          style={{ borderRadius: "28px" }}
        >
          FORMAL ASSESSMENT
        </div>
        <div
          className={`tabItem ${isType === "informal" ? "tabItemActive" : ""}`}
          onClick={() => assignmentType("formal")}
          style={{ borderRadius: "28px" }}
        >
          INFORMAL ASSESSMENT
        </div>
      </div>
    </React.Fragment>
  );
};

export default AssignmentType;
