import React from "react";
import parse from "html-react-parser";
import Select from "../common/select";
import Input from "../common/input";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  curriculumListFun,
  subjectListFun,
  topicListFun,
  chapterListFun,
} from "../../../utils/utils";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";

// console.log(curriculumOptions);

/* {console.log(curriculumOptions)} */

const AddTemplate = (props) => {
  const {
    mode,
    templateData,
    curriculumList,
    subjectsList,
    topicsList,
    chaptersList,
    handleInput,
    templatesListAll,
    handleGetQuestions,
    questionsList,
    optioncheckbox,
    handleSubmit,
    submitFalse,
  } = props;
  useEffect(() => {
    setcurriCullumdata(curriculumList);
    console.log(templatesListAll);
  });

  const data = templateData;
  console.log(data);
  const [curricullumdata, setcurriCullumdata] = useState([]);
  const [cur, setCue] = useState("");
  const [editcur, seteditCur] = useState("");
  const [addSubject, setAddSubject] = useState("");
  // const [template, setTemplate] = useState("");
  const [editSubject, setEditSubject] = useState("");
  const [template, setTemplate] = useState("");
  const [editTemplate, SetEditTemplate] = useState("");
  const [topic, setTopic] = useState("");
  const [editTopic, setEditTopic] = useState("");
  const [addChapter, setAddchapter] = useState("");
  const [editChapter, setEditChapter] = useState("");

  let curriculumOptions = curriculumListFun(curriculumList);
  let subjectOptions = subjectListFun(subjectsList);
  let topicOptions = topicListFun(topicsList);
  let chapterOptions = chapterListFun(chaptersList);

  console.log(chapterOptions);
  console.log(editcur);
  console.log(subjectsList);
  const templateTypeOptions = [
    { _id: "1", name: "Public" },
    { _id: "2", name: "Private" },
  ];
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-10">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "10px",
              width: "98%",
              alignItems: "center",
            }}
          >
            <h1 className="form-heading" style={{ color: "#4A1D75" }}>
              {mode} Template
            </h1>
            <div
            // className="add-btn-div"
            >
              <Link
                to={`/admin/template`}
                className="btn btn-sm "
                style={{
                  borderRadius: "40px",
                  backgroundColor: "#4A1D75",
                  border: "2px solid #4A1D75",
                  width: "140px",
                  textAlign: "center",
                  padding: "6px",
                  color: "white",
                  height: "auto",
                }}
              >
                Cancel
              </Link>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <Input
                    type="text"
                    name="templateName"
                    label="Template Name"
                    value={data.templateName ? data.templateName : ""}
                    onChange={handleInput}
                  />
                </div>

                <div className="col-md-6">
                  <div className="relative">
                    <Select
                      name="templateType"
                      label="Template Type"
                      value={data.templateType ? data.templateType : ""}
                      options={templateTypeOptions}
                      onChange={handleInput}
                    />
                    <KeyboardArrowDownIcon className="drop_icon" />
                  </div>
                  {/* <label className="label-heading" htmlFor="curriculum">
                    Template Type
                  </label>
                  <select
                    name="templateType"
                    label="Template Type"
                    className="w-100 form-select"
                    value={data.templateType ? data.templateType : ""}
                    options={templateTypeOptions}
                    onChange={handleInput}
                  >
                    <option
                      defaultValue="select"
                      defaultChecked
                      selected="selected"
                      value={"select"}
                    >
                      select
                    </option>
                    {templateData?.map((data1, index) => (
                      <option key={index} value={data1.templateType}>
                        {" "}
                        {data1.templateType}{" "}
                      </option>
                    ))}
                  </select> */}
                </div>

                <div className="col-md-6">
                  <div className="relative">
                    <Select
                      name="curriculum"
                      optname="curriculum"
                      label="Curriulum Name"
                      value={data.curriculum ? data.curriculum : ""}
                      options={curriculumOptions}
                      onChange={(e) => {
                        setCue(e.target.value);
                        console.log(e.target.value);
                        handleInput(e, "curriculum");
                      }}
                    />
                    <KeyboardArrowDownIcon className="drop_icon" />
                  </div>
                  {/* <label className="label-heading" htmlFor="curriculum">
                    curriculums Name
                  </label> */}
                  {/* {mode === "Add" && (
                    <select
                      className="w-100 form-select"
                      name="curriculum"
                      optname="curriculum"
                      label="Curriulum Name"
                      defaultValue={data.curriculum ? data.curriculum : ""}
                      value={cur}
                      options={curriculumOptions}
                      onChange={(e) => {
                        setCue(e.target.value);
                        console.log(e.target.value);
                        handleInput(e, "curriculum");
                      }}
                    >
                      <option
                        defaultValue="select"
                        defaultChecked
                        selected="selected"
                        value={"select"}
                      >
                        select
                      </option>
                      {curriculumList?.map((data1, index) => (
                        <option key={index} value={data1.curriculum}>
                          {" "}
                          {data1.curriculum}{" "}
                        </option>
                      ))}
                    </select>
                  )}
                  {mode === "Edit" && (
                    <select
                      className="w-100 form-select"
                      name="curriculum"
                      optname="curriculum"
                      label="Curriulum Name"
                      defaultValue={data.curriculum ? data.curriculum : ""}
                      value={cur}
                      options={curriculumOptions}
                      onChange={(e) => {
                        setCue(e.target.value);
                        handleInput(e, "curriculum");
                      }}
                    >
                      <option
                        defaultValue="select"
                        defaultChecked
                        selected="selected"
                        value={"select"}
                      >
                        select
                      </option>
                      {curriculumList?.map((data1, index) => (
                        <option key={index} value={data1.curriculum}>
                          {" "}
                          {data1.curriculum}{" "}
                        </option>
                      ))}
                    </select>
                  )} */}
                </div>

                <div className="col-md-6">
                  {/* <label className="label-heading" htmlFor="curriculum">
                    Subject Name
                  </label> */}
                  <div className="relative">
                    <Select
                      name="subject"
                      optname="subject"
                      label="Subject Name"
                      defaultValue={data.subject ? data.subject : ""}
                      options={subjectOptions}
                      onChange={(e) => {
                          setAddSubject(e.target.value);
                        handleInput(e, "subject");
                      }}
                    />

                    {/* {mode === "Add" && (
                    <select
                      name="subject"
                      className="w-100 form-select"
                      optname="subject"
                      label="Subject Name"
                      // value={data.subject ? data.subject : ""}
                      value={addSubject}
                      options={subjectOptions}
                      onChange={(e) => {
                        setAddSubject(e.target.value);
                        handleInput(e, "subject");
                      }}
                    >
                      <option
                        defaultValue="select"
                        defaultChecked
                        selected="selected"
                        value={"select"}
                      >
                        select
                      </option>
                      {subjectsList?.map((data2, index) => (
                        <option key={index} value={data2.subject}>
                          {" "}
                          {data2.subject}{" "}
                        </option>
                      ))}
                    </select>
                  )} */}
                    {/* {mode === "Edit" && (
                    <select
                      name="subject"
                      className="w-100 form-select"
                      optname="subject"
                      label="Subject Name"
                      // value={data.subject ? data.subject : ""}
                      value={addSubject}
                      options={subjectOptions}
                      onChange={(e) => {
                        setAddSubject(e.target.value);
                        handleInput(e, "subject");
                      }}
                    >
                      <option
                        defaultValue="select"
                        defaultChecked
                        selected="selected"
                        value={"select"}
                      >
                        select
                      </option>
                      {subjectsList?.map((data2, index) => (
                        <option key={index} value={data2.subject}>
                          {" "}
                          {data2.subject}{" "}
                        </option>
                      ))}
                    </select>
                  )} */}
                    <KeyboardArrowDownIcon className="drop_icon" />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="relative">
                    {/* <Select
                    name="topic"
                    optname="topic"
                    label="Topic Name"
                    value={data.topic ? data.topic : ""}
                    options={topicOptions}
                    onChange={(e) => handleInput(e, "topic")}
                  /> */}
                    {/* <label className="label-heading" htmlFor="curriculum">
                    Topic Name
                  </label> */}
                    {/* {mode === "Add" && (
                    <select
                      name="topic"
                      className="w-100 form-select"
                      optname="topic"
                      label="Topic Name"
                      // value={data.topic ? data.topic : ""}
                      value={topic}
                      options={topicOptions}
                      onChange={(e) => {
                        setTopic(e.target.value);
                        handleInput(e, "topic");
                      }}
                    >
                      <option
                        defaultValue="select"
                        defaultChecked
                        selected="selected"
                        value={"select"}
                      >
                        select
                      </option>
                      {topicsList?.map((data3, index) => (
                        <option key={index} value={data3.topic}>
                          {data3.topic}
                        </option>
                      ))}
                    </select>
                  )} */}
                    {/* {mode === "Edit" && (
                    <select
                      name="topic"
                      className="w-100 form-select"
                      optname="topic"
                      label="Topic Name"
                      // value={data.topic ? data.topic : ""}
                      value={topic}
                      options={topicOptions}
                      onChange={(e) => {
                        setTopic(e.target.value);
                        handleInput(e, "topic");
                      }}
                    >
                      <option
                        defaultValue="select"
                        defaultChecked
                        selected="selected"
                        value={"select"}
                      >
                        select
                      </option>
                      {topicsList?.map((data3, index) => (
                        <option key={index} value={data3.topic}>
                          {data3.topic}
                        </option>
                      ))}
                    </select>
                  )} */}
                    <Select
                      name="topic"
                      optname="topic"
                      label="Topic Name"
                      value={data.topic ? data.topic : ""}
                      options={topicOptions}
                      onChange={(e) => {
                        setTopic(e.target.value);
                        handleInput(e, "topic");
                      }}
                    />
                    <KeyboardArrowDownIcon className="drop_icon" />
                  </div>
                </div>
                <br />
                <div className="col-md-6">
                  <div className="relative">
                    {/* <label className="label-heading" htmlFor="curriculum">
                    Chapter Name
                  </label> */}
                    <Select
                      name="chapter"
                      optname="chapter"
                      label="Chapter Name"
                      value={data.chapter ? data.chapter : ""}
                      options={chapterOptions}
                      onChange={(e) => handleInput(e, "chapter")}
                    />
                    <KeyboardArrowDownIcon className="drop_icon" />
                  </div>
                  {/* {mode === "Add" && (
                    <select
                      name="chapter"
                      className="w-100 form-select"
                      optname="chapter"
                      label="Chapter Name"
                      // value={data.chapter ? data.chapter : ""}
                      value={addChapter}
                      options={chapterOptions}
                      onClick={(e) => {
                        console.log(chapterOptions);
                      }}
                      onChange={(e) => {
                        setAddchapter(e.target.value);
                        handleInput(e, "chapter");
                      }}
                    >
                      <option
                        defaultValue="select"
                        defaultChecked
                        selected="selected"
                        value={"select"}
                      >
                        select
                      </option>
                      {chapterOptions?.map((data3, index) => (
                        <option key={index} value={data3.chapter}>
                          {data3.chapter}
                        </option>
                      ))}
                    </select>
                  )} */}
                  {/* {mode === "Edit" && (
                    <select
                      name="chapter"
                      className="w-100 form-select"
                      optname="chapter"
                      label="Chapter Name"
                      // value={data.chapter ? data.chapter : ""}
                      value={editChapter}
                      options={chapterOptions}
                      onChange={(e) => {
                        setEditChapter(e.target.value);

                        handleInput(e, "chapter");
                      }}
                    >
                      <option
                        defaultValue="select"
                        defaultChecked
                        selected="selected"
                        value={"select"}
                      >
                        select
                      </option>
                      {chapterOptions?.map((data4, index) => (
                        <option key={index} value={data4.chapter}>
                          {data4.chapter}
                        </option>
                      ))}
                    </select>
                  )} */}
                </div>

                {data.curriculum && data.subject && (
                  <div className="col-md-12 text-right">
                    <button
                      type="submit"
                      onClick={handleGetQuestions}
                      className="btn btn-sm "
                      style={{
                        borderRadius: "40px",
                        backgroundColor: "#4A1D75",
                        border: "2px solid #4A1D75",
                        color: "white",
                        width: "150px",
                        textAlign: "center",
                        padding: "7.5px 0px",
                        marginTop: "20px",
                        marginBottom: "10px",
                      }}
                    >
                      List
                    </button>
                  </div>
                )}

                <div className="col-md-12 mt-4">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th></th>
                        <th style={{ color: "#4A1D75" }}>ID</th>
                        <th style={{ color: "#4A1D75" }}>Question</th>
                        <th style={{ color: "#4A1D75" }}>Marks</th>
                      </tr>
                    </thead>
                    <tbody>
                      {questionsList &&
                        questionsList.map((ql) => {
                          return (
                            <tr key={ql._id}>
                              <td
                                style={{ width: "50px", textAlign: "center" }}
                              >
                                <input
                                  type="checkbox"
                                  name="optioncheckbox"
                                  value
                                  onChange={(e) =>
                                    optioncheckbox(e, ql.questionID)
                                  }
                                />
                              </td>
                              <td style={{ width: "105px", fontSize: "13px" }}>
                                {ql.questionID}
                              </td>
                              <td>
                                <span>{parse(ql.question)}</span>
                              </td>
                              <td>
                                {ql.questionmarks ? ql.questionmarks : ""}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn btn-sm  mt-4"
                      onClick={handleSubmit}
                      disabled={submitFalse}
                      style={{
                        borderRadius: "40px",
                        backgroundColor: "#4A1D75",
                        border: "2px solid #4A1D75",
                        width: "140px",
                        textAlign: "center",
                        padding: "6px",
                        color: "white",
                        height: "auto",
                      }}
                    >
                      Submit
                    </button>
                  </div>
                  {/* )} */}
                </div>
                {/* ) : (
                ""
              )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTemplate;
