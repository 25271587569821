import React from "react";
import { NavLink } from "react-router-dom";

import Logo from "../../../assets/Images/logo.png";
import styles from "./Assignments.module.css";
import moment from "moment";

const TestResult = ({ testResult ,assignmentIndividual,setVideo}) => {
  let assessment = assignmentIndividual
  const data = testResult.length !==0 ? testResult[0] : assignmentIndividual ;
console.log(data) 
  let grade;
  let acheivedPercentage = 0;
  let testScore = data.achievedMarks ? data.achievedMarks : "";
  acheivedPercentage = (testScore * 100) / data.totalMarks;

  if (acheivedPercentage >= 75) {
    grade = "A";
  } else if (acheivedPercentage >= 50 && acheivedPercentage < 75) {
    grade = "B";
  } else if (acheivedPercentage >= 35 && acheivedPercentage < 50) {
    grade = "C";
  } else if (acheivedPercentage < 35) {
    grade = "D";
  }

  return (
    <React.Fragment>
      <div className={`${styles.testInfo}`}>
        <div className="container">
          <div className="row">
            <div className="col-md-4 text-left">
              <NavLink to="/student/dashboard">
                <img src={Logo} alt="logo" style={{ height: "60px" }} />
              </NavLink>
            </div>
            <div className="col-md-8">
              <button className={`${styles.backLink}`} onClick={()=>{setVideo()}}>
                <NavLink to="/student/dashboard">Back To Dashboard</NavLink>
              </button>
            </div>
          </div>
        </div>
      </div>
      <React.Fragment>
        <div className="container mt-4">
          <h4 className="text-center">Result Summary</h4>
          <div className="test_result_bg">
            <div className="row mt-5">
              <div className="col-md-6">
                <table className="table table-bordered">
                  <tbody>
                    <tr>
                      <th>Assessment Name</th>
                      <td>{assessment.assessmentName}</td>
                    </tr>
                    <tr>
                      <th>Curriculum</th>
                      <td>{assessment.curriculum}</td>
                    </tr>
                    <tr>
                      <th>Subject</th>
                      <td>{assessment.subject}</td>
                    </tr>
                    <tr>
                      <th>
                        Start Date <small>(DD/MM/YYYY)</small>
                      </th>
                      <td>{moment(assessment.startdate).format("DD/MM/YYYY")}</td>
                    </tr>
                    <tr>
                      <th>
                        End Date <small>(DD/MM/YYYY)</small>
                      </th>
                      <td>{moment(assessment.enddate).format("DD/MM/YYYY")} </td>
                    </tr>
                    {data.correctionMode === "automatic" && (
                      <React.Fragment>
                        <tr>
                          <th>Achieved Marks</th>
                          <td>{data.achievedMarks}</td>
                        </tr>
                        <tr>
                          <th>Total Marks</th>
                          <td>{data.totalMarks}</td>
                        </tr>
                      </React.Fragment>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="col-md-6">
                {data.correctionMode === "automatic" ? (
                  <React.Fragment>
                    <ul className={`${styles.testResultLeft}`}>
                      <li>
                        <div className={`${styles.resultDt}`}>
                          <span className={`${styles.rightAns}`}>
                            {data.correctAnsweredQues
                              ? data.correctAnsweredQues
                              : 0}
                          </span>
                          <p>Right</p>
                        </div>
                      </li>
                      <li>
                        <div className={`${styles.resultDt}`}>
                          <span className={`${styles.wrongAns}`}>
                            {data.wrongAnsweredQues
                              ? data.wrongAnsweredQues
                              : 0}
                          </span>
                          <p>Wrong</p>
                        </div>
                      </li>
                      <li>
                        <div className={`${styles.resultDt}`}>
                          <span className={`${styles.totQues}`}>
                            {data.totalQuestions ? data.totalQuestions : ""}
                          </span>
                          <p>Total Questions</p>
                        </div>
                      </li>
                    </ul>
                    <div style={{ textAlign: "center", fontSize: "30px" }}>
                      Grade : <b>{grade}</b>
                    </div>
                  </React.Fragment>
                ) : (
                  <h3>
                    Teacher needs to correct. Marks will be calculated after
                    teacher correction
                  </h3>
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
      )
    </React.Fragment>
  );
};

export default TestResult;
