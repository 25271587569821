import React, { useState, useEffect } from "react";
import FlatList from 'flatlist-react';
import { Link } from "react-router-dom";
import RecordVideo from "./recordVideo";

const TestLinkComponent = ({ meet }) => {
    const [modalShow, setModalShow] = useState(false)
    const [videoDetail, setVideoDetail] = useState({
        location: ""
    })
    const handleClose = () => {
        setModalShow(false)
    }

    return <div class="container">
        <div className="col-md-12">
            <h1 className="form-heading">Assessment  Links</h1>


        </div>
        <FlatList
            list={meet}
            renderItem={(item) => {
                console.log('itme', item);
                return (
                    <div class="card p-4">
                        <h5 class="card-title mb-0">
                            Meeting Title <br />
                            <hr />
                        </h5>
                        <div class="card-body row ">
                            <div class="col-md-4 ">
                                <p class="adminSession_head__2uutR">
                                    <i class="las la-info pr-2"></i>
                                    {item.curriculumCode}
                                </p>
                                <span class="adminSession_head__2uutR overflow-hidden" >
                                    <i class="las la-link pr-2" ></i>
                                    {item.email}
                                </span>
                            </div>
                            <div class="col-md-3">
                                <p class="adminSession_head__2uutR text-start ">
                                    <i class="las la-file-alt pr-2"></i>
                                    {item.testId}
                                </p>
                                <p class="adminSession_head__2uutR text-start ">
                                    <i class="las la-user-astronaut pr-2"></i>
                                    {item.studentId}
                                </p>
                            </div>
                            <Link
                                onClick={() => {
                                    setModalShow(true)
                                }}
                            >
                                <div class="col-md-3 text-center">
                                    <button class="btn btn-primary" style={{ "fontSize": '10px' }} >Save recording</button>
                                </div>
                            </Link>
                            <Link
                                onClick={() => {
                                    window.open(item.joiUrl, "_blank")
                                }}
                            >
                                <div class="col-md-2 text-center">
                                    <button class="btn btn-success" style={{ "fontSize": '10px' }} >Join Meeting</button>
                                </div>
                            </Link>
                        </div>
                        <hr />
                        <RecordVideo chapterSingle={videoDetail} modalShow={modalShow} handleClose={handleClose} />
                    </div>
                )
            }}
        />

    </div >
}
export default TestLinkComponent