import http from "../httpService";
import { apiUrl } from "../../config.json";
import { getAuthToken } from "../Authentication/AuthService";

const headers = {
  "x-access-token": getAuthToken(),
};
export function saveTemplate(payload, templateId) {
  if (!templateId) {
    return http.post(apiUrl + "/template/addTemplate", payload, {
      headers: headers,
    });
  } else {
    return http.post(apiUrl + "/template/updateTemp/"+templateId, payload, {
      headers: headers,
    });
  }
}

export const getTemplateQuestions = (payload) => {
  return http.post(apiUrl + "/template/getTemp/" + payload.templateID, {
    headers: headers,
  });
};

export const getTemplateList = () => {
  return http.post(apiUrl + "/template/getAllTemplate", {
    headers: headers,
  });
};
export const deleteTemplate = (payload) => {
  return http.post(apiUrl + "/template/delete", payload, {
    headers: headers,
  });
};

export const copyTemplate = (id) => {
  return http.post(apiUrl + "/copyTemplates/" + id, {
    headers: headers,
  });
};
