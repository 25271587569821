import React, { useState, Fragment } from "react";



const ImageList = (props) => {
    const [image, setImage] = useState("/icons/Placeholder.png");
    const { logoImg, submitupload, loading } = props
    // console.log(logoImg)

    console.log(setImage);

    return (
      <Fragment>
        <div className="container">
          <div className="row justify-content-center">
            <div className="card col-md-7" style={{ width: "100%" }}>
              <div className="card-body d-flex flex-column align-items-center">
                <h5
                  className={`${styles.logotext}`}
                  style={{ color: "#4A1D75" }}
                >
                  Logo
                </h5>

                {logoImg && <img src={logoImg} />}
                {/* {!logoImg && <div style={{ height: '500px', width: '500px', backgroundColor: '#C9CCD5', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            No image
                        </div>} */}
                <img
                  src={image}
                  alt="image"
                  style={{
                    width: "100%",
                    height: "100%",
                    maxWidth: "500px",
                    border: "1px solid #000",
                  }}
                />
                {/* <input type="file" id="BtnBrowseHidden" style={{ display: 'none' }} /> */}
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) =>
                    setImage(URL.createObjectURL(e.target.files[0]))
                  }
                />
                {!loading && (
                  <button
                    className={`btn btn-sm add-btn mt-3 ${styles.imageButton}`}
                    onClick={submitupload}
                    style={{ backgroundColor: "#E0F6F6 ", color: "#1DB9AA" }}
                  >
                    Upload
                  </button>
                )}
                {loading && (
                  <button
                    className="btn btn-sm  add-btn mt-3"
                    onClick={submitupload}
                    style={{ backgroundColor: "#E0F6F6 ", color: "#1DB9AA" }}
                  >
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span className="sr-only">Loading...</span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
}
const styles = {

}


export default ImageList