
import './ResetPassword.css'
function Message() {

  return (
    <>
   <div className='main_container p-5 border border-danger'>
        <div className='display-6 msg'>
        Your password has been reset. Kindly Login with your new password now.
        </div>
        <div className='back_to_login mt-5'>
            Back to Login   
        </div>
   </div>
    </>
  )
}

export default Message;