import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import styles from "./Assignments.module.css";
import Countdown from 'react-countdown';
import { CircularProgress } from "@material-ui/core";

const AssignmentResult = ({ assignmentIndividual, getTime, showResult }) => {

  const data = assignmentIndividual;
  useEffect(() => {

  }, [])
  useEffect(() => {
    timerr()

  }, [data])
  const height = window.innerHeight - 155;
  const [showMss, setShowMss] = useState(true)
  const [timer, setTimer] = useState(null)
  const [loading, setloading] = useState(true);
  if (!data) {
    return false;
  }

  const Completionist = () => {
    setShowMss(false)
    return <span>Start the Assessment now!</span>
  };

  const timerr = () => {
    let event, now, remaning, h, m
    try {
      if (data.startdate) {
        // console.log('ccccccccc22');

        h = data.startdate.split('T')[1].split(':')[0]
        m = data.startdate.split('T')[1].split(':')[1]
        now = new Date().getTime()
        let ii = moment(data.enddate).format("MM/DD/YY")
        ii = ii + ' ' + h + ':' + m
        // console.log('ddd', ii);
        let da = new Date(ii).getTime()
        remaning = da - now
        if (remaning > 0) {
          let datee = Date.now() + remaning
          setTimer(datee)
           setloading(false);
        }

      }
    } catch (err) {
      console.log('error');
      // console.log(err)
    }

  }
  const getTestTime = () => {
    let now = getTime()
    let h, m, event

    if (data.startdate)
      event = new Date()
    const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    let da = new Date(event.toISOString())
    // let enGB = (da.toLocaleDateString('en-GB', options))
    console.log(da.getTime());
    // let time = moment(data.startdate).format("DD/MM/YYYY hh:mm")
    h = data.startdate.split('T')[1].split(':')[0]
    m = data.startdate.split('T')[1].split(':')[1]
    // console.log('hhm', data.startdate);
    if (h < now.hours && m < now.min) {
      return true

    } else {
      return false
    }
  }

  return (
    <>
      {!loading ? (
        <div className="row">
          <div className="col-md-12">
            <div
              className={`${styles.assignmentPreviewDiv}`}
              style={{ display: "flex", justifyContent: "center" }}
            >
              {/* <div className={` col-md-11 `} style={{display:"flex",justifyContent:"end"}}>
                        <Link to={`/student/assignments`}>
                            <button
                                className={`btn btn-sm btn-secondary theam-color mr-0 ${styles.testBack1}`}
                            >
                                Back
                            </button>
                        </Link>
                    </div> */}
              <div className="col-md-6 " style={{ border: "1px solid black" }}>
                <div style={{ height: height }}>
                  <h2 className="mt-3">Assessment Details</h2>
                  <table class="table" style={{ marginLeft: "10px" }}>
                    <tr>
                      <th>Assessment Name</th>
                      <td>{data.assessmentName ? data.assessmentName : ""}</td>
                    </tr>
                    <tr>
                      <th>Curriculum Name</th>
                      <td>{data.curriculum}</td>
                    </tr>
                    <tr>
                      <th>Subject Name</th>
                      <td>{data.subject ? data.subject : ""}</td>
                    </tr>
                    <tr>
                      <th>
                        Start Date &nbsp;<small>(DD/MM/YYYY)</small>
                      </th>
                      <td>{moment(data.startdate).format("DD/MM/YYYY")}</td>
                    </tr>

                    <tr>
                      <th>Total Marks</th>
                      <td>
                        {showResult.status
                          ? showResult.totalMarks
                          : data.maxWeightage}
                      </td>
                    </tr>
                    {showResult.status === "Accept" && (
                      <>
                        {" "}
                        <tr>
                          <th>AchievedMarks Marks</th>
                          <td>{showResult.achievedMarks}</td>
                        </tr>
                        <tr>
                          <th>Correct Answered</th>
                          <td>{showResult.correctAnsweredQues}</td>
                        </tr>
                        <tr>
                          <th>Wrong Answered</th>
                          <td>{showResult.wrongAnsweredQues}</td>
                        </tr>
                      </>
                    )}
                    {showResult.status === "Reject" && (
                      <>
                        <p style={{ padding: "10px" }}>
                          Your Assessment Was Rejected by teacher
                        </p>
                      </>
                    )}
                    {showResult.status === undefined && (
                      <>
                        <p style={{ padding: "10px" }}>
                          Teacher needs to correct. Marks will be calculated
                          after teacher correction
                        </p>
                      </>
                    )}
                  </table>
                  <div
                    className={` col-md-11 `}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Link to={`/student/assignments`}>
                      <button
                        className={`btn btn-sm btn-secondary theam-color mr-0 ${styles.testBack1}`}
                      >
                        Back
                      </button>
                    </Link>
                  </div>
                </div>
              </div>

              {/* <div className="col-md-12 text-center">
                        {timer && <Countdown date={timer}>
                            <Completionist />
                        </Countdown>}
                        <Link
                            onClick={() => {
                                // window.open(`/#/student/test-view/${data.testID}`, "_blank")

                                window.open(`/#/student/test-view/${data.testID}`, "_blank")



                            }

                            }
                        >

                            <button disabled={showMss} className={`btn btn-sm btn-success ${styles.testStart}`}>
                                Start Assessment
                            </button>
                        </Link>
                    </div> */}
            </div>
          </div>
        </div>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "200px",
            }}
          >
            <CircularProgress />
          </div>
        </>
      )}
    </>
  );
};

export default AssignmentResult;
