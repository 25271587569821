import React from "react";
import { Link } from "react-router-dom";
import { copySubject } from "../../../services/Admin/subjectService";

import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import style from "./subject.css"
const Subject = (props) => {
  const { subjectList, search, searchSubject } = props;
  return (
    <div className="row">
      <h1 className="form-heading" style={{ color: "#4A1D75" }}>
        Subject List
      </h1>
      {/* <div className="subjectHeader"> */}
      {/* <div class="input-group row col-12   mb-2 "> */}
      {/* <div class="input-group-prepend"> */}
      {/* <div class="input-group-text" style={{ borderRadius: "40px" }}>
            <img
              src="https://img.icons8.com/search"
              style={{ maxWidth: "30px" }}
            />
            <input
              type="text"
              value={search}
              onChange={(text) => searchSubject(text)}
              class="form-control"
              placeholder="Search Curriculum/Chapter "
              style={{ borderRadius: "40px" }}
            />
          </div> */}
      {/* </div> */}
      {/* </div> */}
      {/* <div className="add-btn-div">
          <Link
            to={`/admin/add-subject`}
            className="btn btn-sm  add-btn"
            style={{
              borderRadius: "40px",
              backgroundColor: "#4A1D75",
              border: "2px solid #4A1D75",
              width: "150px",
              textAlign: "center",
              padding: "15px",
            }}
          >
            Add Subject
          </Link>
        </div> */}
      {/* </div> */}
      <div className="subjectHeader">
        <div class="search">
          <span class="fa fa-search"></span>
          <input
            placeholder="Search  "
            style={{ borderRadius: "30px" }}
            type="text"
            value={search}
            onChange={(text) => searchSubject(text)}
          />
        </div>
        <div className="add-btn-div">
          <Link
            to={`/admin/add-subject`}
            className="btn btn-sm  add-btn"
            style={{
              borderRadius: "40px",
              backgroundColor: "#4A1D75",
              border: "2px solid #4A1D75",
              width: "140px",
              textAlign: "center",
              padding: "6px",
              color: "white",
              height: "auto",
            }}
          >
            Add Subject
          </Link>
        </div>
      </div>
      <div className="col-md-12 table_container">
        {/* <div className="card"> */}
          <div className="card-body">
            <table className="table table">
              <thead>
                <tr>
                  <th style={{ color: "#20639B" }}>Curriculum Name</th>
                  <th style={{ color: "#20639B" }}>Subject Name</th>
                  <th style={{ color: "#20639B" }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {subjectList &&
                  subjectList.map((c) => {
                    return (
                      <tr key={c._id}>
                        <td>{c.curriculum}</td>
                        <td>{c.subject}</td>
                        <td className="w-25">
                          <Link to={`/admin/edit-subject/${c._id}`}>
                            <button
                              className="btn btn-sm mr-2"
                              style={{ backgroundColor: " #FFE7A8" }}
                            >
                              <span style={{ color: "#EAB731" }}>
                                <BorderColorIcon
                                  style={{ color: "#EAB731", fontSize: "13px" }}
                                />
                                <b style={{ fontSize: "11px" }}> Edit</b>
                              </span>
                            </button>
                          </Link>
                          <Link to={`/admin/view-subject/${c._id}`}>
                            <button
                              className="btn btn-sm ml-2"
                              style={{
                                backgroundColor: "#DEF2E9",
                                marginLeft: "10px",
                              }}
                            >
                              <span style={{ color: "#49AF48" }}>
                                <RemoveRedEyeIcon
                                  style={{ color: "#49AF48", fontSize: "13px" }}
                                />
                                <b style={{ fontSize: "11px" }}>View</b>
                              </span>
                            </button>
                          </Link>
                          {c.subject[c.subject.length - 1] === ")" ? null : (
                            <button
                              onClick={() => {
                                async function test() {
                                  await copySubject(c._id);
                                  window.location.reload();
                                }
                                test();
                              }}
                              className="btn btn-sm mr-2 "
                              style={{
                                backgroundColor: " #E0F6F6",
                                // marginTop: "10px",
                                marginLeft: "10px",
                              }}
                            >
                              <span style={{ color: "#1DB9AA" }}>
                                <CopyAllIcon
                                  style={{ color: "#1DB9AA", fontSize: "13px" }}
                                />
                                <b style={{ fontSize: "11px" }}> Copy</b>
                              </span>
                            </button>
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    // </div>
  );
};

export default Subject;
