import React from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { toCapitalWord } from "../../../utils/utils";
import { copyQuestion } from "../../../services/Admin/questionService";
import style from "./question.css"
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import CopyAllIcon from "@mui/icons-material/CopyAll";

const Curriculum = ({ questionsList, search, searchQuestion}) => {
  return (
    <div className="row">
      <h1 className="form-heading" style={{ color: "#4A1D75" }}>
        Question Library
      </h1>
      {/* <div className="questionHeader"> */}
      {/* <div class="input-group row col-12   mb-2 "> */}
      {/* <div class="input-group-prepend"> */}
      {/* <div class="input-group-text" style={{ borderRadius: "40px" }}>
            <img
              src="https://img.icons8.com/search"
              style={{ maxWidth: "30px" }}
            />
            <input
              type="text"
              value={search}
              onChange={(text) => searchQuestion(text)}
              class="form-control"
              placeholder="Search Curriculum/Subject/Question"
              style={{ borderRadius: "40px" }}
            />
          </div> */}
      {/* <div > */}

      {/* </div> */}
      {/* </div> */}
      {/* </div>
        <div>
          <Link
            to={`/admin/add-question`}
            className="btn btn-sm  add-btn"
            style={{
              borderRadius: "40px",
              backgroundColor: "#4A1D75",
              border: "2px solid #4A1D75",
              width: "150px",
              textAlign: "center",
              padding: "15px",
            }}
          >
            Add Question
          </Link>
        </div>
      </div> */}
      <div className="questionHeader">
        <div class="search">
          <span class="fa fa-search"></span>
          <input
            style={{ borderRadius: "30px" }}
            type="text"
            value={search}
            onChange={(text) => searchQuestion(text)}
            placeholder="Search "
          />
        </div>
        <div>
          <Link
            to={`/admin/add-question`}
            className="btn btn-sm  add-btn"
            style={{
              borderRadius: "40px",
              backgroundColor: "#4A1D75",
              border: "2px solid #4A1D75",
              width: "140px",
              textAlign: "center",
              padding: "6px",
              color: "white",
              height: "auto",
            }}
          >
            Add Question
          </Link>
        </div>
      </div>
      <div className="col-md-12 table_container">
        {/* <div className="card"> */}
        <div className="card-body">
          <table className="table table-">
            <thead>
              <tr>
                <th scope="col" style={{ width: "10%" }}>
                  <span> Question ID</span>
                </th>
                <th scope="col" style={{ width: "10%" }}>
                  <span> Question</span>
                </th>
                <th scope="col" style={{ width: "10%" }}>
                  <span> Question Type</span>
                </th>
                <th scope="col" style={{ width: "10%" }}>
                  <span> Curriculum</span>
                </th>
                <th scope="col" style={{ color: "#20639B", width: "10%" }}>
                  <span>Subject</span>
                </th>
                <th scope="col" style={{ color: "#20639B", width: "10%" }}>
                  <span> Marks</span>
                </th>
                <th scope="col" style={{ color: "#20639B", width: "10%" }}>
                  <span>Actions</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {questionsList &&
                questionsList.length > 0 &&
                questionsList.map((q) => {
                  return (
                    <tr key={q._id}>
                      <td>{q.questionID}</td>
                      <td> {q.question ? parse(q.question) : ""} </td>
                      <td>
                        {q.questionType ? toCapitalWord(q.questionType) : ""}
                      </td>
                      <td>{q.curriculum}</td>
                      <td>{q.subject}</td>
                      <td> {q.questionmarks} </td>
                      <td className="w-25">
                        <Link to={`/admin/edit-question/${q.questionID}`}>
                          <button
                            className="btn btn-sm mr-2"
                            style={{ backgroundColor: " #FFE7A8" }}
                          >
                            <span style={{ color: "#EAB731" }}>
                              <BorderColorIcon
                                style={{ color: "#EAB731", fontSize: "13px" }}
                              />
                              <b style={{ fontSize: "11px" }}> Edit</b>
                            </span>
                          </button>
                        </Link>
                        <Link to={`/admin/view-question/${q.questionID}`}>
                          <button
                            className="btn btn-sm ml-2"
                            style={{
                              backgroundColor: "#DEF2E9",
                              marginLeft: "10px",
                            }}
                          >
                            <span style={{ color: "#49AF48" }}>
                              <RemoveRedEyeIcon
                                style={{ color: "#49AF48", fontSize: "13px" }}
                              />
                              <b style={{ fontSize: "11px" }}>View</b>
                            </span>
                          </button>
                        </Link>
                        {q.question[q.question.length - 1] === ")" ? null : (
                          <button
                            onClick={() => {
                              async function test() {
                                await copyQuestion(q._id);
                                window.location.reload();
                              }
                              test();
                            }}
                            className="btn btn-sm "
                            style={{
                              backgroundColor: " #E0F6F6",
                              // marginTop: "10px",
                              marginLeft: "10px",
                            }}
                          >
                            <span style={{ color: "#1DB9AA" }}>
                              <CopyAllIcon
                                style={{ color: "#1DB9AA", fontSize: "13px" }}
                              />
                              <b style={{ fontSize: "11px" }}> Copy</b>
                            </span>
                          </button>
                        )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    // </div>
  );
};

export default Curriculum;
