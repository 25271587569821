import React, { useState, useEffect, Fragment } from "react";
import Profile from "../../components/Share/Profile"
import { getUserInfo, } from "../../services/Admin/commonService";
import { userID } from "../../services/Authentication/AuthService";



const ProfileAdminContainer = props => {
    const [userInfo, setUserInfo] = useState('')
    useEffect(() => {
        getUser()
    }, [])

    const getUser = () => {
        getUserInfo(+userID()).then(res => {
            if (res.status === 200) {
                // if (res.data.status) {

                    setUserInfo(res.data.data)
                // }
            } else {
                setUserInfo('')
            }
        }).catch(err => { console.log('err') })
    }

    return <Fragment>
        {userInfo && <Profile role={userInfo.role}
            firstName={userInfo.firstName}
            middleName={userInfo.middleName}
            lastName={userInfo.lastName}
            mobile={userInfo.mobile}
            email={userInfo.email}
            gender={userInfo.gender}
            address={userInfo.address}

             />
            }
    </Fragment>
}


export default ProfileAdminContainer