import { Box, Button, useMediaQuery } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useParams } from 'react-router-dom';
import { useHistory } from "react-router-dom";

import { forgetPassword } from "../../services/Authentication/AuthService";
import { Link } from "react-router-dom";
import e from "cors";




function ResetPassword() {
  const params = useParams();
  console.log(params);

  const isMobile = useMediaQuery('(max-width:950px )')
  console.log(isMobile, "isMobile")
 
  let history = useHistory();



  // toggle between password visible or not for 1st input
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [error1,setError1] = useState(true)
  const [error2,setError2] = useState(true)

  const changePassword = async ()=>{
  if(password === passwordC){
     await forgetPassword({ email: params.email, password: password }).then((e) => { console.log(e) });
     alert(
       "Your password has been reset. Kindly Login with your new password"
     );
     setError2(true)
      history.push("/");

  }else{
    setError2(false)
  }
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordChange = (event) => {
    var rgx = /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).{15,30}$/;
    if(event.target.value.search(rgx) === 0){
      setError1(true)
      setError2(true)
    }
    setPassword(event.target.value);
  };
  //

  // toggle between password visible or not for 2nd input
  const [showPasswordC, setShowPasswordC] = useState(false);
  const [passwordC, setPasswordC] = useState('');

  const togglePasswordVisibilityC = () => {
    setShowPasswordC(!showPasswordC);
  };

  const passCheck = ()=>{
    var rgx = /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).{15,30}$/;
    console.log(password.search(rgx))
    if(password.search(rgx) === -1){
      setError1(false)
    }else{
      setError1(true)
      // setPassword(e.target.value)
    }
  }

  const ConfirmPassCheck = ()=>{
    if(password !== passwordC){
     setError2(false)
    }else{
      setError2(true)
      // setPasswordC(e.target.value)
    }
  }

  const handlePasswordChangeC = (event) => {
    if(password !== event.target.value){
      setError2(false)
     }else{
       setError2(true)
       // setPasswordC(e.target.value)
     }
    setPasswordC(event.target.value);
  };
 


  return (
    // main div
    <>
      <Box
        // sx={{ paddingLeft: "38vw", paddingRight: "38vw", paddingTop: "22vh" }}
        sx={{
          paddingLeft: `${isMobile ? "8vw" : "38vw"}`,
          paddingRight: `${isMobile ? "8vw" : "38vw"}`,
          paddingTop: `${isMobile ? "8vw" : "22vh"}`,
        }}
      >
        <Box>
          <Box
            sx={{
              fontSize: "30px",
              fontFamily: "IvyOra Text",
              lineHeight: "52px",
              fontWeight: "700",
              color: "#202648",
              textAlign: "center",
            }}
          >
            Reset Password
          </Box>
        </Box>
        <Box>
          <Box
            sx={{
              fontSize: "20px",
              fontFamily: "IvyOra Text",
              lineHeight: "31px",
              fontWeight: "500",
              color: "#202648",
              textAlign: "center",
            }}
          >
            Enter a new password
          </Box>
        </Box>

        <Box className="d-flex justify-content-start" sx={{ paddingTop: "8%" }}>
          <input
            className="form-control  "
            type={showPassword ? "text" : "password"}
            value={password}
            onBlur={passCheck}
            onChange={handlePasswordChange}
            placeholder="Password"
          />

          <i
            className={`fas ${showPassword ? "fa-eye" : "fa-eye-slash"}`}
            onClick={togglePasswordVisibility}
            style={{ marginLeft: "-10%", marginTop: "3%", cursor: "pointer" }}
          />

          {/* <input className="" type="password" name="password" autocomplete="current-password" required="" id="id_password"/>
        <i class="far fa-eye" id="togglePassword" style={{marginLeft:"-30px" , cursor: "pointer",}}></i> */}
        </Box>
        {!error1  ? <small className="form-text text-danger">Minimum length of password should be 15 characters<br/>Password must contain:<br/>At least one UPPERCASE,<br/>one number,one special character(!,@,#,$)</small>:''}
        <Box className="d-flex justify-content-start" sx={{ paddingTop: "5%" }}>
          <input
            className="form-control  "
            type={showPasswordC ? "text" : "password"}
            value={passwordC}
            onBlur={ConfirmPassCheck}
            onChange={handlePasswordChangeC}
            placeholder=" Confirm New Password"
          />

          <i
            className={`fas ${showPasswordC ? "fa-eye" : "fa-eye-slash"}`}
            onClick={togglePasswordVisibilityC}
            style={{ marginLeft: "-10%", marginTop: "3%", cursor: "pointer" }}
          />
        </Box>
        {!error2 ? <small className="form-text text-danger">Password And Confirm Password Must Be same</small>:''}
        {/* <Box sx={{paddingTop:"5%"}}>
          <input className="form-control" />
        </Box> */}

        <Box sx={{ paddingTop: "5%" }}>
          <Button
            variant="contained"
            sx={{
              fontFamily: "Roboto",
              fontWeight: "400",
              fontSize: "18px",
              color: "#FDFDFD",
              textTransform: "capitalize",
              background: "#202648",
              bordeRadius: "10px",
              width: "100%",
            }}
            onClick={() => changePassword()}
          >
            Change Password
          </Button>
        </Box>
        <Box>
          <Link to="/" style={{textDecoration:'none'}}>
            <Box
              sx={{
                fontFamily: "Roboto",
                fontSize: "18px",
                fontWeight: "400",
                lineHeight: "23px",
                color: "#2A2381",
                textAlign: "center",
                paddingTop: "16%",
              }}
            >
              Back to Login
            </Box>
          </Link>
        </Box>
      </Box>
    </>
  );
}

export default ResetPassword;
