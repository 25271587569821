
import { useHistory } from "react-router-dom";
import moment from "moment";
import parse from "html-react-parser";
import { deleteAssessment } from "../../../services/Admin/assessmentService";
const ViewAssignment = ({ assignmentInvidual, questionssss }) => {
  const data = assignmentInvidual ? assignmentInvidual : [];
  
  const dataAll = questionssss ? questionssss : null;
  let newArr = [];
  console.log(assignmentInvidual, "aues");

  if (dataAll === null) {
  } else {
    dataAll.map((item) => {
      console.log(item)
      newArr.push(item);
    });
    console.log(newArr, "sdsd");
  }

  // let datttaaa = [];
  // if(dataAll.testq) {
  //   datttaaa.push(dataAll.testq[0])
  // }
  // console.log(datttaaa);

  // console.log(dataAll.testq);
  const history = useHistory();
  console.log(data)
  return (
    <div className="row justify-content-center">
      <div className="closePage">
        <h3 style={{ color: "#4A1D75" }}>View Assessment</h3>
        <button
          className="btn btn-sm "
          onClick={() => {
            deleteAssessment({ testID: data.testID });
            history.goBack();
          }}
          style={{
            borderRadius: "40px",
            backgroundColor: "#4A1D75",
            border: "2px solid #4A1D75",
            width: "140px",
            textAlign: "center",
            padding: "6px",
            color: "white",
            height: "auto",
            // marginRight: "5px",
          }}
        >
          Delete
        </button>
        <button
          className="btn btn-sm mr-2"
          onClick={() => history.goBack()}
          style={{
            borderRadius: "40px",
            backgroundColor: "#4A1D75",
            border: "2px solid #4A1D75",
            width: "140px",
            textAlign: "center",
            padding: "6px",
            color: "white",
            height: "auto",
            marginRight: "5px",
          }}
        >
          Close
        </button>
      </div>
      <div className="col-md-12">
        <div className="card-deck">
          <div className="card">
            <div className="card-body">
              <div className="text-view">
                <label>Assessment Name</label>
                <span> {data && data.assessmentName} </span>
              </div>
              <div className="text-view">
                <label>Curriculum Name</label>
                <span>{data && data.curriculum}</span>
              </div>
              <div className="text-view">
                <label>Subject</label>
                <span>{data && data.subject}</span>
              </div>
              <div className="text-view">
                <label>
                  Start Date <small>(DD-MM-YYYY)</small>
                </label>
                <span>
                  {data && moment(data.startdate).format("DD-MM-YYYY")}
                </span>
              </div>
              <div className="text-view">
                <label>Start Time</label>
                <span>
                  {data && moment(data.startTime,"hh:mm").format("h:mm:ss a")}
                </span>
              </div>
              <div className="text-view">
                <label>
                  End Date <small>(DD-MM-YYYY)</small>
                </label>
                <span>{data && moment(data.enddate).format("DD-MM-YYYY")}</span>
              </div>
              <div className="text-view">
                <label>End Time</label>
                <span>{data && moment(data.endTime,"hh:mm").format("h:mm:ss a")}</span>
              </div>
              {/* <div className="text-view">
                <label>Lab Session</label>
                <span>{data && data.labSession === true ? "Yes" : "No"}</span>
              </div> */}

              <div className="text-view">
                <label>Max Questions</label>
                <span>{data && data.maxQuestions}</span>
              </div>

              <div className="text-view">
                <label>Max Weightage</label>
                <span>{data && data.maxWeightage}</span>
              </div>

              <div className="text-view">
                <label>Correction Mode</label>
                <span>{data && data.correctionMode}</span>
              </div>

              <div className="text-view">
                <label>Questions</label>
                <div>
                  {console.log(newArr.length, typeof newArr[0])}
                  {newArr.length > 0 &&
                    newArr[0] != undefined &&
                    newArr.map((data, key) => {
                      {
                        var ques = parse(data.question);
                        // ques = ques.split("?")[0];
                        // ques = ques.split("<p>").join("");
                      }
                      return <p className="mb-3">{ques}</p>;
                    })}
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewAssignment;
