import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import style from "./assessment.css"

import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

const Assessment = ({ assessmentList, search, searchAssessment }) => {
  return (
    <div className="row">
      <h1 className="form-heading" style={{ color: "#4A1D75" }}>
        Assessment List
      </h1>
      {/* <div className="assessmentHeader"> */}
      {/* <div class="input-group row col-12   mb-2 "> */}
      {/* <div class="input-group-prepend"> */}
      {/* <div class="input-group-text">
            <img
              src="https://img.icons8.com/search"
              style={{ maxWidth: "30px" }}
            />
            <input
              type="text"
              value={search}
              onChange={(text) => searchAssessment(text)}
              class="form-control"
              placeholder="Search Assessment Name	"
              style={{ borderRadius: "40px" }}
            />
          </div> */}
      {/* </div> */}
      {/* </div> */}
      {/* <div
              >
          <Link
            to={`/admin/add-assessment`}
            className="btn btn-sm add-btn"
            style={{
              borderRadius: "40px",
              backgroundColor: "#4A1D75",
              border: "2px solid #4A1D75",
              width: "150px",
              textAlign: "center",
              padding: "15px",
            }}
          >
            Add Assessment
          </Link>
        </div>
      </div> */}
      <div className="assessmentHeader">
        <div class="search">
          <span class="fa fa-search"></span>
          <input
            style={{ borderRadius: "30px" }}
            type="text"
            value={search}
            onChange={(text) => searchAssessment(text)}
            placeholder="Search	"
          />
        </div>
        <div>
          <Link
            to={`/admin/add-assessment`}
            className="btn btn-sm add-btn"
            style={{
              borderRadius: "40px",
              backgroundColor: "#4A1D75",
              border: "2px solid #4A1D75",
              width: "140px",
              textAlign: "center",
              padding: "6px",
              color: "white",
              height: "auto",
            }}
          >
            Add Assessment
          </Link>
        </div>
      </div>
      <div className="col-md-12 table_container">
        {/* <div className="card"> */}
        <div className="card-body">
          <table className="table table">
            <thead>
              <tr>
                <th style={{  }}>ID</th>
                <th style={{ }}>Assessment Name</th>
                <th style={{ }}>Type</th>
                <th style={{ }}>Curriculum</th>
                <th style={{  }}>Start Date</th>
                <th style={{ }}>End Date</th>
                <th style={{ }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {assessmentList &&
                assessmentList.length > 0 &&
                assessmentList.map((a) => {
                  return (
                    <tr key={a._id}>
                      <td>{a.testID}</td>
                      <td>{a.assessmentName}</td>
                      <td>{a.type}</td>
                      <td>{a.curriculum}</td>
                      <td>{moment(a.startdate).format("MM/DD/YYYY")}</td>
                      <td>{moment(a.enddate).format("MM/DD/YYYY")} </td>
                      <td>
                        <Link
                          to={`/admin/view-assessment/${a.testID}/${a.curriculumCode}`}
                        >
                          <button
                            className="btn btn-sm btn-info"
                            style={{
                              backgroundColor: "#DEF2E9",
                              // marginLeft: "10px",
                            }}
                          >
                            <span style={{ color: "#49AF48" }}>
                              <RemoveRedEyeIcon
                                style={{ color: "#49AF48", fontSize: "13px" }}
                              />
                              <b style={{ fontSize: "11px" }}>View</b>
                            </span>
                          </button>
                        </Link>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default Assessment;
