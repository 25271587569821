import http from "../httpService";
import { apiUrl } from "../../config.json";
import { getAuthToken } from "../Authentication/AuthService";

const headers = {
  "x-access-token": getAuthToken(),
};

export const getCurriculumDetails = (payload) => {
  return http.post(apiUrl + "/getCurriculumDetails", payload, {
    headers: headers,
  });
};
export const getUserCurriculum = (id) => {
  return http.get(apiUrl + "/user/getUserCur/" + id, {
    headers: headers,
  });
};
