import http from "../httpService";
import { apiUrl } from "../../config.json";
import { getAuthToken } from "../Authentication/AuthService";

const headers = {
  "x-access-token": getAuthToken(),
};

export const fetchAssignments = (payload) => {
  return http.post(apiUrl + "/assessment/fetchAssignments", payload, {
    headers: headers,
  });
};

export const fetchAssignmentsById = (payload) => {
  return http.post(apiUrl + "/assessment/getAssessment", payload, {
    headers: headers,
  });
};

export const getPendingQuestions = (payload) => {
  return http.post(apiUrl + "/getPendingQuestions", payload, {
    headers: headers,
  });
};

export const testSubmitByTeacher = (payload) => {
  return http.post(apiUrl + "/transaction/testSubmitByTeacher", payload, {
    headers: headers,
  });
};

export const getTestStudent = (payload) => {
  return http.post(apiUrl + "/transaction/getStudent", payload, {
    headers: headers,
  });
};

export const getStudentQues = (payload) => {
  return http.post(apiUrl + "/transaction/getStudentQues", payload, {
    headers: headers,
  });
};
export const getAssignments = (payload) => {
  return http.post(apiUrl + "/assessment/getAssessmentByCur", payload, {
    headers: headers,
  });
};

export const getAssesmentAfterCheck = (payload) => {
  return http.post(apiUrl + "/transaction/getAssessment", payload, {
    headers: headers,
  });
};
export const setStatus = (payload) => {
  return http.post(apiUrl + "/transaction/setStatus", payload, {
    headers: headers,
  });
};

export const getAllStudentReports =(payload) =>{
  return http.post(apiUrl + "/transaction/getReportofStudent",payload, {
    headers:headers,
  });
}