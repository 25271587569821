import React, { useState, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Certificate from "../../components/Students/Certificatee/certificatee";
import { PDFViewer } from "@react-pdf/renderer";
import Profile from "../../components/Share/Profile";
import { userID } from "../../services/Authentication/AuthService";
import { getUserInfo } from "../../services/Admin/commonService";
import { getCurriculum } from "../../services/Admin/curriculumService";
const CertificateContainer = () => {
  const [userInfo, setUserInfo] = useState("");
  const [cur, setCur] = useState("");
  useEffect(() => {
    getUser();
  }, []);
  const param = useParams();
  console.log(param.id);
  const getUser = () => {
    getUserInfo(+userID())
      .then((res) => {
        if (res.status === 200) {
          // if (res.data.status) {
          setUserInfo(res.data.data);
          // }
        } else {
          setUserInfo("");
        }
      })
      .catch((err) => {
        console.log("err");
      });

    getCurriculum({ _id: param.id }).then((res) => {
      setCur(res.data.data[0]);
      console.log(res.data.data);
    });
  };
  return (
    <PDFViewer style={{ width: "100%", height: "1000px" }}>
      <Certificate userInfo={userInfo} cur={cur} />
    </PDFViewer>
  );
};

export default CertificateContainer;
