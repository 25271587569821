import React, { useState, useEffect, Fragment } from "react";
import { Link, useHistory } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import style from "./adminStudent.css"
const StudentList = (props) => {
  const { studentList, search, searchStudent,changeStatus} = props;
  const history = useHistory();

  // useEffect(() => {
  //     window.scrollTo(0, 0)
  // }, [])
  return (
    <Fragment>
      <div className="row">
        <div className="col-md-12">
          <div className="col-md-12  ">
            <h3 style={{ color: "#4A1D75" }}>Student List</h3>
          </div>
          <div className="col-md-12 d-flex "></div>
          {/* <div class="input-group row col-12   mb-2 ">
            <div class="input-group-prepend">
              <div class="input-group-text">
                <img
                  src="https://img.icons8.com/search"
                  style={{ maxWidth: "40%" }}
                />
              </div>
            </div>
            <input
              type="text"
              value={search}
              onChange={(text) => searchStudent(text)}
              class="form-control"
              placeholder="Search Student"
            />
          </div> */}
          <div class="search">
            <span class="fa fa-search"></span>
            <input
              placeholder="Search"
              style={{ borderRadius: "30px" }}
              type="text"
              value={search}
              onChange={(text) => searchStudent(text)}
            />
          </div>
          <div className="col-md-12 table_container">
            {/* <div className="card" style={{ marginTop: "10px" }}> */}
            <div
              className="card-body"
            // style={{ display: "block", overflowX: "scroll" }}
            >
              <table className="table table overflow-hidden">
                <thead>
                  <tr>
                    <th style={{ color: "#20639B", width: "13%" }}>
                      Student Name
                    </th>
                    <th style={{ color: "#20639B", width: "5%" }}>Role</th>
                    <th style={{ color: "#20639B", width: "12%" }}>Email</th>
                    {/* <th style={{ color: "#20639B", width: "14%" }}>
                      Curriculum code
                    </th> */}
                    {/* <th style={{ color: "#20639B", width: "7%" }}>
                      Curriculum
                    </th> */}
                    <th style={{ color: "#20639B", width: "2%" }}>gender</th>
                    <th style={{ color: "#20639B", width: "10%" }}>D.O.B</th>
                    <th style={{ color: "#20639B", width: "10%" }}>
                      Mobile No.
                    </th>
                    <th style={{ color: "#20639B", width: "" }}>address</th>
                    <th style={{ width: "" }}>Status</th>
                    <th style={{width:''}}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {studentList &&
                    studentList.length > 0 &&
                    studentList.map((q) => {
                      if (q.role === "student") {
                        return (
                          <tr>
                            <td>
                              {q.firstName} {q.middleName} {q.lastName}{" "}
                            </td>
                            <td>{q.role}</td>
                            <td>{q.email}</td>
                            {/* <td>{q.curriculumCode ? q.curriculumCode : ""}</td> */}
                            {/* <td>{q.curriculum ? q.curriculum : ""}</td> */}
                            <td>{q.gender}</td>
                            <td>{q.dob && q.dob.split("T")[0]}</td>
                            <td>{q.mobile}</td>
                            <td>{q.address}</td>
                            <td>

                              {q.isActivate ? (
                                <button
                                  className="btn btn-sm btn-info float-right ml-3"
                                  onClick={() => changeStatus(q._id, false)}
                                  style={{ backgroundColor: "#AB94E2",width:"80px",height:"40px" }}
                                >
                                  <span style={{ color: "rgb(74, 29, 117)" }}>
                                    <b> Active</b>
                                  </span>
                                </button>
                              ) : (
                                <button
                                  className="btn btn-sm btn-info float-right ml-3"
                                  onClick={() => changeStatus(q._id, true)}
                                  style={{ backgroundColor: "#AB94E2",width:"80px",height:"40px" }}
                                >
                                  <span style={{ color: "rgb(74, 29, 117)" }}>
                                    <b> InActive</b>
                                  </span>
                                </button>
                              )}
                            </td>

                            <td >

                              <Link to={`/admin/student-view/${q.userID}`}>
                                <button
                                  className="btn btn-sm "
                                  style={{
                                    backgroundColor: "#DEF2E9",
                                    marginRight: "10px",
                                    
                                  
                                  }}
                                >
                                  <span style={{ color: "#49AF48" }}>
                                    <RemoveRedEyeIcon
                                      style={{
                                        color: "#49AF48",
                                        fontSize: "9px",
                                       
                                      }}
                                    />
                                    <span style={{ fontSize: "10px" }}>View</span>
                                  </span>
                                </button>
                              </Link>
                              {/* <Link to={`/admin/student-edit/${q.userID}`}>
                                <button
                                  className="btn btn-sm"
                                  style={{
                                    backgroundColor: "#FFFFD3",
                                    
                                  }}
                                >
                                  <span style={{ color: "#EAB731" }}>
                                    <BorderColorIcon
                                      style={{
                                        color: "#EAB731",
                                        fontSize: "10px",
                                      }}
                                    />
                                    <span style={{ fontSize: "9px" }}> Edit</span>
                                  </span>
                                </button>
                              </Link> */}
                            </td>
                          </tr>
                        );
                      }
                    })}
                </tbody>
              </table>
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default StudentList;
