import React, { useEffect } from "react";
import admindash1 from "../../..//assets/Images/admindash1.png"
import admindash3 from "../../..//assets/Images/admindash3.png"
import teacherDash1 from "../../..//assets/Images/teacherDash1.png";
import admindash4 from "../../..//assets/Images/admindash4.png";
import admindash5 from "../../..//assets/Images/admindash5.png";
import admindash2 from "../../..//assets/Images/admindash2.png";
import teacherDash2 from "../../..//assets/Images/teacherDash2.png";
import teacherDash3 from "../../..//assets/Images/teacherDash3.png";
import teacherDash4 from "../../..//assets/Images/teacherDash4.png";
import teacherDash6 from "../../..//assets/Images/teacherDash6.png";
import teacherDash7 from "../../..//assets/Images/teacherDash7.png";
import teacherDash8 from "../../..//assets/Images/teacherDash8.png";
import completesession from "../../..//assets/Images/completesession.png";
import completedqueries from "../../..//assets/Images/completedqueries.png";
import pendingqueries from "../../..//assets/Images/pendingqueries.png"
import session from "../../..//assets/Images/session.png"
import queries1 from "../../..//assets/Images/queries1.png"
import pendingsession from "../../..//assets/Images/pendingsession.png"
import style from "./Dashboard.module.css";
import { useState } from "react";
import { StylesProvider } from "@material-ui/core";
import { Link } from "react-router-dom";
const Dashboard = (dashData) => {
  const [data, setData] = useState({});
  useEffect(() => {
    if (dashData) {
      console.log(dashData.dashData);

      setData(dashData.dashData);
    }
  }, [dashData]);
  return (
    <React.Fragment>
      <>
        {/* <div class="container">
        <div class="row">
          <div class="col-sm">
            <div className={style.card}>
              <div className={style.totalcurri}>
                <p className={style.texts}> Total Curriculum</p>
                <img src={teacherDash1} />
              </div>
              <h3>{data.curriculum}</h3>
            </div>
          </div>
          <div class="col-sm ">
            <div className="main_box">
              <div>
                <div className={style.circle1}>
                  <div className={style.img_con}>
                    <img
                      src={teacherDash4}
                      className="img_box"
                      style={{ width: "80px" }}
                    />
                  </div>
                </div>
                <div className=" py-3" style={{ background: "white" }}>
                  <div className={style.semicircle}>
                    <span className=" d-flex justify-content-center">
                      <b style={{ alignItems: "center" }}>{data.assesment}</b>
                    </span>
                    <span className=" d-flex justify-content-center">
                      Total Assessment
                    </span>
                  </div>
                </div>
              </div>
            </div>
          
          </div>
        </div>
        <div class="row">
          <div class="col-sm">
            <div className={style.card2}>
              <div className={style.totalstudent}>
                <p className={style.texts}> Total Student</p>
                <img src={admindash4} />
              </div>
           
              <h3>{data.student}</h3>
            </div>
          </div>
          <div class="col-sm">
            <div className={style.filestudent}>
              <div className="main_box">
                <div className={style.circle}>
                  <div className={style.img_con}>
                    <img src={teacherDash6} className="img_box1" />
                  </div>
                </div>
                <div className=" py-3" style={{ background: "white" }}>
                  <span className=" d-flex justify-content-center">
                    <b>{data.studentPass}</b>
                  </span>
                  <span className=" d-flex justify-content-center">
                    Student Passed
                  </span>
                </div>
              </div>
              <div className="main_box">
                <div className={style.circle}>
                  <div className={style.img_con}>
                    <img src={teacherDash3} className="img_box1" />
                  </div>
                </div>
                <div className=" py-3" style={{ background: "white" }}>
                  <span className=" d-flex justify-content-center">
                    <b>{data.studentFail}</b>
                  </span>
                  <span className=" d-flex justify-content-center">
                    Student Failed
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm">
            <div className={style.card3}>
              <div className={style.totalsession}>
                <p className={style.texts}> Total Session</p>
                <img src={admindash5} />
              </div>
              
              <h3>{data.session}</h3>
            </div>
          </div>
          <div class="col-sm">
            <div className={style.filestudent}>
              <div className="main_box">
                <div className={style.circle}>
                  <div className={style.img_con}>
                    <img src={teacherDash7} className="img_box1" />
                  </div>
                </div>
                <div className=" py-3" style={{ background: "white" }}>
                  <span className=" d-flex justify-content-center">
                    <b>{data.sesionComplete}</b>
                  </span>
                  <span className=" d-flex justify-content-center">
                    Completed
                  </span>
                </div>
              </div>
              <div className="main_box">
                <div className={style.circle}>
                  <div className={style.img_con}>
                    <img src={teacherDash8} className="img_box1" />
                  </div>
                </div>
                <div className=" py-3" style={{ background: "white" }}>
                  <span className=" d-flex justify-content-center">
                    <b>{data.sessionPendng}</b>
                  </span>
                  <span className=" d-flex justify-content-center">
                    Pending
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      </>
      <div className={style.admindashome}>
        <div className={style.main}>
          <div className={style.card}>
            <div className={style.curri}>
              <span>
                <h6>Subject</h6>
              </span>
              <img src={admindash1} style={{ width: "40px" }} />
            </div>
            <span>
              <b>{data.curriculum}</b>
            </span>
          </div>
          <Link
            to="/teacher/assignments-list"
            style={{ textDecoration: "none" }}
          >
            <div className={style.card2}>
              <div className={style.assess}>
                <span>
                  <h6 style={{ color: "black" }}> Assessment</h6>
                </span>
                <img src={admindash2} />
              </div>
              <span style={{ color: "black" }}>
                <b>{data.assesment}</b>
              </span>
            </div>
          </Link>
          <div className={style.card3}>
            <div className={style.sessio}>
              <span>
                <h6 style={{ color: "black" }}>Curriculum</h6>
              </span>

              {/* <span></span> */}

              <img src={admindash4} />
            </div>
            {/* <br /> */}
            <span>
              <b>{data.curriculum}</b>
            </span>
          </div>
        </div>
      </div>

      <div className={style.submain}>
        {/* <div className={style.totalSession}> */}
        <Link to="/teacher/teacher-session" style={{ textDecoration: "none" }}>
          <div className={style.card4}>
            <div className={style.student}>
              <span>
                <h6 style={{ color: "black" }}>Total Session</h6>
              </span>
              <img src={session} />
            </div>
            <span style={{ color: "black" }}>
              <b>{data.session}</b>
            </span>
          </div>
        </Link>
        {/* </div> */}

        {/* <div className={style.totalSession}> */}
        <Link to="/teacher/queries-list" style={{ textDecoration: "none" }}>
          <div className={style.card5}>
            <div className={style.teacher}>
              <span>
                <h6 style={{ color: "black" }}>No of Queries</h6>
              </span>
              <img src={queries1} />
            </div>
            <span style={{ color: "black" }}>
              <b>{data.query}</b>
            </span>
          </div>
        </Link>
        {/* </div> */}
      </div>
      <div className={style.cardcompletedpend}>
        <div className={style.sessioncards}>
          <div className={style.completesession}>
            <span>
              <h6>Completed Session</h6>
            </span>
            <span>
              <h5>{data.sesionComplete}</h5>
            </span>
            <span style={{ display: "flex", justifyContent: "flex-end" }}>
              <img src={completesession} />
            </span>
          </div>
          <div className={style.pendingsession}>
            <span>
              <h6>Pending Session</h6>
            </span>
            <span>
              <h5>{data.sessionPendng}</h5>
            </span>
            <span style={{ display: "flex", justifyContent: "flex-end" }}>
              <img src={pendingsession} />
            </span>
          </div>
        </div>
        <div className={style.queriescard}>
          <div className={style.completequeries}>
            <span>
              <h6>Completed Queries</h6>
            </span>
            <span>
              <h5>{data.queryClose}</h5>
            </span>
            <span style={{ display: "flex", justifyContent: "flex-end" }}>
              <img src={completedqueries} />
            </span>
          </div>
          <div className={style.pendingcard}>
            <span>
              <h6>Pending Queries</h6>
            </span>
            <span>
              <h5>{data.queryPending}</h5>
            </span>
            <span style={{ display: "flex", justifyContent: "flex-end" }}>
              <img src={pendingqueries} />
            </span>
          </div>
        </div>
      </div>
      {/* 
      <div className={style.admindashome}>
        <div className={style.main}>
          <div className={style.card}>
            <div className={style.curri}>
              <span>
                <h6>Session</h6>
              </span>
              <img src={admindash5} style={{ width: "40px" }} />
            </div>
            <span>
              <b>{data.curriculum}</b>
            </span>
          </div>

          <div className={style.card2}>
            <div className={style.assess}>
              <span>
                <h6>Complete Session</h6>
              </span>
              <img src={teacherDash7} />
            </div>
            <span>
              <b>{data.assesment}</b>
            </span>
          </div>

          <div className={style.card3}>
            <div className={style.sessio}>
              <span>
                <h6>Pending Session</h6>
              </span>

         

              <img src={teacherDash8} />
            </div>
          
            <span>
              <b>{data.session}</b>
            </span>
          </div>
        </div>
      </div> */}
      {/* <div className={style.admindashome}>
        <div className={style.main}>
          <div className={style.card}>
            <div className={style.curri}>
              <span>
                <h6>Nomber of Queries</h6>
              </span>
              <img src={admindash1} style={{ width: "40px" }} />
            </div>
            <span>
              <b>{data.curriculum}</b>
            </span>
          </div>

          <div className={style.card2}>
            <div className={style.assess}>
              <span>
                <h6> Completed Queries</h6>
              </span>
              <img src={admindash2} />
            </div>
            <span>
              <b>{data.assesment}</b>
            </span>
          </div>

          <div className={style.card3}>
            <div className={style.sessio}>
              <span>
                <h6>Pending Queries</h6>
              </span>

              

              <img src={admindash3} />
            </div>
          
            <span>
              <b>{data.session}</b>
            </span>
          </div>
        </div>
      </div> */}
    </React.Fragment>
  );
};

export default Dashboard;
